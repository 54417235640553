import React, {useContext, useState} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import HeaderSection from "./HeaderSection";
import TaskDescription from "./TaskDescription";
import TabSection from "./TabSection";
import {useQuery} from "react-query";
import {getAuditById} from "../../../api/audits/auditsApi";
import {Link, useLocation, useParams} from "react-router-dom";
import AddTechnicianDrawer from "../component/addTachnicianDrawer/addTechnicianDrawer";
import BoxImageSection from "./BoxImageSection";
import TechnicianSection from "./TechnicianSection";
import MapSection from "./MapSection";
import BreadCrumb from "../../../Components/Common/BreadCrumb/BreadCrumb";
import TaskContext from "../hook/TaskContext";
import HistoryContext from "../../../Components/Hooks/HistoryContext";
import loading from "../../../assets/images/loading.gif"
import toast from "react-hot-toast";
const TaskOverview = () => {
    const history = useContext(HistoryContext)
    const location = useLocation();
    document.title = `Tâche | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;
    const params = useParams()
    const {
        isLoading,
        isError,
        error,
        data: auditItem,
    } = useQuery(["audit", params.id], () => getAuditById(params.id));

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };

    if (isLoading) {
        return (
            <React.Fragment>
                 
            <div className="page-content">
                <Container style={{display:"flex",'justify-content': 'center','align-items': 'center',height:"80vh"}}>
                <img src={loading} alt='loading'/>
                </Container>
            </div>
        </React.Fragment>
          );
    }

    if (isError) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Row className="d-flex justify-content-center align-items-center">
                        <Col xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <lord-icon
                                            className="avatar-xl"
                                            src="https://cdn.lordicon.com/usownftb.json"
                                            trigger="loop"
                                            colors="primary:#405189,secondary:#0ab39c"
                                            style={{ width: "120px", height: "120px" }}
                                        ></lord-icon>
                                        <h1 className="text-primary mb-4">Oops !</h1>
                                        <h4 className="text-uppercase">Désolé, Boîte vide😭</h4>
                                        <p className="text-muted mb-4">
                                            {error}
                                        </p>
                                        <Link to="/audits" className="btn btn-success">
                                            <i className="mdi mdi-arrow-u-left-top me-1"></i>Retour sur la liste des audits
                                        </Link>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                     <BreadCrumb
                         page="Détails tâche"
                         history={history}
                         prevUrl={location?.state?.prevUrl}
                     />

                    <Row className="row">
                        <HeaderSection audit={auditItem} />
                    </Row>

                    <Row className="row mt-n5">
                        <Col className="col-xxl-3">
                            <BoxImageSection content={auditItem?.boxImages} status={auditItem?.status} />
                        </Col>
                        <Col className="col-xxl-7">
                            <TaskDescription audit={auditItem} />
                        </Col>
                        <Col className="col-xxl-2">
                            <TechnicianSection showDrawer={showDrawer} technician={auditItem?.technician}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-xxl-10">
                            <TaskContext.Provider value={auditItem}>
                                <TabSection />
                            </TaskContext.Provider>
                        </Col>
                        <Col className="col-xxl-2">
                            <MapSection
                                location={auditItem?.place?.location}
                            />
                        </Col>
                    </Row>
                    <AddTechnicianDrawer audit={auditItem} setOpen={setOpen} open={open}/>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TaskOverview;
