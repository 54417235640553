import {Button, Container} from "reactstrap";
import {Empty} from "antd";
import React from "react";

const EmptyData = ({handleClick, message}) => {
    return (
        <div className="d-flex justify-content-center align-items-center mt-5">
            <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                    height: 200,
                }}
                description={
                    <span>
                {message}!
              </span>
                }
            >
                <Button onClick={handleClick} type="primary">Créer un projet</Button>
            </Empty>
        </div>
    )
}

export default EmptyData;