import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {getJointFileToDemande} from "../../../../../api/audits/auditsApi";
import SimpleBar from "simplebar-react";

const TaskJointsFiles = () => {
    const params = useParams();
    const [fileList, setFileList] = useState([]);

    const {
        isLoading,
        isError,
        data :fetchedFiles
    } = useQuery(
        "audit_files",()=>getJointFileToDemande(params.id))

    // Recuperation des fichiers lies a la demande
    useEffect(() => {
        if(fetchedFiles){
            setFileList(fetchedFiles)
        }
    }, [params,fetchedFiles]);

    return (
        <>
            {fileList.length>0 ? (
                <SimpleBar style={{maxHeight: "300px"}} className="px-3">
                    <div className="table-responsive">
                        <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-active">
                            <tr>
                                <th scope="col">Nom Fichier</th>
                                <th scope="col">Type Fichier</th>
                                <th scope="col">Taille Fichier</th>
                                <th scope="col">Date Recente</th>
                                <th scope="col" className="text-center"></th>
                            </tr>
                            </thead>
                            <tbody id="file-list">
                            {(fileList || []).map((item, key) => (
                                <tr key={key}>
                                    <td>
                                        <input
                                            className="form-control filelist-id"
                                            type="hidden"
                                            value="1"
                                            id="filelist-1"
                                        />
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 fs-17 me-2 filelist-icon">
                                                <i
                                                    className={
                                                        item.split(".").at(-1) === "pdf"
                                                            ? "ri-file-pdf-fill text-danger"
                                                            : item.split(".").at(-1) === "docx"
                                                                ? "ri-file-text-fill text-secondary"
                                                                : "ri-gallery-fill text-success"
                                                    }
                                                />
                                            </div>
                                            <div className="flex-grow-1 filelist-name">
                                                {item?.substr(20).split(".").at(0)}
                                            </div>
                                        </div>
                                    </td>
                                    <td>{item.split(".").at(-1)}</td>
                                    <td className="filelist-size">.ko</td>
                                    <td className="filelist-create">{item?.substr(0, 10)}</td>
                                    <td>
                                        <Link to={`${process.env.REACT_APP_API_URL}/data-file/download-file/${item}`}>
                                            <i className="ri-download-2-fill me-2 align-middle text-muted"></i>
                                            <span className="text-muted">Téléchargement</span>
                                        </Link>
                                        {/* <Link to="#">
                        <i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>
                    </Link> */}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </SimpleBar>
            ) : (
                <div className="py-4 text-center">
                    <div>
                        <lord-icon
                            src="https://cdn.lordicon.com/wzwygmng.json"
                            trigger="loop"
                            colors="primary:#405189,secondary:#0ab39c"
                            style={{width: "72px", height: "72px"}}
                        ></lord-icon>
                    </div>

                    <div className="mt-4">
                        <h5>Désolé! Aucun fichier joint trouvé!</h5>
                    </div>
                </div>
            )}
        </>
    );
}

export default TaskJointsFiles;