import {Col, Form, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as moment from "moment/moment";
import {updateBoxInfos} from "../../../api/audits/auditsApi";
import {useMutation} from "react-query";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";

const EditBoxInfosModal = ({modal, toggle, boxInfos}) => {
    const params = useParams();
    const updateBoxInfosMutation = useMutation(updateBoxInfos, {
        onSuccess: () => {
            toast.success('update successfully!')
        },
        onError: (error) => {
            toast.error(error)
        }
    })

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            boxReference: (boxInfos && boxInfos?.boxReference) || "",
            cableNumber: (boxInfos && boxInfos?.cablesNumber) || "",
            cassetteNumber: (boxInfos && boxInfos?.cassetteNumber) || "",
            boxType: (boxInfos && boxInfos?.boxType?.type) || "",
        },
        validationSchema: Yup.object({}),
        onSubmit: (values) => {
            const {
                boxReference,
                cableNumber,
                cassetteNumber
            } = values;

            const requestParams = {
                "boxReference": boxReference,
                "cableNumber": cableNumber,
                "cassetteNumber": cassetteNumber,
                "isBoxBottomEmpty": boxInfos && boxInfos?.isBoxBottomEmpty,
            };

            const requestBody =  {
                "boxReferenceImage": boxInfos?.boxReferenceImageUrl,
                "cableNumberImage": boxInfos?.cablesNumberImageUrl,
                "cassetteNumberImage": boxInfos?.cassetteNumberImageUrl
            }

            updateBoxInfosMutation.mutate({params:requestParams, body:requestBody, taskId: parseInt(params?.id)})
        },
    })
    return (
        <Modal
            animation={false}
           className="zoomIn"
           id="EditBoxInfos"
           size="md"
           isOpen={modal}
           toggle={toggle}
           centered
           backdrop={'static'}
        >
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                <Form
                    autoComplete="off"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row className="mb-3">
                        <Col lg={5} >
                            <label htmlFor="boxRefInput" className="form-label">Référence Boîte</label>
                        </Col>
                        <Col lg={7} >
                            <input
                                name="boxReference"
                                type="text"
                                className="form-control"
                                id="boxRefInput"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.boxReference || ""}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col lg={5} >
                            <label htmlFor="cableNbrInput" className="form-label">Nombre de Câble</label>
                        </Col>
                        <Col lg={7} >
                            <input
                                name="cableNumber"
                                type="number"
                                className="form-control"
                                id="cableNbrInput"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.cableNumber || ""}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col lg={5} >
                            <label htmlFor="casNbrInput" className="form-label">Nombre de Cassette</label>
                        </Col>
                        <Col lg={7} >
                            <input
                                name="cassetteNumber"
                                type="number"
                                className="form-control"
                                id="casNbrInput"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.cassetteNumber || ""}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col lg={5} >
                            <label htmlFor="boxTypeInput" className="form-label">Type de Boîte</label>
                        </Col>
                        <Col lg={7} >
                            <input
                                disabled={true}
                                name="boxType"
                                type="text"
                                className="form-control"
                                id="boxTypeInput"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.boxType || ""}
                            />
                        </Col>
                    </Row>
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary" disabled>Enregistrer</button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default EditBoxInfosModal;