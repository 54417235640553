import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from "antd";
import frFR from 'antd/locale/fr_FR';
// import frFR from 'antd/lib/locale/fr_FR';
const queryClient = new QueryClient();

const store = configureStore({ 
  reducer: rootReducer, 
  devTools: true,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  }) 
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider locale={frFR}>
          <App />
        </ConfigProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>
);