import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Col, Container, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink} from 'reactstrap';
import AllTasks from './component/AllTasks';
import BreadCrumb from '../../Components/Common/BreadCrumb/BreadCrumb';
import HistoryContext from "../../Components/Hooks/HistoryContext";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {deleteAudit, getAudits, removeAssignation} from "../../api/audits/auditsApi";
import {AuditListSkeleton} from "../../Components/Common/skeletons/auditSkeleton";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
    Address,
    AssignedTo,
    Createdat,
    DueDate,
    Modifitedat, Priority,
    Project,
    Status,
    text_color
} from "./component/TaskListCol";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, FloatButton, Result, Space} from "antd";
import {CustomerServiceOutlined, DeleteOutlined, DownloadOutlined, PlusOutlined,DiffOutlined} from "@ant-design/icons";
import ExampleDoc from "../../assets/ressources/MODEL_DE_DONNEES_TACHES.xlsx";
import AddMultiTask from "./component/addMultiTaskExcel/addMultiTask";
import classnames from "classnames";
import TableContainer from "../../Components/Common/TableContainer";
import AddEditFormDrawer from "./component/AddEditFormDrawer";
import toast from "react-hot-toast";
import {message} from "../../Components/constants/message";
import GoogleMapsBalises from "../Dashboard/component/GoogleMapsBalises";
import {getDashboardMetrics} from "../../api/dashboard/dashboardAPI";
import ImportAuditToBpe from './component/importAuditToBPE/importAuditToBpeFile';

const Audits = () => {
    const history = useContext(HistoryContext);
    const location = useLocation();
    const navigate = useNavigate()
    document.title = `Tâches d'audit | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;
    const queryClient = useQueryClient();
    const {
        isLoading,
        isError,
        error,
        data: audits,
    } = useQuery("audits", getAudits);
    const {data: metrics} = useQuery("metrics", getDashboardMetrics);
    const [isEdit, setIsEdit] = useState(false);
    const [task, setTask] = useState(null);
    const [selectedTask, setSelectedTask] = useState({});
    const [taskList, setTaskList] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [deleteModalUser, setDeleteModalUser] = useState(false);
    const [openTF, setOpenTF] = useState(false);
    const showDrawerTF = () => {
        setOpenTF(true);
    };

    const [modal, setModal] = useState(false);
    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    }, [modal]);

    useEffect(() => {
        if (audits) setTaskList(audits)
    }, [audits])

    const [activeTab, setActiveTab] = useState("1");
    const toggleTab = (tab, type) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            let filteredTasks = audits;
            if (type !== "all") {
                filteredTasks = audits.filter((task) => task.status === type);
            }
            setTaskList(filteredTasks);
        }
    };

    const deleteAuditMutation = useMutation(deleteAudit, {
        onSuccess: () => {
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("audits");
            toast.success(message.AUDIT_DELETE_SUCCESSFULLY)
        },
        onError: (error) => {
            toast.error(error)
        }
    });

    const removeAuditAssignationMutation = useMutation(removeAssignation, {
        onSuccess: () => {
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("audits");
            toast.success(message.AUDIT_ASSIGNATION_DELETED_SUCCESSFULLY)
        },
        onError: (error) => {
            toast.error(error)
        }
    });

    // Delete Data
    const onClickDelete = (task) => {
        setTask(task);
        setDeleteModal(true);
    };

    // Delete Data
    const handleDeleteTask = () => {
        if (task) {
            deleteAuditMutation.mutate(task.id)
            setDeleteModal(false);
        }
    };

    const [item, setItem] = useState();
    const onClickDeleteUser = (item) => {
        setItem(item)
        setDeleteModalUser(true);
    }

    const handleDeleteUser = () => {
        if (item) {
            removeAuditAssignationMutation.mutate({userId: item.userId, auditTaskId: item.taskId})
            setDeleteModalUser(false);
        }
    }

    // Update Data
    const handleTaskClick = useCallback(
        (arg) => {
            const task = arg;
            setSelectedTask({
                id: task.id,
                name: task.name,
                description: task.description || '',
                address: task.address,
                priority: task.priority,
                status: task.status,
                dueDate: task.dueDate,
                endDate: task.endDate,
                createdDate: task.createdDate,
                interlocFirstname: task.interlocFirstname || '',
                interlocLastname: task.interlocLastname || '',
                interlocEmail: task.interlocEmail || '',
                interlocPhone: task.interlocPhone || '',
                userId: task?.assignedTo[0]?.userId,
                projectId: task?.project?.projectId
            });

            setIsEdit(true);
            showDrawerTF();
        },
        [openTF]
    );

    // Add Data
    const handleTaskClicks = () => {
        setTask("");
        setIsEdit(false);
        // toggle();
    };

    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".taskCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => (ele.checked = true));
        } else {
            ele.forEach((ele) => (ele.checked = false));
        }

        deleteCheckbox();
    }, []);

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    
    //export multiple
    const [exportbox, setexportbox] = useState(["1","2"]);

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".taskCheckBox:checked");
        selectedCheckBoxDelete.forEach((element) => {
            deleteAuditMutation.mutate(element.value)
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
        ele.forEach(e => e.checked = false);
    };

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".taskCheckBox:checked");
        ele.length > 0
            ? setIsMultiDeleteButton(true)
            : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    function exportMultiAuditExcel () {
        const datas = [];

        selectedCheckBoxDelete.forEach((element) => {
           datas.push(element.value)
        });

        navigate("/excel-export",{state: {data :datas}})

     
    }

    const columns = useMemo(
        () => [
            {
                Header: (
                    <input
                        type="checkbox"
                        id="checkBoxAll"
                        className="form-check-input"
                        onClick={() => checkedAll()}
                    />
                ),
                Cell: (cellProps) => {
                    return (
                        <input
                            type="checkbox"
                            className="taskCheckBox form-check-input"
                            value={cellProps.row.original.id}
                            onChange={() => deleteCheckbox()}
                        />
                    );
                },
                id: "#",
            },
            {
                Header: "Projet",
                accessor: "project",
                filterable: false,
                Cell: (cellProps) => {
                    return <Project {...cellProps} />;
                },
            },
            {
                Header: "Tâches",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return (
                        <React.Fragment>
                            <div className="d-flex">
                                <div
                                    className={"flex-grow-1 tasks_name text-uppercase d-inline-block text-truncate " + text_color(cellProps.row.values.status)}
                                    style={{maxWidth: "170px"}}
                                >
                                    {cellProps.value}
                                </div>
                                <div className="flex-shrink-0 ms-4">
                                    <ul className="list-inline tasks-list-menu mb-0">
                                        <li className="list-inline-item">
                                            <Link
                                                to={`/demande-overview/${cellProps.row.original.id}`}
                                                state={{prevUrl: location.pathname}}
                                            >
                                                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                                            </Link>
                                        </li>
                                        {cellProps.row.values.status !== 'Validated' ? (<><li className="list-inline-item">
                                            <Link
                                                to="#"
                                                onClick={() => {
                                                    const taskData = cellProps.row.original;
                                                    handleTaskClick(taskData);
                                                }}
                                            >
                                                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                            </Link>
                                        </li>
                                        <li className="list-inline-item">
                                            <Link
                                                to="#"
                                                className="remove-item-btn"
                                                onClick={() => {
                                                    const taskData = cellProps.row.original;
                                                    onClickDelete(taskData);
                                                }}
                                            >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                            </Link>
                                        </li></>):null}
                                        <li className="list-inline-item">
                                            <Link
                                                to="#"
                                                className="remove-item-btn"
                                                onClick={(event) => {event.preventDefault(); 
                                                    window.open(`/demande-overview/${cellProps.row.original.id}`);}} 
                                                    target="_blank"
                                            >
                                                <i className="ri-share-box-fill align-bottom me-2 text-muted"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                },
            },
            {
                Header: " Créée le",
                accessor: "createdDate",
                filterable: false,
                Cell: (cellProps) => {
                    return <Createdat {...cellProps} />;
                },
            },
            {
                Header: "Modifiée le",
                accessor: "updatedDate",
                filterable: false,
                Cell: (cellProps) => {
                    return <Modifitedat {...cellProps} />;
                },
            },
            {
                Header: "Assignée à",
                accessor: "assignedTo",
                filterable: false,
                Cell: (cellProps) => {
                    return <AssignedTo {...cellProps} onClickDeleteUser={onClickDeleteUser}/>;
                },
            },
            {
                Header: "Intervention",
                accessor: "dueDate",
                filterable: false,
                Cell: (cellProps) => {
                    return <DueDate {...cellProps} />;
                },
            },
            {
                Header: "Adresse",
                accessor: "address",
                filterable: false,
                Cell: (cellProps) => {
                    return <Address {...cellProps} />;
                },
            },
            {
                Header: "Status",
                accessor: "status",
                filterable: false,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                },
            },
            {
                Header: "Priorité",
                accessor: "priority",
                filterable: false,
                Cell: (cellProps) => {
                    return <Priority {...cellProps} />;
                },
            },
        ],
        [handleTaskClick, checkedAll]
    );

    if (isLoading) {
        return <AuditListSkeleton/>
    }

    if (isError) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Result
                        status="warning"
                        title="La ressource demandée est introuvable. Veuillez vérifier votre connexion et réessayer."
                        extra={
                            <Button
                                type="primary"
                                key="console"
                                onClick={() => window.location.reload()}
                            >
                                Refresh
                            </Button>
                        }
                    />
                </Container>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        page="Tâches"
                        history={history}
                    />
                    {/*<AllTasks />*/}
                    <DeleteModal
                        show={deleteModalMulti}
                        onDeleteClick={() => {
                            deleteMultiple();
                            setDeleteModalMulti(false);
                        }}
                        onCloseClick={() => setDeleteModalMulti(false)}
                    />
                    <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleDeleteTask}
                        onCloseClick={() => setDeleteModal(false)}
                    />

                    <DeleteModal
                        show={deleteModalUser}
                        onDeleteClick={handleDeleteUser}
                        onCloseClick={() => setDeleteModalUser(false)}
                    />
                    <div className="row">
                        <Col lg={12}>
                            <div className="card border border-dark" id="tasksList">
                                <div className="card-header border-0">
                                    <div className="d-flex align-items-center">
                                        <h1 className="card-title mb-0 flex-grow-1 fs-2 fw-bold">
                                            Historique des tâches d'audits BPE
                                        </h1>
                                        <div className="flex-shrink-0">
                                            <div className="d-flex flex-wrap gap-2">
                                                {isMultiDeleteButton && (
                                                    <div >
                                                    <Button onClick={() => setDeleteModalMulti(true)} type="primary"
                                                            danger shape="circle" icon={<DeleteOutlined/>}
                                                            size="large" style={{margin:"5px"}}/>

                                                    <Button onClick={() => exportMultiAuditExcel()} type="primary"
                                                            success shape="circle" icon={<DiffOutlined/>}
                                                            size="large"/>
                                                 </div>
                                                )}


                                                <Space>

                                                    <a href={ExampleDoc} download="MODEL_DE_DONNEES_TACHES"
                                                       target='_blank'>
                                                        <Button icon={<DownloadOutlined/>}
                                                                style={{backgroundColor: "#BCED09"}}>
                                                            Model excel
                                                        </Button>
                                                    </a>

                                                    <AddMultiTask queryClient={queryClient}/>
                                                    <ImportAuditToBpe />

                                                    <Button
                                                        type="primary"
                                                        icon={<PlusOutlined/>}
                                                        onClick={showDrawerTF}
                                                        // onClick={() => {
                                                        //   setIsEdit(false);
                                                        //   setTask(null)
                                                        //   toggle();
                                                        // }}
                                                    >Nouvelle Tâche</Button>


                                                </Space>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body pt-0">
                                    <Nav
                                        className="nav-tabs nav-tabs-custom"
                                        role="tablist"

                                    >
                                        <NavItem>
                                            <NavLink
                                                className={classnames(
                                                    {active: activeTab === "1"},
                                                    "fw-semibold"
                                                )}
                                                onClick={() => {
                                                    toggleTab("1", "all");
                                                }}
                                                href="#"
                                            >
                                                <i className="ri-folder-5-line me-1 align-bottom"></i>{" "}
                                                Toutes les tâches
                                                {activeTab === "1" && (
                                                    <span className="badge align-middle ms-1 text text-dark"
                                                          style={{backgroundColor: "#FFF"}}>
                        {taskList?.length || 0}
                      </span>
                                                )}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames(
                                                    {active: activeTab === "2"},
                                                    "fw-semibold"
                                                )}
                                                onClick={() => {
                                                    toggleTab("2", "In_Progress");
                                                }}
                                                href="#"
                                            >
                                                <i className="ri-loader-line me-1 align-bottom"></i> En
                                                Cours{" "}
                                                {activeTab === "2" && (
                                                    <span className="badge align-middle ms-1 text text-dark"
                                                          style={{backgroundColor: "#FFF"}}>
                        {taskList?.length || 0}
                      </span>
                                                )}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames(
                                                    {active: activeTab === "3"},
                                                    "fw-semibold"
                                                )}
                                                onClick={() => {
                                                    toggleTab("3", "Completed");
                                                }}
                                                href="#"
                                            >
                                                <i className="ri-find-replace-line me-1 align-bottom"></i>{" "}
                                                Complètes{" "}
                                                {activeTab === "3" && (
                                                    <span className="badge align-middle ms-1 text text-dark"
                                                          style={{backgroundColor: "#FFF"}}>
                        {taskList?.length || 0}
                      </span>
                                                )}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames(
                                                    {active: activeTab === "4"},
                                                    "fw-semibold"
                                                )}
                                                onClick={() => {
                                                    toggleTab("4", "Validated");
                                                }}
                                                href="#"
                                            >
                                                <i className="ri-checkbox-circle-line me-1 align-bottom"></i>{" "}
                                                Validées
                                                {activeTab === "4" && (
                                                    <span className="badge align-middle ms-1 text text-dark"
                                                          style={{backgroundColor: "#FFF"}}>
                        {taskList?.length || 0}
                      </span>
                                                )}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames(
                                                    {active: activeTab === "5"},
                                                    "fw-semibold"
                                                )}
                                                onClick={() => {
                                                    toggleTab("5", "Shared");
                                                }}
                                                href="#"
                                            >
                                                <i className="ri-share-line me-1 align-bottom"></i>{" "}
                                                Partagées
                                                {activeTab === "5" && (
                                                    <span className="badge align-middle ms-1 text text-dark"
                                                          style={{backgroundColor: "#FFF"}}>
                        {taskList?.length || 0}
                      </span>
                                                )}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TableContainer
                                        columns={columns}
                                        data={taskList || []}
                                        isGlobalSearch={true}
                                        isGlobalFilter={true}
                                        isAddUserList={false}
                                        customPageSize={Number(sessionStorage.getItem("pageSize")) || 10}
                                        trClass={"border border-dark"}
                                        className="custom-header-css"
                                        divClass="table-responsive table-card m-3 border border-dark"
                                        tableClass="align-middle table-nowrap mb-0"
                                        theadClass="table-light table-nowrap"
                                        thClass="table-ligth text-dark"
                                        handleTaskClick={handleTaskClicks}
                                        isTaskListFilter={false}
                                        SearchPlaceholder="Search for tasks or something..."
                                    />
                                </div>
                            </div>
                        </Col>
                    </div>
                </Container>
            </div>
            <AddEditFormDrawer
                openTF={openTF}
                setOpenTF={setOpenTF}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                task={selectedTask}
                setTask={setTask}
            />
            <FloatButton
                onClick={toggle}
                shape="square"
                type="primary"
                style={{
                    right: 24,
                }}
                icon={<i className="ri-road-map-line fs-20"></i>}
            />
             <Modal animation={false}
                className="zoomIn"
                id="addSeller"
                size="xl"
                isOpen={modal}
                toggle={toggle}
                centered
                backdrop={'static'}
            >
              <ModalHeader toggle={toggle}>
                <Button type="text"
                    onClick={(event) => {event.preventDefault(); 
                    // window.open(`https://maps.google.com/maps?q=${latitude},${longitude}&z=10`);
                    }} 
                    target="_blank"
                >
                    <i className="ri-share-box-fill align-bottom fs-18 text-muted"></i>
                </Button>
              </ModalHeader>
              <ModalBody>
                <GoogleMapsBalises balises={metrics?.boxPlaces}/>
               </ModalBody>
             </Modal>
        </React.Fragment>
    );
};

export default Audits;