import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody, ModalFooter, ModalHeader,
  Row,
} from "reactstrap";
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createProject, updateProject } from "../../../api/projects/projectsApi";
import toast from "react-hot-toast";
import { useParams } from "react-router";
import {Button, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import PojectUpload from "./projectUpload";
import {DatePicker , Form as FormAntd} from 'antd';

const AddEditFormProject = ({
  setModal,
  modal,
  toggle,
  isEdit,
  projectItem,
  setProject = null
}) => {
  const param = useParams();
  const [files, setFiles] = useState([]);
  const queryClient = useQueryClient();
  const addProjectMutation = useMutation(createProject, {
    onSuccess: () => {
        // Invalidates cache and refetch data
        queryClient.invalidateQueries("projects");
        toast.success("Le projet a été créé avec succès!")
        setFiles([])
    },
    onError: (error) => {
        // An error happened!
        toast.error(error)
    },
  });

  const updateProjectMutation = useMutation(updateProject, {
      onSuccess: () => {
          // Invalidates cache and refetch data
          queryClient.invalidateQueries("projects");
          toast.success("Le projet a été modifié avec succès")
      },
  });

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      projectName: (projectItem && projectItem.projectName) || "",
      projectDescription: (projectItem && projectItem.projectDescription) || "",
      deadline: null,
      status: (projectItem && projectItem.status) || "Initié",
      image: (projectItem && projectItem.image) || "",
      attachedfile: (projectItem && projectItem.attachedfile) || "",
    },
    validationSchema: Yup.object({
      projectName: Yup.string().required("Veuillez donner un nom au projet"),
      image: Yup.mixed(),
      // deadline: Yup.date().required("")
    }),
    onSubmit: (values) => {
      const {
        projectName,
        projectDescription,
        deadline,
        status,
        image,
      } = validation.values;
      const formData = new FormData();
      formData.append("projectName", projectName);
      formData.append("projectDescription", projectDescription);
      formData.append("deadline", deadline && moment(deadline?.$d).format('yyyy-MM-DDThh:mm:ss'));
      formData.append("status", status);
      formData.append("image", image);
      formData.append("projectParentId", param.id ? param.id : 0);
      formData.append("company", JSON.parse(sessionStorage.getItem("authUser"))?.company);
      if (isEdit) {
        const data = {
          formData,
          attachedFiles: files,
          id: projectItem && projectItem.projectId,
        };
        updateProjectMutation.mutate(data)
        validation.resetForm();
      } else {
        const data = {
          formData,
          attachedFiles: files,
        };
        console.log(deadline)
        addProjectMutation.mutate(data)
        validation.resetForm();
      }
      toggle();
    },
  });

  return (
    <div
      className="modal fade"
      id="addProjectForm"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <Modal
            id="showModal"
            isOpen={modal}
            toggle={toggle}
            centered
            backdrop={"static"}
        >
          <ModalHeader className="bg-light p-3" toggle={toggle}>
            {/*{!!isEdit ? "Modification du projet" : "Créer un nouveau projet"}*/}
          </ModalHeader>
          <Form className="tablelist-form"
            onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}>
            <ModalBody>
              <input type="hidden" id="id-field" />

              <div
                  className="mb-3"
                  id="modal-id"
                  style={{ display: "none" }}
              >
                <Label htmlFor="id-field1" className="form-label">
                  ID
                </Label>
                <Input
                    type="text"
                    id="id-field1"
                    className="form-control"
                    placeholder="ID"
                    readOnly
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="projectName" className="form-label">
                  Nom du projet
                  <span className="text-danger mx-1">*</span>
                </Label>
                <Input
                    type="text"
                    className="form-control"
                    id="projectName"
                    placeholder="Donner un nom au projet"
                    name="projectName"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.projectName || ""}
                    invalid={
                      !!(validation.touched.projectName &&
                          validation.errors.projectName)
                    }
                />
                {validation.touched.projectName &&
                validation.errors.projectName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.projectName}
                    </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label
                    htmlFor="projectDescription"
                    className="form-label"
                >
                  Description
                </Label>
                <textarea
                    name="projectDescription"
                    className="form-control"
                    id="projectDescription"
                    rows="3"
                    cols="33"
                    placeholder="Une description au projet"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.projectDescription || ""}
                ></textarea>
              </div>

              <div className="mb-3">
                <Label htmlFor="deadline" className="form-label">
                  Date Limite
                </Label>
                <FormAntd.Item>
                  <DatePicker
                      name="deadline"
                      onChange={(value) => validation.setFieldValue("deadline", value)}
                      style={{width: '100%'}}
                      size="large"
                      format="DD-MM-YYYY"
                  />
                </FormAntd.Item>
              </div>

              <div className="mb-3">
                <Label htmlFor="image" className="form-label">
                  Icon du Projet
                </Label>
                <Input
                    type="file"
                    className="form-control"
                    id="image"
                    name="image"
                    onChange={(e) =>
                        validation.setFieldValue(
                            "image",
                            e.target.files[0]
                        )
                    }
                />
              </div>
              <div className="mb-3">
                <PojectUpload files={files} setFiles={setFiles} />
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button type="button" className="btn btn-light" onClick={() => { setModal(false); }}> Annuler </button>

                <button type="submit" className="btn btn-success"> {!!isEdit ? "Mettre à jour" : "Créer"} </button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default AddEditFormProject;
