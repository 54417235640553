import React from "react";
import { Navigate } from "react-router-dom";

import Dashboard from '../pages/Dashboard';
import Planning from "../pages/Planning";
import Enterprises from "../pages/Enterprises";
import Quotas from "../pages/Quotas";
import Teams from "../pages/Teams";

//login
import Login from "../pages/Auth/Login";
import Logout from "../pages/Auth/Logout";
import ProjectList from "../pages/Projects";
import Audits from "../pages/audits/index";
import AuditOverview from "../pages/audits/overview";
import TaskOverview from "../pages/audits/overview-new";
import ProjectOverview from "../pages/Projects/overview";
import Profile from "../pages/Teams/overview/profile";
import CompareAudit from "../pages/audits/overview/history/compareBox";
import CompareCassette from "../pages/audits/overview/history/compareBox/compareCassette";
import Resources from "../pages/Resources";
import PdfExport from "../pages/audits/component/pdfExport";
import CustomerSpace from "../pages/Customer-Space";
// import LicenseKey from "../pages/License-Key";
import OfflinePage from "../Components/Common/Offline";
import MultiExcelExport from "../pages/audits/overview-new/MultiExcelExport";
import TasksTable from "../pages/audits/test-new-view/TasksTable";


const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/projects", component: <ProjectList /> },
  { path: "/projects/overview/:id", component: <ProjectOverview /> },
  { path: "/projects/overview/subproject/:id", component: <ProjectOverview/> },
  { path: "/planning", component: <Planning /> },
  // { path: "/demandes", component: <Demandes /> },
  // { path: "/audits", component: <TasksTable /> },
  { path: "/audits", component: <Audits /> },
  { path: "/excel-export", component: <MultiExcelExport/> },
  // { path: "/demande-overview/:id", component: <DemandeDetails /> },
  // { path: "/demande-overview/:id", component: <AuditOverview /> },
  { path: "/demande-overview/:id", component: <TaskOverview /> },
  { path: "/demande-compare", component: <CompareAudit/> },
  { path: "/demande-compare/visualisation", component: <CompareCassette /> },
  { path: "/demande-exportpdf/:id", component: <PdfExport/> },
  { path: "/enterprises", component: <Enterprises/> },
  { path: "/commandes/quotas", component: <Quotas /> },
  { path: "/teams", component: <Teams /> },
  { path: "/resources", component: <Resources /> },
  { path: "/customer-space", component: <CustomerSpace /> },
  // { path: "/license-key", component: <LicenseKey /> },
  { path: "/profile/:id", component: <Profile /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Auth Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/offline", component: <OfflinePage /> },
];

export { authProtectedRoutes, publicRoutes };