import React, {useContext, useState} from "react";
import { Col, Collapse, Row } from "reactstrap";
import classnames from "classnames";
import { getQCM } from "../../../../../api/audits/auditsApi";
import { useQuery } from "react-query";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import audexaLogo from "../../../../../assets/login_page_assets/images/xnoir.png";
import noImage from "../../../../../assets/images/no-image.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TaskContext from "../../../hook/TaskContext";
import {Grow} from "transitions-kit";
import {AsyncImage} from "loadable-image";
import ShowCableModal from "./ShowQcmModal";

const Conformite = () => {
    const {qcmResponses} = useContext(TaskContext)
    const { data: qcmList } = useQuery("qcm", () => getQCM());
    const [showQcmMdl, setShowQcmMdl] = useState(false);
    const [item, setItem] = useState(null);
    function toggleQcmModal(value) {
        setShowQcmMdl(!showQcmMdl);
        setItem(value)
    }

    const [isOpenCollapse, setIsOpenCollapse] = useState(null);
    let mergedSubjects = qcmResponses?.map((subject) => {
        let otherSubject = qcmList?.find(
            (element) => element.id === subject.questionId
        );
        return { ...subject, ...otherSubject };
    });

    const handleOpen = (clickedIndex) => {
        if (isOpenCollapse === clickedIndex) {
            setIsOpenCollapse(null);
        } else {
            setIsOpenCollapse(clickedIndex);
        }
    };

    return (
        <SimpleBar style={{ maxHeight: "350px" }} className="px-3">
            <Row>
                <Col lg={12}>
                    <div className="mt-2">
                        <div
                            className="accordion accordion-border-box"
                            id="genques-accordion"
                        >
                            {(mergedSubjects.sort((a, b) => a.order - b.order) || []).map(
                                (item, index) => {
                                    return [
                                        <div className="accordion-item" key={index}>
                                            <h2
                                                className="accordion-header"
                                                id={`genques-heading-${index}`}
                                            >
                                                <button
                                                    className={classnames(
                                                        "accordion-button",
                                                        "fw-medium",
                                                        "flex",
                                                        {
                                                            collapsed: isOpenCollapse === index,
                                                        }
                                                    )}
                                                    type="button"
                                                    onClick={() => handleOpen(index)}
                                                    style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center" }}

                                                >
                                                    <div style={{ flex: 1 }}>
                                                        {item?.order} - {item?.question}

                                                    </div>

                                                    <div className="me-2">
                                                        {
                                                            item?.response ?
                                                                <span className="badge bg-success text-uppercase"> Conforme</span>:
                                                                <span className="badge bg-danger text-uppercase" > Non conforme</span>
                                                        }
                                                    </div>



                                                </button>

                                            </h2>
                                            <Collapse
                                                isOpen={isOpenCollapse === index}
                                                className="accordion-collapse"
                                            >
                                                <div className="accordion-body">
                                                    <div className="d-flex">
                                                        <div className="d-inline-flex gap-2 border border-dashed p-2 mb-2">
                                                            <Link
                                                                to=""
                                                                onClick={() => toggleQcmModal(item)}
                                                                className="bg-light rounded p-1"
                                                            >
                                                                <AsyncImage
                                                                    // rootMargin='600px'
                                                                    key={index}
                                                                    src={`${process.env.REACT_APP_API_IMG_URL}/${item?.imgUrl}`}
                                                                    style={{ width: 150, height: 100, borderRadius: 3 }}
                                                                    loader={<div style={{ background: '#888' }} />}
                                                                    Transition={Grow}
                                                                />
                                                            </Link>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h5 className="fs-16">
                                                                <a
                                                                    href="apps-ecommerce-product-details"
                                                                    className="link-primary"
                                                                >
                                                                    {item?.question}
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0">
                                                                Réponse:{" "}
                                                                <span className="fw-medium">
                                  {item?.qcmResponse}
                                </span>
                                                            </p>
                                                            <p className="text-muted mb-0">
                                                                Ordre:{" "}
                                                                <span className="fw-medium">{item?.order}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>,
                                    ];
                                }
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            <ShowCableModal
                toggleQcmModal={toggleQcmModal}
                showQcmMdl={showQcmMdl}
                qcm={item}
            />
        </SimpleBar>
    );
};

export default Conformite;
