import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { generateAuditTask, getAuditById } from "../../../api/audits/auditsApi";
import ExcelExport from "../component/excelExport";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export default function MultiExcelExport() {
  const location = useLocation();
  const data = location.state.data;

  const zip = new JSZip();

  const [isDownloadable, setIsDownloadable] = useState(false);
  const [nbrProcessed, setNbrProcessed] = useState(0);
  const [files, setFiles] = useState([]);

  const addFile = (file) => {
    setFiles((prevFiles) => [...prevFiles, file]);
  };

  useEffect(() => {
    if (nbrProcessed === data.length) {
      setIsDownloadable(true);
    }
  }, [nbrProcessed, data.length]);

  useEffect(() => {
    generateFileFolderWithFiles();
  }, [data]);

  async function generateFileFolderWithFiles() {
    const filePromises = data.map(async (id) => {
      try {
        const val = await getAuditToGenerateExcel(id);
        const res = await getTaskdata(id);
        const file = await ExcelExport(res, val, false);
        addFile(file);
        setNbrProcessed((prevNbr) => prevNbr + 1);
      } catch (error) {
        setNbrProcessed((prevNbr) => prevNbr + 1);
        console.error("Error generating file:", error);
      }
    });

    await Promise.all(filePromises);
  }

  async function download() {
    files.forEach((file) => {
      zip.file(file.name, file);
    });
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "audexa_plan_boite.zip");
  }

  async function getTaskdata(id) {
    return await generateAuditTask(id);
  }

  async function getAuditToGenerateExcel(id) {
    return await getAuditById(id);
  }

  return (
    <div className="page-content">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <div className="card card-body text-center">
          <div className="avatar-sm mx-auto mb-3">
            <div className="avatar-title bg-success-subtle text-success fs-17 rounded">
              <i className="ri-add-line"></i>
            </div>
          </div>
          <h4 className="card-title">
            {`${nbrProcessed} Audit(s) traité(s) sur ${data.length}`}
          </h4>
          <p className="card-text text-muted">
            Seul les audits validés ou complétés seront générés.
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={download}
              style={{
                backgroundColor: isDownloadable ? "#BCED09" : "#C9C9C9",
                height: "50px",
                width: "50vw",
                border: "none",
              }}
              disabled={!isDownloadable}
            >
              <h4>Téléchargement</h4>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
