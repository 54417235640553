import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import {Empty} from "antd";

export const TaskRadialBar = ({ metrics, dataColors }) => {
    var chartRadialbarMultipleColors = getChartColorsArray(dataColors);
    const series = metrics ? [
        Math.round(metrics?.tasks.filter((item) => item.status === 'Affected').length * 100/metrics?.taskCount),
        Math.round(metrics?.tasks.filter((item) => item.status === 'In_Progress').length * 100/metrics?.taskCount),
        Math.round(metrics?.tasks.filter((item) => item.status === 'Completed').length * 100/metrics?.taskCount),
        Math.round(metrics?.tasks.filter((item) => item.status === 'Validated').length * 100/metrics?.taskCount)
    ] : []

    var options = {
        chart: {
            height: 350,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        fontSize: '22px',
                    },
                    value: {
                        fontSize: '16px',
                    },
                    total: {
                        show: true,
                        label: 'Tâches',
                        formatter: function (w) {
                            return metrics?.taskCount
                        }
                    }
                }
            }
        },
        labels: ['Assignées', 'En Cours', 'Complètes', 'Validées'],
        colors: chartRadialbarMultipleColors,
        legend: {
            show: true,
            floating: false,
            fontSize: '14px',
            position: 'bottom'
        }
    };

    return (
        <>
            {
                metrics?.taskCount ?
                <ReactApexChart
                    dir="ltr"
                    className="apex-charts"
                    series={series}
                    options={options}
                    type="radialBar"
                    height={350}
                />
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="d-flex justify-content-center align-items-center" style={{height: '255px'}} />
            }
        </>
    )
}

export const ProjectRadialBar = ({ metrics, dataColors }) => {
    var chartRadialbarMultipleColors = getChartColorsArray(dataColors);
    const series = metrics?.projects ? [
        Math.round(metrics?.projectsStatus.filter((item) => item === 'Initié').length * 100/metrics?.projectCount),
        Math.round(metrics?.projectsStatus.filter((item) => item === 'En Cours').length * 100/metrics?.projectCount),
        Math.round(metrics?.projectsStatus.filter((item) => item === 'Terminé').length * 100/metrics?.projectCount),
        Math.round(metrics?.projectsStatus.filter((item) => item === 'StandBy').length * 100/metrics?.projectCount)
    ] : []

    var options = {
        chart: {
            height: 350,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        fontSize: '22px',
                    },
                    value: {
                        fontSize: '16px',
                    },
                    total: {
                        show: true,
                        label: 'Projets',
                        formatter: function (w) {
                            return metrics?.projectCount
                        }
                    }
                }
            }
        },
        labels: ['Initiés', 'En Cours', 'Terminés', 'StandBy'],
        colors: chartRadialbarMultipleColors,
        legend: {
            show: true,
            floating: false,
            fontSize: '16px',
            position: 'bottom',
        }
    };
    return (
        <>
            {
                metrics?.projectCount ?
                <ReactApexChart
                    dir="ltr"
                    className="apex-charts"
                    series={series}
                    options={options}
                    type="radialBar"
                    height={350}
                />
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="d-flex justify-content-center align-items-center" style={{height: '250px'}} />
            }
        </>
    )
}

const DashboardChart = ({ seriesData, colors }) => {
    const series = [seriesData];
    const options = {
        chart: {
            type: "radialBar",
            width: 105,
            sparkline: {
                enabled: true,
            },
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "55%",
                    
                },
                track: {
                    margin: 1
                },
                dataLabels: {
                    show: true,
                    name: {
                        show: false,
                    },
                    value: {
                        show: true,
                        fontSize: "16px",
                        fontWeight: 600,
                        offsetY: 8,
                    },
                },
            },
        },
        colors: [colors],
    };
    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={[...series]}
                type="radialBar"
                id="total_jobs"
                width="105"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export default DashboardChart;
