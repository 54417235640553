import { StyleSheet, Text, View,Image, Link } from '@react-pdf/renderer';
import React from 'react'

const styles = StyleSheet.create({
   row:{
    flexDirection:"row",
    marginBottom:10,
    height:"30%",
    display:"flex"
   }
   ,
  cell1:{
    flex:1,
    flexDirection:"row"
   },
   cell2:{
    flex:2
   }
   
  });
export default function CableRow({image,cable_datas}) {
  return (
   <View style={styles.row}>
        <View style={styles.cell1}>
          <Link href={`${process.env.REACT_APP_API_ORIGINAL_IMG_URL}`+"/"+image}>
          <Image src={`${process.env.REACT_APP_API_IMG_URL}`+"/"+image} style={{objectFit:"fit",height:200}}/>
          </Link>
            
        </View>

        <View style={styles.cell2}>
            {cable_datas}
        </View>

   </View>
  )
}
