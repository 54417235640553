export const StatusFormat = (status) => {
    switch (status) {
        case 'Affected':
            return 'Assignée';
        case 'In_Progress':
            return 'En Cours';
        case 'Completed':
            return 'Complète';
        case 'Validated':
            return 'Validée';
        case 'Shared':
            return 'Partagée';
        case 'Created':
            return 'Créée';
        default:
            return '...';
    }
}

export const StatusColor = (status) => {
    switch (status) {
        case 'Affected':
            return 'primary';
        case 'In_Progress':
            return 'secondary';
        case 'Completed':
            return 'info';
        case 'Validated':
            return 'warning';
        case 'Shared':
            return 'success';
        case 'Created':
            return 'danger';
        default:
            return '';
    }
}