import React from 'react';
import {Link} from 'react-router-dom';
import {Card, CardBody, CardHeader, Col} from 'reactstrap';
import * as moment from "moment";
import 'moment/locale/fr';

const handleValidDate = (date) => {
    if(date) {
        moment.locale('fr');
        return moment(new Date(date)).format("LL");
    }
};

const advancement_percentage = (total, nbr_complete) => {
    if (total === 0 || nbr_complete === 0 || total === null || nbr_complete === null) {
        return "0%";
    }
    const valeur = Math.round((nbr_complete * 100) / total); 
    return valeur + "%";
};

const ActiveProjects = ({projects}) => {

    return (
        <React.Fragment>
            <Col xxl={6}>
            {/* style={{ height: '96%'}} */}
                <Card className="border border-dark">
                    <CardHeader className="d-flex align-items-center">
                        <h4 className="card-title flex-grow-1 mb-0">Projets Actifs</h4>
                        {/* <div className="flex-shrink-0">
                            <Link to="#" className="btn btn-soft-info btn-sm">Export Report</Link>
                        </div> */}
                    </CardHeader>
                    <CardBody>
                    <div className="todo-content position-relative px-4 mx-n4" id="todo-content">
                        <div className="table-responsive ">
                            <table className="table table-nowrap table-centered align-middle">
                                <thead className="bg-light text-dark ">
                                    <tr>
                                        <th scope="col" style={{ width: "20%" }} className='fw-bolder'>Projets</th>
                                        <th scope="col" style={{ width: "10%" }} className='fw-bolder'>Tâches</th>
                                        <th scope="col" >Progression</th>
                                        {/* <th scope="col">Assigné à</th> */}
                                        <th scope="col" style={{ width: "10%" }} className='fw-bolder'>Statut</th>
                                        <th scope="col" style={{ width: "20%" }} className='fw-bolder'>Date Limite</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {(projects || []).slice(0,10).map((item, key) => (<tr key={key}>
                                        <td className="fw-bolder">
                                            <Link className='text text-dark' to={`/projects/overview/${item.projectId}`}>{item.projectName}</Link>
                                        </td>
                                        <td className="fw-medium">{item.totalTaskCount}</td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                            {item.totalTaskCount > 0 && item.totalTaskCount == item.validatedTaskCount ? (
                                                <div className="progress progress-sm  flex-grow-1" style={{ height:"15px" }}>
                                                <div className="progress-bar rounded" role="progressbar" style={{ backgroundColor:"#BCED09", width: advancement_percentage(item.totalTaskCount,(item.completedTaskCount+item.validatedTaskCount)) }} aria-valuenow="53" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            ) : item.inProgressTaskCount > 0 || item.completedTaskCount > 0 ? (
                                                <div className="progress progress-sm  flex-grow-1" style={{ height:"15px" }}>
                                                <div className="progress-bar rounded" role="progressbar" style={{ backgroundColor:"#2642FF", width: advancement_percentage(item.totalTaskCount,(item.completedTaskCount+item.validatedTaskCount)) }} aria-valuenow="53" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            ) : (item.createdTaskCount+item.assignedTaskCount) == item.totalTaskCount ? (
                                                <div className="progress progress-sm  flex-grow-1" style={{ height:"15px" }}>
                                                <div className="progress-bar rounded" role="progressbar" style={{ backgroundColor:"#C9C9C9", width: advancement_percentage(item.totalTaskCount,(item.completedTaskCount+item.validatedTaskCount)) }} aria-valuenow="53" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            ) : (
                                                <div className="progress progress-sm  flex-grow-1" style={{ height:"15px" }}>
                                                <div className="progress-bar rounded" role="progressbar" style={{ backgroundColor:"#989788", width: advancement_percentage(item.totalTaskCount,(item.completedTaskCount+item.validatedTaskCount)) }} aria-valuenow="53" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            )}
                                                
                                                <div className="flex-shrink-0 me-1 mx-3 text-dark fw-bolder fs-13">{advancement_percentage(item.totalTaskCount,(item.completedTaskCount+item.validatedTaskCount))}</div>
                                                
                                            </div>
                                        </td>
                                        <td>
                                            {item.totalTaskCount > 0 && item.totalTaskCount == item.validatedTaskCount ? (
                                                <span className="badge p-1 text-uppercase" style={{backgroundColor:"#BCED0980" , color:"#BCED09",width:"80px"}}>
                                                Terminé
                                                </span>
                                            ) : item.inProgressTaskCount > 0 || item.completedTaskCount > 0 ? (
                                                <span className="badge p-1  text-uppercase" style={{backgroundColor:"#2642FF80" , color:"#2642FF",width:"80px"}}>
                                                En Cours
                                                </span>
                                            ) : (item.createdTaskCount+item.assignedTaskCount) == item.totalTaskCount ? (
                                                <span className="badge p-1 text-dark text-uppercase" style={{backgroundColor:"#C9C9C9",width:"80px"}}>
                                                Initié
                                                </span>
                                            ) : (
                                                <span className="badge p-1 text-dark text-uppercase" style={{backgroundColor:"#989788",width:"80px"}}>
                                                StandBy
                                                </span>
                                            )}
                                        </td>
                                        <td className="text-dark fw-bolder">{handleValidDate(item.deadline) !== 'Invalid date' ? handleValidDate(item.deadline) : '--/--/----'}</td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                        <div className="align-items-center mt-xl-3 mt-4 justify-content-end d-flex">
                            <div className="flex-shrink-0">
                                <Link to="/projects" className="text-muted text-decoration-underline">Voir Tous les Projets</Link>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default ActiveProjects;