import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Carousel} from "react-responsive-carousel";
import {Button, Card} from "antd";
const { Meta } = Card;
import {AsyncImage} from "loadable-image";
import {Grow} from "transitions-kit";
import {
    ArrowDownOutlined,
    CameraOutlined,
    EditOutlined,
    EllipsisOutlined,
    SettingOutlined,
    UploadOutlined
} from "@ant-design/icons";
import React, {useRef, useState} from "react";
import toast from "react-hot-toast";
import {useMutation, useQueryClient} from "react-query";
import {updateAllBoxImages} from "../../../api/boxImages/boxImgAPI";
import EasyCropImage from "../CropImage/EasyCropImage";

const PreviewImageCard = ({content, index, togglePrevModal, previewImgMdl}) => {
    const queryClient = useQueryClient();
    const [imagePreview, setImagePreview] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const downloadImage = (item) => {
        saveAs(`${process.env.REACT_APP_API_IMG_URL}/${item?.img}`, `${item.name}`) // Put your image URL here.
    }

    const [isCrop, setIsCrop] = useState(false)
    const toggleCrop = () => {
        setIsCrop(!isCrop)
    }

    const hiddenFileInput = useRef(null);
    const handleClick = (item) => {
        hiddenFileInput.current.click();
        setSelectedItem(item)
    };

    const updateImageCable = (e) => {
        if (e.target.files[0] && selectedItem) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                if (fileReader.readyState === 2) {
                    setImagePreview(fileReader.result);
                }
            };
            fileReader.readAsDataURL(e.target.files[0]);
            const data = {
                imageName: selectedItem,
                file: e.target.files[0]
            }
            updateImgMutation.mutate(data)
        }
    }

    // Updated mutation for all box Images
    const updateImgMutation = useMutation(updateAllBoxImages, {
        onSuccess: (data) => {
            queryClient.invalidateQueries("audit");
            toast.success("Image modifiée avec succès!");
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    let image = document.getElementById('image');
    function ZoomIn() {
        let width = image.clientWidth;
        let height = image.clientHeight;
        image.style.width = (width + 50) + "px";
        image.style.height = (height + 50) + "px";
    }
    function ZoomOut() {
        let width = image.clientWidth;
        let height = image.clientHeight;
        image.style.width = (width - 50) + "px";
        image.style.height = (height - 50) + "px";
    }

    return (
        <Modal
            id="boxImgCropModal"
            className="modal-xl"
            isOpen={previewImgMdl}
            toggle={togglePrevModal}
            backdrop={"static"}
        >
            <ModalHeader
                className="modal-title"
                id="boxImgCropModal"
                toggle={togglePrevModal}
            >
            </ModalHeader>
            <ModalBody>
                <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={updateImageCable}
                    ref={hiddenFileInput}
                    style={{display: "none"}}
                />
                <Carousel
                    showThumbs={false}
                    showIndicators={false}
                    selectedItem={index}
                >
                    {content && content.filter((i) => i.img != null).map((item, index) => {
                        return (
                            <>
                                {!isCrop ? (
                                    <Card
                                        style={{
                                            width: '100%',
                                        }}
                                        cover={
                                            <AsyncImage
                                                id="image"
                                                key={index}
                                                src={imagePreview ? imagePreview : `${process.env.REACT_APP_API_ORIGINAL_IMG_URL}/${item?.img}`}
                                                style={{width: '100%', height: 600, borderRadius: 3}}
                                                loader={<div style={{background: '#888'}}/>}
                                                Transition={Grow}
                                            />
                                        }
                                        actions={[
                                            <Button onClick={toggleCrop} type="text">
                                                <SettingOutlined key="setting"/>
                                            </Button>,
                                            <Button onClick={() => downloadImage(item)} type="text">
                                                <ArrowDownOutlined key="download"/>
                                            </Button>,
                                            <Button onClick={() => handleClick(item.img)} type="text">
                                                <CameraOutlined key="upload" />
                                            </Button>,
                                            // <Button onClick={ZoomIn} type="text">
                                            //     -
                                            // </Button>,
                                            // <Button onClick={ZoomOut} type="text">
                                            //     +
                                            // </Button>,
                                        ]}
                                    >
                                        <Meta
                                            title={`${item.name}`}
                                            // description="This is the description"
                                        />
                                    </Card>
                                ) : (
                                    <EasyCropImage
                                        item={item}
                                        setIsCrop={setIsCrop}
                                    />
                                )}
                            </>
                        )
                    })}
                </Carousel>
            </ModalBody>
        </Modal>
    )
}

export default PreviewImageCard;