import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import avatar1 from "../../assets/images/users/avatar-user.png";
import { Link } from 'react-router-dom';
import { UserRoleFormat } from '../../helpers/role_format';

const ProfileDropdown = () => {

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" 
                            src={avatar1}
                            alt="HeaderSection Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{JSON.parse(sessionStorage.getItem("authUser"))?.fullName.toUpperCase()}</span>
                            <span className="d-none d-xl-block ms-1 fs-13 text-muted user-name-sub-text">{JSON.parse(sessionStorage.getItem("authUser"))?.companyName}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">{JSON.parse(sessionStorage.getItem("authUser"))?.email}</h6>
                    <DropdownItem className='p-0'>
                        <Link to={`/profile/${JSON.parse(sessionStorage.getItem("authUser"))?.userId}`} className="dropdown-item">
                            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Mon Profil</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to={"#"} className="dropdown-item">
                            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                                className="align-middle">Mes Tâches</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to="#" className="dropdown-item">
                            <i
                                className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle">Besoin d'aide ?</span>
                        </Link>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    {/*<DropdownItem className='p-0'>*/}
                    {/*    <Link to={process.env.PUBLIC_URL + "/pages-profile-settings"} className="dropdown-item">*/}
                    {/*        <i*/}
                    {/*                className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span*/}
                    {/*                    className="align-middle">Paramètres</span>*/}
                    {/*    </Link>*/}
                    {/*</DropdownItem>*/}
                    <DropdownItem className='p-0'>
                        <Link to={process.env.PUBLIC_URL + "/logout"} className="dropdown-item">
                            <i
                                className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle" data-key="t-logout">Déconnexion</span>
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;