import React from 'react';
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";

const SimpleDonutCharts = ({ data, dataColors }) => {

    var chartDonutBasicColors = getChartColorsArray(dataColors);
    const series = [(data?.assignedTaskCount+data?.createdTaskCount),data?.inProgressTaskCount, data?.completedTaskCount, data?.validatedTaskCount]
    var options = {
        chart: {
            height: 280,
            type: 'donut',
        },
        labels: ["En attente", "En cours", "Complètes", "Validées"],
        dataLabels: {
            dropShadow: {
                enabled: true,
              }
        },
        legend: {
            position: 'bottom'
        },
        colors: chartDonutBasicColors
    };
    return (
        <ReactApexChart dir="ltr"
            series={series}
            options={options}
            type="donut"
            height={280}
            className="apex-charts mt-3"
        />

    )
}

export default SimpleDonutCharts;
