import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import avatar from "../../../../assets/images/users/avatar-user.png";
import TeamMembersChart from "../../components/TeamMembersChart";
import React, { useCallback, useState } from "react";
import AddEditFormTask from "../../components/addEditFormTask";
import AddFileToProjectModal from "../../components/loadfileform";
import AddEditFormProject from "../../components/addEditFormProject";
import SimpleBar from "simplebar-react";
import ProjectUpload from "../../components/projectUpload";
import { addProjectFiles } from "../../../../api/projects/projectsApi";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import SimpleDonutCharts from "../../components/TaskManagerCharts";
import { Progress, Statistic } from "antd";
import { LikeOutlined } from "@ant-design/icons";

const DescriptionTab = ({ overview, setProject }) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const [modal, setModal] = useState(false);
  const [modalTask, setModalTask] = useState(false);

  /**
   * Modal toggle adding task or subproject to this project
   **/
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modal]);
  const toggleTask = useCallback(() => {
    if (modalTask) {
      setModalTask(false);
    } else {
      setModalTask(true);
    }
  }, [modalTask]);
  const handleAddTask = () => {
    setModalTask(!modalTask);
    toggleTask();
  };
  const handleSubProjectClicks = () => {
    setModal(!modal);
    toggle();
  };

  /**
   * Adding project file function inside overview project page
   **/
  const [addfilemodal, setaddfilemodal] = useState(false);
  const handleCloseModal = () => {
    setaddfilemodal(false);
  };
  const [files, setFiles] = useState([]);
  function handleAddFileToProject() {
    if (files) {
      addProjectFilesMutation.mutate({ id: params.id, files: files });
    }
  }
  const addProjectFilesMutation = useMutation(addProjectFiles, {
    onSuccess: () => {
      queryClient.invalidateQueries("projects");
      toast.success("Fichier(s) ajouté(s) avec succès!");
      setFiles([]);
    },
    onError: () => {
      toast.error("Fichier(s) non sauvegarder!");
    },
  });

  return (
    <React.Fragment>
      <Row>
        <Col xxl={9}>
          <Card>
            <CardBody>
              <div className="text-muted">
                <h6 className="mb-3 fw-semibold text-uppercase">Description</h6>
                <p>{overview?.actualProject?.projectDescription}</p>

                <div className="pt-3 border-top border-top-dashed mt-4">
                  <Row>
                    <Col lg={3} sm={6}>
                      <div className="p-2 border border-dashed rounded">
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm me-2">
                            <div className="avatar-title rounded bg-transparent text-success fs-24">
                              <i className="ri-todo-fill"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-1">Tâche(s) :</p>
                            <h5 className="mb-0">{overview?.actualProject?.totalTaskCount}</h5>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div className="p-2 border border-dashed rounded">
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm me-2">
                            <div className="avatar-title rounded bg-transparent text-success fs-24">
                              <i className="ri-task-fill"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-1">Tâche(s) Complète(s) :</p>
                            <h5 className="mb-0">{overview?.actualProject?.completedTaskCount}</h5>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div className="p-2 border border-dashed rounded">
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm me-2">
                            <div className="avatar-title rounded bg-transparent text-success fs-24">
                              <i className="ri-folder-4-fill"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-1">Sous Projet(s) :</p>
                            <h5 className="mb-0">{overview?.subProjects?.length}</h5>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div className="p-2 border border-dashed rounded">
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm me-2">
                            <div className="avatar-title rounded bg-transparent text-success fs-24">
                              <i className="ri-user-settings-fill"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-1">Technicien(s) :</p>
                            <h5 className="mb-0">{overview?.projectMembers?.length}</h5>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium fs-13">
                          Tâche(s) Projet:{" "}
                          <span className="badge bg-danger fs-12">
                            {overview?.actualProject?.totalTaskCount}{" "}
                          </span>
                        </p>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium fs-13">
                          Tâche(s) Complète(s):{" "}
                          <span className="badge bg-success fs-12">
                            {overview?.actualProject?.completedTaskCount}{" "}
                          </span>
                        </p>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium fs-13">
                          Sous Projet(s):{" "}
                          <span className="badge bg-secondary fs-12">
                            {overview?.subProjects?.length}{" "}
                          </span>
                        </p>
                      </div>
                    </Col>
                    <Col lg={3} sm={6}>
                      <div>
                        <p className="mb-2 text-uppercase fw-medium fs-13">
                          Membres(s):{" "}
                          <span className="badge bg-primary fs-12">
                            {overview?.projectMembers?.length}{" "}
                          </span>
                        </p>
                      </div>
                    </Col> */}
                  </Row>
                </div>

                <div className="pt-3 border-top border-top-dashed mt-4">
                  <h6 className="mb-3 fw-semibold text-uppercase">
                    Raccourcis
                  </h6>
                  <Row className="g-3">
                    <Col xxl={4} lg={6}>
                      <div className="border rounded border-dashed p-2">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm">
                              <div className="avatar-title bg-light text-secondary rounded fs-24">
                                <i className="ri-upload-fill"></i>
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => setaddfilemodal(true)}
                            className="btn btn-success"
                          >
                            Ajouter un Fichier
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={4} lg={6}>
                      <div className="border rounded border-dashed p-2">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm">
                              <div className="avatar-title bg-light text-secondary rounded fs-24">
                                <i className="ri-file-fill"></i>
                              </div>
                            </div>
                          </div>
                          <button
                            className="btn btn-success"
                            onClick={() => handleAddTask()}
                          >
                            Ajouter une Tâche
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={4} lg={6}>
                      <div className="border rounded border-dashed p-2">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm">
                              <div className="avatar-title bg-light text-secondary rounded fs-24">
                                <i className="ri-folder-fill"></i>
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => handleSubProjectClicks()}
                            className="btn btn-success"
                          >
                            Ajouter un Sous Projet
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
          <CardBody></CardBody>
          </Card>
        </Col>
        <Col xxl={3}>
          <Card className="card-height-100">
            <CardHeader className="align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Techniciens</h4>
              <div className="flex-shrink-0"></div>
            </CardHeader>
            <SimpleBar style={{ maxHeight: "600px" }} className="px-3">
              <div className="card-body">
                <SimpleDonutCharts
                  data={overview?.actualProject}
                  dataColors='["#4F4F4F", "#1677FF", "#97DD72", "#FBB458"]'
                  className="apex-charts mt-3"
                  dir="ltr"
                />
                <div className="mt-4">
                  <ul className="list-unstyled vstack gap-4">
                    {(overview?.projectMembers || []).map((item, key) => (
                      <li key={key}>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <div className="avatar-xs">
                              <img
                                src={avatar}
                                alt=""
                                className="avatar-xs rounded-3 me-2"
                              />
                            </div>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h5 className="mb-1 fs-15">
                              {item?.firstname} {item?.lastname}
                            </h5>
                            <p className="mb-0 fs-12 text-muted">Technicien</p>
                          </div>
                          <b>{item?.count}</b>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div className="table-responsive table-card">
                  <table className="table table-borderless table-nowrap align-middle mb-0">
                    <thead className="table-light text-muted">
                    <tr>
                      <th scope="col">Membres</th>
                      <th scope="col">Tâches</th>
                      <th scope="col">Statut</th>
                    </tr>
                    </thead>
                    <tbody>
                    {(overview?.projectMembers || []).map((item, key) => (
                        <tr key={key}>
                          <td className="d-flex">
                            <img
                                src={avatar}
                                alt=""
                                className="avatar-xs rounded-3 me-2"
                            />
                            <div>
                              <h6 className="mb-0">{item?.firstname} {item?.lastname}</h6>
                              <p className="fs-13 mb-0 text-muted">
                                Technicien
                              </p>
                            </div>
                          </td>
                          <td>{item?.count}</td>
                          <td style={{width: "5%"}}>
                          {console.log((item?.count*100)/overview?.actualProject?.totalTaskCount)}
                            <TeamMembersChart
                                seriesData={`${(item?.count*100)/overview?.actualProject?.totalTaskCount}`}
                                chartsColor="#25a0e2"
                            />
                          </td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div> */}
              </div>
            </SimpleBar>
          </Card>
        </Col>
      </Row>

      {/* Sub Project Form Modal */}
      <AddEditFormProject
        projectItem={null}
        // setProject={setProject}
        modal={modal}
        setModal={setModal}
        isEdit={false}
        toggle={toggle}
      />

      {/* Task Form Modal */}
      <AddEditFormTask modal={modalTask} toggle={toggleTask} event={null} />

      {/* File Form Modal */}
      <Modal
        isOpen={addfilemodal}
        centered={true}
        toggle={handleCloseModal}
        className={"className"}
        size="md"
      >
        <ModalBody className={"className"} size="lg">
          <ProjectUpload files={files} setFiles={setFiles} />
          <div className="hstack gap-2 justify-content-end mt-2">
            <button
              onClick={() => handleAddFileToProject()}
              className="btn btn-success"
              id="btn-save-event"
            >
              Ajouter fichiers
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DescriptionTab;
