import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import BreadCrumb from "../../../../../Components/Common/BreadCrumb";
import { useQuery } from "react-query";
import { generateAuditTask } from "../../../../../api/audits/auditsApi";
import { toast } from "react-hot-toast";

const CompareAudit = () => {
  const { state } = useLocation();

  const [demandes, setdemandes] = useState([]);
  const [cassettes, setcassettes] = useState([]);
  const [detailmodal, setdetailmodal] = useState(false);

  useEffect(() => {
    loaddemandeinfo();
  }, []);



  async function loaddemandeinfo() {
    var datas = [];
    var cas = [];
    for (let i = 0; i < state?.length; i++) {

      let data =await generateAuditTask(state[i])
      datas.push(data);
    }
    //trie sur les demandes par date pour avoir le plus ancien comparé au plus nouveau
    if (datas[0]?.dueDate > datas[1]?.dueDate) {
      const _ = require("lodash");
      _.reverse(datas);
      console.log("reverse");
    }

    setdemandes([...datas]);
    setcassettes([datas[0]?.cassettesData, datas[1]?.cassettesData]);
  }

  function getlinenombre(data) {
    var nbrline = 0;
    let j;
    for (j = 0; j < data?.length; j++) {
      nbrline = nbrline + data[j].linesData?.length;
    }

    return nbrline;
  }

  function handleCloseModal() {
    setdetailmodal(false);
  }

  function loadCasetteAndSetModal() {
    setdetailmodal(true);
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <BreadCrumb title="Demande" pageTitle="Comparer" />

          <Row>
            <Col>
              <Card className="mb-3">
                <CardBody>
                  <div className="mb-4">
                    <div className="d-flex mb-3">
                      <h6 className="card-title mb-0 flex-grow-1">
                        Résumé Audit A{" "}
                      </h6>
                    </div>
                  </div>
                  <div className="table-card">
                    <table className="table mb-0">
                      <tbody>
                      <tr>
                        <td className="fw-medium">Boite</td>
                        <td>{demandes[0]?.boxRef}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Address</td>
                        <td>{demandes[0]?.address}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Date </td>
                        <td>{demandes[0]?.dueDate}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Card className="mb-3">
                <CardBody>
                  <div className="mb-4">
                    <div className="d-flex mb-3">
                      <h6 className="card-title mb-0 flex-grow-1">
                        Résumé Audit B{" "}
                      </h6>
                    </div>
                  </div>
                  <div className="table-card">
                    <table className="table mb-0">
                      <tbody>
                      <tr>
                        <td className="fw-medium">Boite</td>
                        <td>{demandes[1]?.boxRef}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Address</td>
                        <td>{demandes[1]?.address}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Date </td>
                        <td>{demandes[1]?.dueDate}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <Card className="mb-3">
              <CardBody>
                <div className="mb-4">
                  <div className="d-flex mb-3">
                    <h6 className="card-title mb-0 flex-grow-1">
                      Résumé Comparaison des cassettes{" "}
                    </h6>
                  </div>
                </div>
                <div className="table-card">
                  <table className="table mb-0">
                    <tbody>
                    <tr>
                      <td></td>
                      <td>Boite A</td>
                      <td>Boite B</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Nombre de cassette</td>
                      <td>{demandes[0]?.cassettesData?.length || 0}</td>
                      <td>{demandes[1]?.cassettesData?.length || 0}</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">Nombre de ligne</td>
                      <td>{getlinenombre(demandes[0]?.cassettesData) || 0}</td>
                      <td>{getlinenombre(demandes[1]?.cassettesData) || 0}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Card>

          <Card className="mx-5">
            <Link to={"/demande-compare/visualisation"} state={cassettes}>
              <button className="btn btn-primary ">Visualisation</button>
            </Link>
          </Card>
        </div>
      </React.Fragment>
  );
};
export default CompareAudit;
