import React, {useState} from "react";
import {Button, Card, Col, Row} from "reactstrap";
import EasyCropImage from "../../../../../../../Components/Common/CropImage/EasyCropImage";
import SimpleBar from "simplebar-react";
import {Drawer} from "antd";
import EditLinesCassetteDrawer from "../editLinesCassette/EditLinesCassetteDrawer";
import {Grow} from "transitions-kit";
import {AsyncImage} from "loadable-image";

import {Card as CardAnt} from 'antd';
import CassetteLine from "./CassetteLine";
import {DeleteOutlined, EditOutlined, SettingOutlined} from "@ant-design/icons";
import {useMutation, useQueryClient} from "react-query";
import {deleteBoxBottomCassette, deleteCassette} from "../../../../../../../api/audits/auditsApi";
import toast from "react-hot-toast";

const gridStyle = {
    width: '50%',
    textAlign: 'center',
};

const Cassette = ({cassette, status, key, toggleModal}) => {
    const [open, setOpen] = useState(false);
    const [isCrop, setIsCrop] = useState(false);
    const [newCassetteLine, setNewcassetteLine] = useState(null);
    
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const toggleCrop = () => {
        setIsCrop(!isCrop)
    }

    const queryClient = useQueryClient();
    const deleteCassetteMutation = useMutation(deleteCassette, {
        onSuccess: () => {
            toggleModal()
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("cassettes");
            toast.success("Cassette supprimée avec succès!");
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const deleteBoxBottomCassetteMutation = useMutation(deleteBoxBottomCassette, {
        onSuccess: () => {
            toggleModal()
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("cassettes");
            toast.success("Cassette supprimée avec succès!");
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const handleDelete = (cassette) => {
        console.log(cassette)
    }

    return (
        <>
            <CardAnt title={cassette && (cassette?.cassetteType?.toUpperCase().includes('cas')
                ? cassette?.cassetteType?.toUpperCase()
                : `Cas ${cassette?.cassetteType}`?.toUpperCase())}

                 extra={[
                     <a onClick={toggleCrop} className="me-2"><SettingOutlined key="setting" /></a>,
                    //  status !== 'Validated' ? (<a onClick={showDrawer} className="me-2"><EditOutlined key="edit"/></a>,
                    //  <a onClick={() => handleDelete(cassette)} className="me-2"><DeleteOutlined key="delete" /></a>) : null
                    status !== 'Validated' ?<a onClick={showDrawer} className="me-2"><EditOutlined key="edit"/></a>:null,
                    status !== 'Validated' ?<a onClick={() => handleDelete(cassette)} className="me-2"><DeleteOutlined key="delete" /></a>:null
                 ]}
            >
                <CardAnt.Grid style={gridStyle}>
                    {!isCrop && <AsyncImage
                        key={key}
                        src={`${process.env.REACT_APP_API_IMG_URL}/${cassette?.cassetteImageUrl}`}
                        style={{width: '100%', height: 600, borderRadius: 3}}
                        loader={<div style={{background: '#888'}}/>}
                        Transition={Grow}
                    />}
                    {isCrop && (
                        <EasyCropImage
                            status={status}
                            item={{img: cassette?.cassetteImageUrl, name: cassette?.cassetteImageUrl}}
                            isCassette={true}
                        />
                    )}
                </CardAnt.Grid>
                <CardAnt.Grid style={gridStyle}>
                    <SimpleBar style={{ maxHeight: "550px" }} className="px-3">
                        <table cellSpacing="0" border="0">
                            <colgroup span="11" width="100"></colgroup>
                            <thead>
                            <tr>
                                <th
                                    className="th-header"
                                    align="center"
                                    valign="middle"
                                    bgcolor="#C2BEBE"
                                    width="5%"
                                >
                                    <b>Capacité</b>
                                </th>
                                <th
                                    className="th-header"
                                    align="center"
                                    valign="middle"
                                    bgcolor="#C2BEBE"
                                    width="10%"
                                >
                                    <b>Câble</b>
                                </th>
                                <th
                                    className="th-header"
                                    align="center"
                                    valign="middle"
                                    bgcolor="#C2BEBE"
                                    width="4%"
                                >
                                    <b>Tube</b>
                                </th>
                                <th
                                    className="th-header"
                                    align="center"
                                    valign="middle"
                                    bgcolor="#C2BEBE"
                                    width="4%"
                                >
                                    <b>Bague</b>
                                </th>
                                <th
                                    className="th-header"
                                    align="center"
                                    valign="middle"
                                    bgcolor="#C2BEBE"
                                    width="4%"
                                >
                                    <b>Fibre</b>
                                </th>
                                <th
                                    className="th-header"
                                    align="center"
                                    valign="middle"
                                    bgcolor="#C2BEBE"
                                    width="8%"
                                >
                                    <b>Statut</b>
                                </th>
                                <th
                                    className="th-header"
                                    align="center"
                                    valign="middle"
                                    bgcolor="#C2BEBE"
                                    width="4%"
                                >
                                    <b>Fibre</b>
                                </th>
                                <th
                                    className="th-header"
                                    align="center"
                                    valign="middle"
                                    bgcolor="#C2BEBE"
                                    width="4%"
                                >
                                    <b>Bague</b>
                                </th>
                                <th
                                    className="th-header"
                                    align="center"
                                    valign="middle"
                                    bgcolor="#C2BEBE"
                                    width="4%"
                                >
                                    <b>Tube</b>
                                </th>
                                <th
                                    className="th-header"
                                    align="center"
                                    valign="middle"
                                    bgcolor="#C2BEBE"
                                    width="10%"
                                >
                                    <b>Câble</b>
                                </th>
                                <th
                                    className="th-header"
                                    align="center"
                                    valign="middle"
                                    bgcolor="#C2BEBE"
                                    width="5%"
                                >
                                    <b>Capacité</b>
                                </th>
                            </tr>
                            <tr>
                                <td
                                    colSpan={16}
                                    height="17"
                                    align="center"
                                    valign="middle"
                                >
                                    {cassette?.cassetteType?.toLowerCase().includes('cas')
                                        ? cassette?.cassetteType?.toUpperCase()
                                        : `Cas ${cassette?.cassetteType}`?.toUpperCase()}
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {(cassette?.linesData || []).map((line, i) => {
                                return <CassetteLine line={line} i={i}/>
                            })}
                            </tbody>
                        </table>
                    </SimpleBar>
                </CardAnt.Grid>
            </CardAnt>
            <Drawer
                zIndex={9999}
                title={cassette && (cassette?.cassetteType?.toUpperCase().includes('cas')
                    ? cassette?.cassetteType?.toUpperCase()
                    : `Cas ${cassette?.cassetteType}`?.toUpperCase())}
                maskClosable={false}
                height="70%"
                placement="bottom"
                onClose={onClose}
                open={open}
                key="bottom"
            >
                <EditLinesCassetteDrawer
                    cassette={cassette}
                />
            </Drawer>
        </>
    )
}

export default Cassette;