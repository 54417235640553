import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
const api = new APIClient();
import { v4 as uuidv4 } from 'uuid';
import * as moment from "moment";

export const createTask = async (data) => {
    const response = await api.create(url.ADD_DEMANDE, data.formData);

    if (response && data.projectId) {
        await api.create(url.ADD_TASK_TO_PROJECT({projectId: data.projectId, taskId: response.id}));
    }
    
    if (response && data.userId) {
        await api.create(url.ASSIGN_DEMANDE_TO_USER({userId:data.userId, auditTaskId:response.id}))
    }

    return {
      'id':response.id, 
      'title':response.name, 
      'start':response.dueDate, 
      'end':response.endDate, 
      'description':response.description, 
      'priority':response.priority, 
      'status':response.status, 
      'location':response.address, 
      'technicianId': response.assignedToTechnicianId
    };
}

export const getTasks = async () => {
    const response = await api.get(url.GET_DEMANDES,{"company": JSON.parse(sessionStorage.getItem("authUser"))?.company});
    const newArr = response.map((item) => {
      return {
        'id':item.id, 
        'title':item.name, 
        'start':item.dueDate, 
        'end':item.endDate, 
        'description':item.description, 
        'priority':item.priority, 
        'status':item.status, 
        'location':item.address, 
        'technicianId': item.assignedToTechnicianId
      }
    })
    return newArr;
}

export const updateTask = async (data) => {
    const response = await api.put(`${url.UPDATE_DEMANDE}/${data.id}`, data.formData);

    if (data.userId && response.assignedToTechnicianId) {
        await api.delete(url.REMOVE_ASSIGNATING_USER_TO_DEMANDE({userId:response.assignedToTechnicianId, auditTaskId:response.id}));
        await api.create(url.ASSIGN_DEMANDE_TO_USER({userId:data.userId, auditTaskId:response.id}))
    } else {
        await api.create(url.ASSIGN_DEMANDE_TO_USER({userId:data.userId, auditTaskId:response.id}))
    }

    return {
      'id':response.id, 
      'title':response.name, 
      'start':response.dueDate, 
      'end':response.endDate, 
      'description':response.description, 
      'priority':response.priority, 
      'status':response.status, 
      'location':response.address, 
      'technicianId': response.assignedToTechnicianId
    };
}

export const deleteTask = async (id) => {
    const response = await api.delete(url.DELETE_DEMANDE+'/'+id);
    return {id, ...response.data};
}

export const getTaskById = async (id) => {
    const response = await api.get(url.GET_DEMANDES+'/'+id);
    return response;
}

export const getEventsByTech = async () => {
    const response = await api.get(url.GET_USERS, {"company": JSON.parse(sessionStorage.getItem("authUser"))?.company})

    return {
        sections: response.filter(task => task?.auditTasks?.length>0).map(item => ({
            key: item?.userId,
            label: `${item?.firstname} ${item?.lastname}`,
        })),
        events: response.filter(task => task?.auditTasks?.length>0).map(item => item?.auditTasks?.map(audit => ({
            taskId: audit.id,
            text: audit?.name,
            address: audit?.address,
            start_date: moment(audit?.dueDate).format('YYYY-MM-DD hh:mm'),
            end_date: moment(audit?.endDate).format('YYYY-MM-DD hh:mm'),
            section_id: audit?.assignedToTechnicianId,
            color: audit?.status === 'Validated' 
                ? '#FBBF45' 
                : audit?.status === 'In_Progress'
                    ? '#1677FF'
                    : audit?.status === 'Completed'
                        ? '#6ACD39'
                        : '#8E8E8E'
        }))).flat()
    }
}

export const updateTaskTechnician = async (data) => {
    await api.delete(url.REMOVE_ASSIGNATING_USER_TO_DEMANDE({userId:data.old, auditTaskId:data.task}));
    await api.create(url.ASSIGN_DEMANDE_TO_USER({userId:data.new, auditTaskId:data.task}))
}