import React from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BreadCrumb from "../BreadCrumb";

const DashboardSkeleton = () => {
  document.title = `Accueil | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;
  const statCard = [
    {
      id: 1,
      lable: "TACHES COMPLETES",
      jobs: 10,
      series: [40],
      color: "#09b39b",
    },
    {
      id: 2,
      lable: "TACHES EN COURS",
      jobs: 15,
      series: [63],
      color: "#09b39b",
    },
    {
      id: 3,
      lable: "TACHES NON ASSIGNEES",
      jobs: 6,
      series: [23],
      color: "#09b39b",
    },
    {
      id: 4,
      lable: "PROJETS EN COURS",
      jobs: 14,
      series: [75],
      color: "#f06548",
    },
    {
      id: 5,
      lable: "TECHNICIENS",
      jobs: 20,
      series: [60],
      color: "#09b39b",
    },
    {
      id: 6,
      lable: "INGENIEURS",
      jobs: 8,
      series: [20],
      color: "#f06548",
    },
  ];

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Tableau de bord" pageTitle="Accueil" />
            <Row>
              <Col xl={6}>
                <div className="d-flex flex-column h-100">
                  <Row>
                    {statCard.map((widget, key) => (
                        <Col xl={6} md={6} key={key}>
                          <Card className="card-animate overflow-hidden">
                            <div
                                className="position-absolute start-0"
                                style={{zIndex: "0"}}
                            >
                              <svg
                                  version="1.2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 200 120"
                                  width="200"
                                  height="120"
                              >
                                <path
                                    id="Shape 8"
                                    style={{opacity: ".05", fill: "#5ea3cb"}}
                                    d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                                />
                              </svg>
                            </div>
                            <CardBody style={{zIndex: "1"}}>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                    {" "}
                                    {<Skeleton width={150}/>}
                                  </p>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                            <span className="counter-value" data-target="36894">
                              {<Skeleton width={20}/>}
                            </span>
                                  </h4>
                                </div>
                                <div className="flex-shrink-0">
                                  {<Skeleton circle width={60} height={60}/>}
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                    ))}
                  </Row>
                </div>
              </Col>
              <Col xl={6}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">{<Skeleton width={80}/>}</h4>
                  </CardHeader>
                  <CardBody>{<Skeleton height={250}/>}</CardBody>
                </Card>
              </Col>
              {/*<Col xl={3}>*/}
              {/*  <Card>*/}
              {/*    <CardHeader>*/}
              {/*      <h4 className="card-title mb-0">{<Skeleton width={80}/>}</h4>*/}
              {/*    </CardHeader>*/}
              {/*    <CardBody>{<Skeleton height={250}/>}</CardBody>*/}
              {/*  </Card>*/}
              {/*</Col>*/}
            </Row>
            <Row>
              <Col xxl={6}>
                <Card>
                  <CardHeader className="d-flex align-items-center">
                    <h4 className="card-title flex-grow-1 mb-0">{<Skeleton width={100}/>}</h4>
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive table-card">
                      <table className="table table-nowrap table-centered align-middle">
                        <thead className="bg-light text-muted">
                        <tr>
                          <th scope="col">{<Skeleton/>}</th>
                          <th scope="col">{<Skeleton/>}</th>
                          <th scope="col">{<Skeleton/>}</th>
                          <th scope="col">{<Skeleton/>}</th>
                          <th scope="col" style={{width: "10%"}}>
                            {<Skeleton/>}
                          </th>
                        </tr>
                        </thead>

                        <tbody>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item, key) => (
                            <tr key={key}>
                              <td className="fw-medium">{<Skeleton/>}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0 me-1 text-muted fs-13">
                                    {<Skeleton/>}
                                  </div>
                                  <div
                                      className="progress progress-sm  flex-grow-1"
                                      style={{width: "68%"}}
                                  >
                                    {<Skeleton/>}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="avatar-group flex-nowrap">
                                  <Link to="#" className="d-inline-block">
                                    {<Skeleton circle width={40} height={40}/>}
                                    {/* <img
                                src={avatar}
                                alt=""
                                className="rounded-circle avatar-xxs"
                              /> */}
                                  </Link>
                                </div>
                              </td>
                              <td>
                          <span
                              className={`badge bg-warning-subtle text-warning`}
                          >
                            {<Skeleton/>}
                          </span>
                              </td>
                              <td className="text-muted">{<Skeleton/>}</td>
                            </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="align-items-center mt-xl-3 mt-4 justify-content-end d-flex">
                      <div className="flex-shrink-0">
                        <Link
                            to="/projects"
                            className="text-muted text-decoration-underline"
                        >
                          {<Skeleton/>}
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3}>
                <Card>
                  {/*<CardHeader>*/}
                  {/*  <h4 className="card-title mb-0">{<Skeleton width={80}/>}</h4>*/}
                  {/*</CardHeader>*/}
                  <CardBody>{<Skeleton height={500}/>}</CardBody>
                </Card>
              </Col>
              <div className="col-xxl-3">
                <div className="card card-height-100">
                  <div className="card-header border-0">
                    <h4 className="card-title mb-0">{<Skeleton/>}</h4>
                  </div>
                  <div className="card-body pt-0">
                    <div className="upcoming-scheduled">
                      {<Skeleton height={300}/>}
                    </div>

                    <h6 className="text-uppercase fw-semibold mt-4 mb-3 text-muted">
                      {<Skeleton/>}
                    </h6>
                    {[1, 2, 3].map((item, index) => {
                      return [
                        <div
                            key={index}
                            className="mini-stats-wid d-flex align-items-center mt-3"
                        >
                          <div className="flex-shrink-0 avatar-sm">
                            {<Skeleton circle width={40} height={40}/>}
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6 className="mb-1">{<Skeleton/>}</h6>
                            <p className="text-muted mb-0">{<Skeleton/>}</p>
                          </div>
                          <div className="flex-shrink-0">
                            <p className="text-muted mb-0">{<Skeleton/>}</p>
                          </div>
                        </div>,
                      ];
                    })}
                    <div className="mt-3 text-center">
                      <Link
                          to="/demandes"
                          className="text-muted text-decoration-underline"
                      >
                        {<Skeleton/>}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default DashboardSkeleton;
