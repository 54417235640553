import React, { useRef } from "react";
import "./uploader.css";
// import {Button} from "reactstrap";
import {UploadOutlined} from "@ant-design/icons";
import {Upload, Button} from "antd";

export const FileUploader = ({ handleFileUpload , text,color="#FFF" }) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
        {/*<Upload>*/}
            <Button icon={<UploadOutlined/>} onClick={handleClick} style={{backgroundColor:color}}>{text}</Button>
        {/*</Upload>*/}
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
      />
    </>
  );
};
