import {Col} from "reactstrap";
import React, {useState} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import audexaLogo from '../../../../../../assets/login_page_assets/images/audexa.logo.png';
import { AsyncImage } from 'loadable-image'
import { Blur, Grow, Slide } from 'transitions-kit'

const CassetteCard = ({toggleModal, cassette, index, setSelectedCassette}) => {

    return (
        <>
            <Col lg={3} md={4} key={index}>
                <div
                    className="swiper-wrapper"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        toggleModal()
                        setSelectedCassette(cassette)
                    }}
                >
                    <div
                        className="gallery-box card"
                        style={{ height: "160px" }}
                    >
                        <div className="gallery-container">
                            <AsyncImage
                                // rootMargin='600px'
                                key={index}
                                src={`${process.env.REACT_APP_API_IMG_URL}/${cassette?.cassetteImageUrl}`}
                                style={{ width: 350, height: 180, borderRadius: 3 }}
                                loader={<div style={{ background: '#888' }} />}
                                Transition={Grow}
                            />
                            <div className="gallery-overlay">
                                <h5 className="overlay-caption">
                                    {cassette?.cassetteType?.toLowerCase().includes('cas')
                                        ? cassette?.cassetteType?.toUpperCase()
                                        : `Cas ${cassette?.cassetteType}`?.toUpperCase()}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </>

    )
}

export default CassetteCard;