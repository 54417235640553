import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import audexaLogo from "../../../../assets/login_page_assets/images/xnoir.png";
import { Link, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { saveAs } from "file-saver";
import React, {useCallback, useContext, useState} from "react";
import SimpleBar from "simplebar-react";
import classnames from "classnames";
import EasyCropImage from "../../../../Components/Common/CropImage/EasyCropImage";
import { useQuery, useQueryClient } from "react-query";
import { getImgAfterAuditById } from "../../../../api/audits/auditsApi";
import TaskContext from "../../hook/TaskContext";
import {Grow} from "transitions-kit";
import {AsyncImage} from "loadable-image";
import JSZip from "jszip";
import {Button, Card} from "antd";
const {Meta} = Card
import {
  ArrowDownOutlined,
  CheckCircleOutlined, DeleteOutlined, DownloadOutlined,
  EditOutlined,
  EllipsisOutlined, ExpandOutlined,
  SettingOutlined
} from "@ant-design/icons";

const TabMedia = () => {
  const {imagesBeforeAudit} = useContext(TaskContext)
  const routeParams = useParams();
  const queryClient = useQueryClient();
  //Get Audit Details
  const {
    isLoading,
    isError,
    error,
    data: imgAfterAudit,
  } = useQuery(["imgAfterAudit", routeParams.id], () => getImgAfterAuditById(routeParams.id));

  const downloadImage = (item) => {
    if (item.img) {
      saveAs(`${process.env.REACT_APP_API_IMG_URL}/${item?.img}`, `${item.name}`); // Put your image URL here.
    }
  };

  const [modalImage, setModalImage] = useState(false);
  const [image, setImage] = useState(null);
  function toggleModal(item) {
    setImage({img: item?.img, name: item?.name});
    setModalImage(!modalImage);
  }

  // Custom Vertical Tabs
  const [customverticalTab, setcustomverticalTab] = useState("1");
  const customtoggleVertical = (tab) => {
    if (customverticalTab !== tab) {
      setcustomverticalTab(tab);
    }
  };

  const [selectedCheckBoxImage, setSelectedCheckBoxImage] = useState([]);
  const [isActionButton, setIsActionButton] = useState(false);

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".imgCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => (ele.checked = true));
    } else {
      ele.forEach((ele) => (ele.checked = false));
    }

    selectCheckbox();
  }, []);

  const checkedAll2 = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll2");
    const ele = document.querySelectorAll(".imgCheckBox2");

    if (checkall.checked) {
      ele.forEach((ele) => (ele.checked = true));
    } else {
      ele.forEach((ele) => (ele.checked = false));
    }

    selectCheckbox();
  }, []);

  const selectCheckbox = () => {
    const ele = document.querySelectorAll(".imgCheckBox:checked");
    const ele2 = document.querySelectorAll(".imgCheckBox2:checked");

    ele.length > 0 || ele2.length > 0
        ? setIsActionButton(true)
        : setIsActionButton(false);

    if (ele.length>0) setSelectedCheckBoxImage(ele)
    if (ele2.length>0) setSelectedCheckBoxImage(ele2)
    // ele.length > 0
    //     ? setSelectedCheckBoxImage(ele)
    //     : setSelectedCheckBoxImage(ele2)
  }

  const downloadMultiple = () => {
    const zip = new JSZip();
    const folder = zip.folder('collection');
    const checkall = document.querySelectorAll(".imgCheckBox");
    const checkall2 = document.querySelectorAll(".imgCheckBox2");
    const all = document.getElementById("checkBoxAll");
    const all2 = document.getElementById("checkBoxAll2");
    selectedCheckBoxImage.forEach((element) => {
      const blodPromise = fetch(`${process.env.REACT_APP_API_ORIGINAL_IMG_URL}/${element.value}`).then(r => {
        if (r.status === 200) return r.blob()
        return Promise.reject(new Error(r.statusText))
      })
      const name = element.value;
      folder.file(name, blodPromise)
    });
    zip.generateAsync({ type:'blob'}).then(content => saveAs(content, 'audit-images')).catch(e => console.log(e))
    setIsActionButton(false);
    all ? all.checked = false : null;
    all2 ? all2.checked = false : null;
    checkall.forEach((el) => {
      el.checked = false;
    })
    checkall2.forEach((el) => {
      el.checked = false
    })

  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Nav tabs className="nav-tabs mb-3">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customverticalTab === "1" })}
                onClick={() => {
                  customtoggleVertical("1");
                }}
              >
                <div className="d-flex">
                  <span className="me-1">Avant</span>
                  {customverticalTab === "1" && (
                    <div className="form-check form-check-danger fs-15">
                      <input
                        onClick={() => checkedAll()}
                        className="form-check-input"
                        id="checkBoxAll"
                        type="checkbox"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkBoxAll"
                      ></label>
                    </div>
                  )}
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customverticalTab === "2" })}
                onClick={() => {
                  customtoggleVertical("2");
                }}
              >
                <div className="d-flex">
                  <span className="me-1">Après</span>
                  {customverticalTab === "2" && (
                    <div className="form-check form-check-danger fs-15">
                      <input
                        onClick={() => checkedAll2()}
                        className="form-check-input"
                        id="checkBoxAll2"
                        type="checkbox"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkBoxAll2"
                      ></label>
                    </div>
                  )}
                </div>
              </NavLink>
            </NavItem>
            {isActionButton && (
                <span className="mt-2">
                  <Button onClick={downloadMultiple} icon={<DownloadOutlined />} size="small"/>
                </span>
            )}
          </Nav>
          <TabContent activeTab={customverticalTab} className="text-muted">
            <TabPane tabId="1" id="before">
              <SimpleBar style={{ maxHeight: "440px" }} className="px-3">
                <Row>
                  {imagesBeforeAudit && imagesBeforeAudit.map((item, index) => {
                    return [
                      <Col xxl={2} key={index} className="mb-2">
                        <Card
                            title={<h6 className="fs-12 mt-2">{item?.name}</h6>}
                            cover={
                              <AsyncImage
                                  key={index}
                                  src={`${process.env.REACT_APP_API_IMG_URL}/${item?.img}`}
                                  style={{width: '100%', height: 100, borderRadius: 3}}
                                  loader={<div style={{background: '#888'}}/>}
                                  Transition={Grow}
                              />
                            }
                            extra={[
                              <a onClick={() => toggleModal(item)} className="me-1"><ExpandOutlined className="fs-12" key="setting"/></a>,
                              <a onClick={() => downloadImage(item)} className="me-2"><DownloadOutlined className="fs-12" key="down;pad"/></a>,
                              <input
                                  onChange={() => selectCheckbox()}
                                  className="imgCheckBox form-check-input"
                                  type="checkbox"
                                  value={item.img}
                                  id={"folderlistCheckbox_" + item.img}
                              />
                            ]}
                        >
                          <Meta
                              description={item?.comment}
                          />
                        </Card>
                        {/*<div className="swiper-wrapper">*/}
                        {/*  <div*/}
                        {/*    className="gallery-box card"*/}
                        {/*    style={{ height: "160px" }}*/}
                        {/*  >*/}
                        {/*    <div className="gallery-container">*/}
                        {/*      <div*/}
                        {/*        className="form-check form-check-danger fs-15 d-flex justify-content-end"*/}
                        {/*        style={{*/}
                        {/*          position: "absolute",*/}
                        {/*          zIndex: "1000",*/}
                        {/*          marginTop: "2px",*/}
                        {/*        }}*/}
                        {/*      >*/}
                        {/*        <input*/}
                        {/*          onChange={() => selectCheckbox()}*/}
                        {/*          className="imgCheckBox form-check-input"*/}
                        {/*          type="checkbox"*/}
                        {/*          value={item.img}*/}
                        {/*          id={"folderlistCheckbox_" + item.img}*/}
                        {/*        />*/}
                        {/*        <label*/}
                        {/*          className="form-check-label"*/}
                        {/*          htmlFor={"folderlistCheckbox_" + item.img}*/}
                        {/*        ></label>*/}
                        {/*      </div>*/}
                        {/*      <Link*/}
                        {/*        className="image-popup"*/}
                        {/*        to={item.img ? `${process.env.REACT_APP_API_IMG_URL}/${item?.img}` : item.img}*/}
                        {/*        target="_blanc"*/}
                        {/*      >*/}
                        {/*        /!*<LazyLoadImage*!/*/}
                        {/*        /!*  className="gallery-img img-fluid mx-auto"*!/*/}
                        {/*        /!*  effect="blur"*!/*/}
                        {/*        /!*  src={item.img ? `${process.env.REACT_APP_API_IMG_URL}/${item?.img}` : item.img}*!/*/}
                        {/*        /!*  alt="img_name"*!/*/}
                        {/*        /!*  placeholderSrc={audexaLogo}*!/*/}
                        {/*        /!*  height={100}*!/*/}
                                {/*/>*/}
                        {/*        <AsyncImage*/}
                        {/*            src={`${process.env.REACT_APP_API_IMG_URL}/${item?.img}`}*/}
                        {/*            style={{ width: 200, height:100, borderRadius: 3 }}*/}
                        {/*            loader={<div style={{ background: '#888' }} />}*/}
                        {/*            Transition={Grow}*/}
                        {/*        />*/}
                        {/*        <div className="gallery-overlay">*/}
                        {/*          <h5 className="overlay-caption">*/}
                        {/*            {item.name}*/}
                        {/*          </h5>*/}
                        {/*        </div>*/}
                        {/*      </Link>*/}
                        {/*    </div>*/}
                        {/*    <div className="box-content">*/}
                        {/*      <div className="d-flex align-items-center mt-1">*/}
                        {/*        <div className="flex-shrink-0">*/}
                        {/*          <div className="d-flex gap-3">*/}
                        {/*            <button*/}
                        {/*              type="button"*/}
                        {/*              className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"*/}
                        {/*              onClick={() => toggleModal(item)}*/}
                        {/*            >*/}
                        {/*              <i className="ri-crop-line text-muted align-bottom me-1"></i>*/}
                        {/*            </button>*/}
                        {/*            <button*/}
                        {/*              type="button"*/}
                        {/*              className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"*/}
                        {/*              onClick={() => downloadImage(item)}*/}
                        {/*            >*/}
                        {/*              <i className="ri-download-fill text-muted align-bottom me-1"></i>*/}
                        {/*            </button>*/}
                        {/*          </div>*/}
                        {/*        </div>*/}
                        {/*      </div>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                      </Col>,
                    ];
                  })}
                </Row>
              </SimpleBar>
            </TabPane>
            <TabPane tabId="2" id="after">
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner color="danger">Loading...</Spinner>
                </div>
              ) : (
                <SimpleBar style={{ maxHeight: "440px" }} className="px-3">
                  <Row>
                    {imgAfterAudit && imgAfterAudit.map((item, index) => {
                      return [
                        <Col xxl={2} key={index} className="mb-2">
                          <Card
                              title={<h6 className="fs-12 mt-2">{item?.name}</h6>}
                              cover={
                                <AsyncImage
                                    key={index}
                                    src={`${process.env.REACT_APP_API_IMG_URL}/${item?.img}`}
                                    style={{width: '100%', height: 100, borderRadius: 3}}
                                    loader={<div style={{background: '#888'}}/>}
                                    Transition={Grow}
                                />
                                // <img
                                //     alt="example"
                                //     src={`${process.env.REACT_APP_API_IMG_URL}/${item?.img}`}
                                //     height={100}
                                // />
                              }
                              extra={[
                                <a onClick={() => toggleModal(item)} className="me-1"><ExpandOutlined className="fs-12" key="setting"/></a>,
                                <a onClick={() => downloadImage(item)} className="me-2"><DownloadOutlined className="fs-12" key="down;pad"/></a>,
                                <input
                                    onChange={() => selectCheckbox()}
                                    className="imgCheckBox2 form-check-input"
                                    type="checkbox"
                                    value={item.img}
                                    id={"folderlistCheckbox_" + item.img}
                                />
                              ]}
                          >
                            <Meta
                                description={item?.comment}
                            />
                          </Card>
                          {/*<div className="swiper-wrapper">*/}
                          {/*  <div*/}
                          {/*    className="gallery-box card"*/}
                          {/*    style={{ height: "160px" }}*/}
                          {/*  >*/}
                          {/*    <div className="gallery-container">*/}
                          {/*      <div*/}
                          {/*        className="form-check form-check-danger fs-15 d-flex justify-content-end"*/}
                          {/*        style={{*/}
                          {/*          position: "absolute",*/}
                          {/*          zIndex: "1000",*/}
                          {/*          marginTop: "2px",*/}
                          {/*        }}*/}
                          {/*      >*/}
                          {/*        <input*/}
                          {/*          onChange={() => selectCheckbox()}*/}
                          {/*          className="imgCheckBox2 form-check-input"*/}
                          {/*          type="checkbox"*/}
                          {/*          value={item.img}*/}
                          {/*          id={"folderlistCheckbox_" + item.img}*/}
                          {/*        />*/}
                          {/*        <label*/}
                          {/*          className="form-check-label"*/}
                          {/*          htmlFor={"folderlistCheckbox_" + item.img}*/}
                          {/*        ></label>*/}
                          {/*      </div>*/}
                          {/*      <Link*/}
                          {/*        className="image-popup"*/}
                          {/*        to={item.img ? `${process.env.REACT_APP_API_IMG_URL}/${item?.img}` : null}*/}
                          {/*        target="_blanc"*/}
                          {/*      >*/}
                          {/*        /!*<LazyLoadImage*!/*/}
                          {/*        /!*  className="gallery-img img-fluid mx-auto"*!/*/}
                          {/*        /!*  effect="blur"*!/*/}
                          {/*        /!*  src={item.img ? `${process.env.REACT_APP_API_IMG_URL}/${item?.img}` : null}*!/*/}
                          {/*        /!*  alt="img_name"*!/*/}
                          {/*        /!*  placeholderSrc={audexaLogo}*!/*/}
                          {/*        /!*  height={100}*!/*/}
                                  {/*/>*/}
                          {/*        <AsyncImage*/}
                          {/*            src={`${process.env.REACT_APP_API_IMG_URL}/${item?.img}`}*/}
                          {/*            style={{ width: 200, height:100, borderRadius: 3 }}*/}
                          {/*            loader={<div style={{ background: '#888' }} />}*/}
                          {/*            Transition={Grow}*/}
                          {/*        />*/}
                          {/*        <div className="gallery-overlay">*/}
                          {/*          <h5 className="overlay-caption">*/}
                          {/*            Image After*/}
                          {/*          </h5>*/}
                          {/*        </div>*/}
                          {/*      </Link>*/}
                          {/*    </div>*/}
                          {/*    <div className="box-content">*/}
                          {/*      <div className="d-flex align-items-center mt-1">*/}
                          {/*        <div className="flex-shrink-0">*/}
                          {/*          <div className="d-flex gap-3">*/}
                          {/*            <button*/}
                          {/*              type="button"*/}
                          {/*              className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"*/}
                          {/*              onClick={() => toggleModal(item)}*/}
                          {/*            >*/}
                          {/*              <i className="ri-crop-line text-muted align-bottom me-1"></i>*/}
                          {/*            </button>*/}
                          {/*            <button*/}
                          {/*              type="button"*/}
                          {/*              className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"*/}
                          {/*              onClick={() => downloadImage(item)}*/}
                          {/*            >*/}
                          {/*              <i className="ri-download-fill text-muted align-bottom me-1"></i>*/}
                          {/*            </button>*/}
                          {/*          </div>*/}
                          {/*        </div>*/}
                          {/*      </div>*/}
                          {/*    </div>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                        </Col>,
                      ];
                    })}
                  </Row>
                </SimpleBar>
              )}
            </TabPane>
          </TabContent>
        </Col>
      </Row>
      <Modal
        id="imageModal"
        className="modal-xl"
        isOpen={modalImage}
        toggle={() => {
          toggleModal();
        }}
        backdrop={"static"}
      >
        <ModalHeader
          className="modal-title"
          id="imageModal"
          toggle={() => {
            toggleModal();
          }}
        ></ModalHeader>
        <ModalBody>
          <EasyCropImage
            item={image}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default TabMedia;
