import axios from "axios";
// import { api } from "../config";
import {message as msg} from '../Components/constants/message';

// default
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
//accept type
axios.defaults.headers.post['Accept'] = "application/json";
// content type
const token = sessionStorage.getItem("authUser") && JSON.parse(sessionStorage.getItem("authUser")).token;

if(token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
} else {
  delete axios.defaults.headers.common["Authorization"];
}

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error?.response?.status) {
      case 500:
        message = `${error?.response?.data || error?.response?.statusText}`;
        break;
      case 401:
        message = `${error?.response?.data || error?.response?.statusText}`;
        break;
      case 403:
        message = `${error?.response?.data || msg.UNAUTHORIZED}`;
        break;
      case 404:
        message = `${error?.response?.data || msg.NOT_FOUND}`;
        break;
      case 400:
        message = `${error?.response?.data}`
        break;
      default:
        message = 'Sorry, you are not authorized to access this page.';
        // message = error.message || 'UNAUTHORIZED';
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };

  sendFormData = (url, formData) => {
    // console.log("api_helper  --"+url+"  -- formdata  "+formData+"  le token est "+token);
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
        //,'Authorization': `Bearer ${token}`,
      },
    });
  };
  
  putFormData = (url, formData) => {
   return axios.put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
        //,'Authorization': `Bearer ${token}`,
      },
    });
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };