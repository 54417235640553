import {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import {useQuery} from "react-query";
import {getAuditHistoryByRef} from "../../../../api/audits/auditsApi";
import TaskContext from "../../hook/TaskContext";

const AuditHistory = () => {
    const {box} = useContext(TaskContext)
    const [history, setHistory] = useState([]);
    const [demandeSelectedToCompare, setdemandeSelectedToCompare] = useState([]);
    const [comparebtnvisible, setcomparebtnvisible] = useState(false);
    const {
        isLoading,
        isError,
        error,
        data: auditHistory,
    } = useQuery(["history", box?.boxReference], () => getAuditHistoryByRef(box?.boxReference));

    function addDemandeForCompare(data){

        var size = demandeSelectedToCompare.length;
 
         if(demandeSelectedToCompare.find((item)=>item==data.id)==null){
             setdemandeSelectedToCompare([...demandeSelectedToCompare,data.id])
             size=size+1;
         }else{
             const tab = demandeSelectedToCompare.filter(item=>item!=data.id);
             setdemandeSelectedToCompare(tab);
             size=size-1;
         }
 
         if(size==2){
             setcomparebtnvisible(true)
         }else{
             setcomparebtnvisible(false)
         }
 
    }

    function submitCompare(){
        // console.log(demandeSelectedToCompare);
    }

    useEffect(() => {
        // Recuperation de l'historique d'audit sur la meme boite
        if (auditHistory) {
            setHistory(auditHistory)
        }
    }, [auditHistory])

    const handleOpenFile = (file) => {
        window.open(`${process.env.REACT_APP_API_URL}/data-file/download-file/${file}`)
    }

  return (
    <Col xl={12}>
        {history.length> 0 ? (
            <SimpleBar style={{ maxHeight: "364px" }} className="p-3">
                <div className="acitivity-timeline acitivity-main">
                    {history?.map((item, i) => {
                        return [
                            <div className="acitivity-item d-flex" key={i}>
                                <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                    <div className="avatar-title bg-success-subtle text-success rounded-circle">
                                        <i className="ri-stack-fill"></i>
                                    </div>
                                </div>
                                {history?.length>1 ? (
                                    <input
                                        type="checkbox"
                                        onClick={() => addDemandeForCompare(item)}
                                    />
                                ) : null}

                                <div className="flex-grow-1 ms-3">
                                    <h6 className="mb-1">{item?.name}</h6>
                                    <p className="text-muted mb-1">{item?.description}</p>
                                    <small className="mb-0 text-muted">{item?.createdDate}</small>
                                </div>
                            </div>,
                        ];
                    })}
                    <br />
                    {comparebtnvisible == true ? (
                        <Link to="/demande-compare" state={demandeSelectedToCompare}>
                            <button className="btn btn-success"> Comparer </button>
                        </Link>
                    ) : (
                        <div></div>
                    )}
                </div>
            </SimpleBar>
        ) : (
            <div className="py-4 text-center">
                <div>
                    <lord-icon
                        src="https://cdn.lordicon.com/hejvpxpn.json"
                        trigger="loop"
                        colors="primary:#405189,secondary:#0ab39c"
                        style={{width: "72px", height: "72px"}}
                    ></lord-icon>
                </div>

                <div className="mt-4">
                    <h5>Désolé! historique vide!</h5>
                </div>
            </div>
        )}

    </Col>
  );
};

export default AuditHistory;
