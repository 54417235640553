import {APIClient} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import axios from "axios";

const api = new APIClient();

export const createMember = async (data) => {
    const response = await api.create(url.ADD_USER, data.formData);
    if (data.profile) {
        await axios.put(url.UPLOAD_USER_IMG(response.id), {'profile': data.profile}, {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        });
    }
    return {...response, userId: response.id, phone: response.mobileNumber};
}

export const createMultiMembers = async (data) => {
    const response = await api.create(url.ADD_MULTI_USERS, data);
    return response;
}

export const getMembers = async () => {
    if (JSON.parse(sessionStorage.getItem("authUser"))?.isSuperAdmin) {
        return await api.get(url.GET_USERS_BY_SA(JSON.parse(sessionStorage.getItem("authUser"))?.userId)).then((data) => {
            return (data.map((user) => ({
                userId:user.userId,
                firstname:user.firstname,
                lastname:user.lastname,
                userRole:user.userRole,
                userImgUrl:user.userImgUrl,
                company:user.company,
                email:user.email,
                enabled:user.enabled,
                phone:user.phone,
                username:user.username,
                superAdminId:user.superAdminId,
                inProgressTasks: user?.auditTasks?.filter((task) => task.status === "In_Progress")?.length,
                validatedTasks: user?.auditTasks?.filter((task) => task.status === "Validated")?.length,
                completedTasks: user?.auditTasks?.filter((task) => task.status === "Completed")?.length,
                waitingTasks: user?.auditTasks?.filter((task) => task.status === "Affected" || task.status === "Created")?.length,
                progression: Math.round((user?.auditTasks?.filter((task) => task.status === "Completed")?.length * 100) / user?.auditTasks?.length) || 0,
            })))
        });
    } else {
        return await api.get(url.GET_USERS, {"company": JSON.parse(sessionStorage.getItem("authUser"))?.company}).then((data) => {
            return (data.map((user) => ({
                userId:user.userId,
                firstname:user.firstname,
                lastname:user.lastname,
                userRole:user.userRole,
                userImgUrl:user.userImgUrl,
                company:user.company,
                email:user.email,
                enabled:user.enabled,
                phone:user.phone,
                username:user.username,
                superAdminId:user.superAdminId,
                inProgressTasks: user?.auditTasks?.filter((task) => task.status === "In_Progress")?.length,
                validatedTasks: user?.auditTasks?.filter((task) => task.status === "Validated")?.length,
                completedTasks: user?.auditTasks?.filter((task) => task.status === "Completed")?.length,
                waitingTasks: user?.auditTasks?.filter((task) => task.status === "Affected" || task.status === "Created")?.length,
            })))
        });
    }
}

export const getMembersBySuperAdmin = async () => {
    const response = await api.get(url.GET_USERS_BY_SA(JSON.parse(sessionStorage.getItem("authUser"))?.userId));
    return response;
}

export const updateMember = async (data) => {
    const response = await api.put(`${url.UPDATE_USER}/${data.userId}`, data.formData);
    if (data.profile) {
        await axios.put(url.UPLOAD_USER_IMG(response.id), {'profile': data.profile}, {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        });
    }
    return {...response, userId: response.id, phone: response.mobileNumber};
}

export const deleteMember = async (id) => {
    const response = await api.delete(url.DELETE_USER+'/'+id);
    return {id, ...response.data};
}

export const getMemberById = async (id) => {
    const response = await api.get(url.GET_USERS+'/'+id);
    return response;
}
