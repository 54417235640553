import React, {useState, useCallback, useMemo, useContext} from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
  ReverseUserRoleFormat,
  UserRoleFormat,
} from "../../helpers/role_format";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Empty, Result, Space, Spin } from "antd";
// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import SideCardInfo from "./component/sideCardInfo";
import AddEditForm from "./component/addEditForm";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ExampleDoc from "../../assets/ressources/MODEL_DE_DONNEES_USERS.xlsx";

import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  createMember,
  createMultiMembers,
  deleteMember,
  getMembers,
  getMembersBySuperAdmin,
  updateMember,
} from "../../api/users/usersApi";
import TableContainerMin from "../../Components/Common/TableContainerMin";
import toast from "react-hot-toast";
import { FileUploader } from "../../Components/Common/FileUpload/FileUploader";
import AddEditDrawer from "./component/addEditDrawer";
import AddMultiUsers from "./component/addMultiUsers";
import loading from "../../assets/images/loading.gif"
import HistoryContext from "../../Components/Hooks/HistoryContext";
const Teams = () => {
  const history = useContext(HistoryContext)
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    error,
    data: users,
  } = useQuery("users", getMembers);

  const addMemberMutation = useMutation(createMember, {
    onSuccess: () => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("users");
      toast.success("L'utilisateur a été créé avec succès");
    },
    onError: (error) => {
      // An error happened!
      toast.error(error);
    },
  });

  const addMultiMembersMutation = useMutation(createMultiMembers, {
    onSuccess: (data) => {
      console.log(data);
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("users");
      if (Object.keys(data.unregisteredUsers).length > 0) {
        for (
          let index = 0;
          index < Object.keys(data.unregisteredUsers).length;
          index++
        ) {
          toast.error(Object.keys(data.unregisteredUsers)[index]);
        }
      } else {
        toast.success("Utilisateurs créés avec succès!");
      }
    },
    onError: (error) => {
      console.log(error);
      // An error happened!
      toast.error(error);
    },
  });

  const updateMemberMutation = useMutation(updateMember, {
    onSuccess: () => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("users");
      toast.success("L'utilisateur a été modifié avec succès");
    },
  });

  const deleteMemberMutation = useMutation(deleteMember, {
    onSuccess: () => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("users");
      toast.success("L'utilisateur a été supprimé avec succès");
    },
  });

  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState(null);
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setUser(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // Delete Data
  const handleDeleteUser = () => {
    if (user) {
      deleteMemberMutation.mutate(user.userId);
      setDeleteModal(false);
    }
  };

  const onClickDelete = (user) => {
    setUser(user);
    setDeleteModal(true);
  };

  // Add Data
  const handleUserClicks = () => {
    setUser("");
    setIsEdit(false);
    toggle();
  };

  // Update Data
  const handleUserClick = useCallback(
    (arg) => {
      const user = arg;
      setUser({
        userId: user.userId,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        mobileNumber: user.phone,
        username: user.username,
        userRole: user.userRole,
        userImgUrl: user.userImgUrl,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      deleteMemberMutation.mutate(element.value);
    });

    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original.userId}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Nom Utilisateur",
        accessor: "username",
        filterable: false,
        Cell: (user) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <div className="flex-shrink-0 avatar-xs me-2">
                  <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                    {user.row.original.firstname.charAt(0).toUpperCase()}
                    {user.row.original.lastname.charAt(0).toUpperCase()}
                  </div>
                </div>
                {/* {user.row.original.userImgUrl ? (
                  <img
                    src={`https://ik.imagekit.io/piscodeId/${user.row.original.userImgUrl}`}
                    alt=""
                    className="avatar-xs rounded-circle"
                  />
                ) : (
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-success-subtle text-success rounded-circle fs-13">
                      {user.row.original.firstname.charAt(0)}
                      {user.row.original.lastname.charAt(0)}
                    </div>
                  </div>
                )} */}
              </div>
              <div className="flex-grow-1 ms-2 name">
                {user.row.original.username}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Nom",
        accessor: "lastname",
        filterable: false,
      },
      {
        Header: "Prénom",
        accessor: "firstname",
        filterable: false,
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
      },
      {
        Header: "Téléphone",
        accessor: "phone",
        filterable: false,
      },
      {
        Header: "Rôle",
        accessor: "userRole",
        filterable: false,
        Cell: (cell) => {
          return <>{UserRoleFormat(cell.value)}</>;
        },
      },
      {
        Header: "Etat Compte",
        accessor: "enabled",
        filterable: false,
        Cell: (cell) => {
          return (
            <React.Fragment>
              {cell.value ? (
                <span className="badge bg-success text-uppercase">Active</span>
              ) : (
                <span className="badge bg-danger text-uppercase">
                  Desactive
                </span>
              )}
            </React.Fragment>
          );
        },
      },
      {
        Header: " ",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Call">
                <Link
                  to="#"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    setInfo(userData);
                  }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item edit" title="Message">
                <Link
                  to="#"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    handleUserClick(userData);
                  }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                </Link>
              </li>
              {cellProps.row.original.userId !==
              JSON.parse(sessionStorage.getItem("authUser")).userId ? (
                <li className="list-inline-item edit" title="Message">
                  <Link
                    to="#"
                    onClick={() => {
                      const userData = cellProps.row.original;
                      onClickDelete(userData);
                    }}
                    className="text-muted d-inline-block"
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                  </Link>
                </li>
              ) : null}
            </ul>
          );
        },
      },
    ],
    [handleUserClick, checkedAll]
  );

  const [info, setInfo] = useState(null);

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);

      addMultiMembersMutation.mutate(
        parsedData.map((item) => ({
          firstname: item.Nom,
          lastname: item.Prénom,
          email: item.Email,
          mobileNumber: item.Téléphone,
          username: item.Nom_Utilisateur,
          userRole: ReverseUserRoleFormat(item.Rôle),
          userImageUrl: "",
        }))
      );
    };
  };

  const saveFile = () => {
    saveAs("../../assets/ressources/template_users.xlsx", "template.xlsx");
  };

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = `Personnel | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;

  if (isLoading) {
    return (
      <React.Fragment>
           
      <div className="page-content">
          <Container style={{display:"flex",'justify-content': 'center','align-items': 'center',height:"80vh"}}>
          <img src={loading} alt='loading'/>
          </Container>
      </div>
  </React.Fragment>
    );
  }

  if (isError) {
    return (
      <div className="page-content">
        <Container fluid>
          <Result
            status="warning"
            title="La ressource demandée est introuvable. Veuillez vérifier votre connexion et réessayer."
            extra={
              <Button
                type="primary"
                key="console"
                onClick={() => window.location.reload()}
              >
                Refresh
              </Button>
            }
          />
        </Container>
      </div>
    );
    // return <p>{error.message}</p>;
  }

  return (
    <div className="page-content">
      <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={users || []}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb
            page="Personnel"
            history={history}
        />
        <Row>
          <Col xxl={`${info ? 9 : 12}`}>
            <Card id="contactList">
              <CardHeader>
                {/* <HeaderTable />
          <FileUploader handleFile={handleFile} /> */}
                <div className="d-flex align-items-center justify-content-end flex-wrap gap-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2">
                      {isMultiDeleteButton && (
                        <button
                          className="btn btn-danger"
                          onClick={() => setDeleteModalMulti(true)}
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                      )}
                      <a
                        href={ExampleDoc}
                        download="MODEL_DE_DONNEES_USERS"
                        target="_blank"
                      >
                        <Button
                          icon={<DownloadOutlined />}
                          style={{ backgroundColor: "#BCED09" }}
                        >
                          Model excel
                        </Button>
                      </a>
                      <AddMultiUsers queryClient={queryClient} />
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          setModal(true);
                          setUser("");
                          setIsEdit(false);
                        }}
                      >
                        Nouveau compte
                      </Button>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <TableContainerMin
                    columns={columns}
                    data={users || []}
                    isGlobalSearch={true}
                    isGlobalFilter={true}
                    isUsersFilter={false}
                    customPageSize={10}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-3"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light"
                    handleUserClick={handleUserClicks}
                    SearchPlaceholder="Recherche..."
                    // HeaderTable={HeaderTable}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          {info && <SideCardInfo info={info} setInfo={setInfo} />}
        </Row>

        <AddEditForm
          modal={modal}
          setModal={setModal}
          toggle={toggle}
          isEdit={isEdit}
          user={user}
          addMemberMutation={addMemberMutation}
          updateMemberMutation={updateMemberMutation}
        />
        {/*<AddEditDrawer open={open} setOpen={setOpen}/>*/}
      </Container>
    </div>
  );
};

export default Teams;
