import {Button, Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {Switch, Tooltip, Badge, Button as BtnAntd, Space, Popconfirm} from "antd";
import {CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SearchOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import toast from "react-hot-toast";
import {deleteSpace, updateSpaceState} from "../../../api/space/spaceAPI";
import companyLogo from '../../../assets/images/companies/company-logo.png'
import SpaceOverview from "../overview";


const SpaceCard = ({space, setOpen, setItem, setIsEdit}) => {
    const queryClient = useQueryClient();
    const [openSpace, setOpenSpace] = useState(false);
    const {isLoading, mutate} = useMutation(deleteSpace, {
        onSuccess: () => {
            queryClient.invalidateQueries("spaces")
            toast.success("Espace supprimé avec succès!")
        }
    })

    const [isDesabled, setIsDesabled] = useState(null)
    const updateSpaceStateMutation = useMutation(updateSpaceState, {
        onSuccess: (data) => {
            // console.log(data)
        }
    })

    const handleChangeSpaceState = (item) => {
        const space = {
            "id": item?.id,
            "engineerNumber": item?.engineerNumber,
            "techNumber": item?.techNumber,
            "logoUrl": item?.logoUrl,
            "company": item?.company,
            "adminId": item?.adminId,
            "isEnable": !item?.isEnable,
            "superAdminId": item?.superAdminId,
            "companyName": item?.companyName
        }
        updateSpaceStateMutation.mutate(space)
    }

    function hendleOpenSpaceViewDrawer() {
        setOpenSpace(true)
    }

    const [openCM, setOpenCM] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = () => {
        setOpenCM(true);
    };
    const handleOk = (space) => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpenCM(false);
            setConfirmLoading(false);
        }, 2000);
        if(space) {
            mutate(space?.id || space?.clientSpace?.id)
        }
    };
    const handleCancel = () => {
        setOpenCM(false);
    };

    return (
        <React.Fragment>
            <Col xxl={2} lg={6}>
                <Badge.Ribbon color={`${space?.isEnable || space?.clientSpace?.isEnable ? 'green' : 'red'}`} text={`${space?.isEnable || space?.clientSpace?.isEnable ? 'Activé' : 'Désactivé'}`}>
                <Card className="ribbon-box right overflow-hidden">
                    <CardBody className="text-center p-4">
                        {space?.logoUrl || space?.clientSpace?.logoUrl
                            ? (<img
                                src={`${process.env.REACT_APP_API_IMG_URL}/${space?.logoUrl || space?.clientSpace?.logoUrl}`}
                                alt=""
                                height="45"/>)
                            : (
                                <img
                                    src={companyLogo}
                                    alt=""
                                    height="45"/>
                            )
                        }
                        <h5 className="mb-1 mt-4">
                            <Link
                                onClick={hendleOpenSpaceViewDrawer}
                                className="link-primary"
                            >
                                {space?.companyName || space?.clientSpace?.companyName}
                            </Link>
                        </h5>
                        <p className="text-muted mb-4">{space?.adminId || space?.admin?.username}</p>
                        <Row className="mt-4">
                            <Col lg={6} className="border-end-dashed border-end">
                                <h5>{space?.techNumber || space?.clientSpace?.techNumber}</h5>
                                <span className="text-muted">Techniciens</span>
                            </Col>
                            <Col lg={6}>
                                <h5>{space?.engineerNumber || space?.clientSpace?.engineerNumber}</h5>
                                <span className="text-muted">Ingénieurs</span>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="hstack gap-2 d-flex justify-content-between">
                        <Switch
                            disabled={true}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked
                            onChange={(e) => {
                                setIsDesabled(e)
                                handleChangeSpaceState(space)
                            }}
                        />
                        <div>
                            <Space>
                                <Tooltip title="Modifier">
                                    <BtnAntd onClick={() => {
                                        setIsEdit(true)
                                        setOpen(true)
                                        setItem(space)
                                    }} shape="circle" icon={<EditOutlined />} size="small" />
                                </Tooltip>
                                <Popconfirm
                                    title="Suppression espace"
                                    description="Voulez-vous supprimez l'espace ?"
                                    open={openCM}
                                    onConfirm={() => handleOk(space)}
                                    okButtonProps={{
                                        loading: confirmLoading,
                                    }}
                                    onCancel={handleCancel}
                                >
                                    <Tooltip title="Supprimer">
                                        <BtnAntd
                                            onClick={showPopconfirm}
                                            //onClick={() => deleteSpaceMutation.mutate(space?.adminId || space?.clientSpace?.adminId)}
                                            shape="circle"
                                            danger
                                            icon={<DeleteOutlined />}
                                            size="small"
                                        />
                                    </Tooltip>
                                </Popconfirm>
                            </Space>
                        </div>
                    </CardFooter>
                </Card>
                </Badge.Ribbon>
            </Col>
            <SpaceOverview space={space} openSpace={openSpace} setOpenspace={setOpenSpace} />
        </React.Fragment>
    )
}

export default SpaceCard;