import axios from "axios";
import { APIClient, setAuthorization } from "../../helpers/api_helper";
import { jwtDecode } from "jwt-decode";
import * as url from "../../helpers/url_helper";
import {CHECK_COMPANY_LICENCE} from "../../helpers/url_helper";
const api = new APIClient();

const AUTHORITIES = ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_BACK_OFFICE_MANAGER'];

export const authUser = async (data) => {

    try {
        const response = await axios.post(process.env.REACT_APP_KEYCLOAK_SERVER, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        });

        if (response) setAuthorization(response.access_token)

        const token_parsed = jwtDecode(response.access_token);

        const roles = token_parsed.realm_access.roles.filter((item) => item === 'ROLE_ADMIN' || item === 'ROLE_BACK_OFFICE_MANAGER' || item === 'ROLE_SUPER_ADMIN');

        if (AUTHORITIES.some(r => roles.includes(r))) {
            if (roles.includes('ROLE_SUPER_ADMIN')) {
                saveUserToSessionStorage({
                    token: response.access_token,
                    ...token_parsed,
                    roles
                })
            } else {
                try {
                    const isLicenceKeyValid = await api.get(url.CHECK_COMPANY_LICENCE(token_parsed.company));
                    
                    if (isLicenceKeyValid) {
                        const space = await api.get(url.GET_SPACE_BY_COMPANY(token_parsed.company)).catch((e) => {
                            return Promise.reject("Espace Client non trouvé!");
                        })
                        saveUserToSessionStorage({
                            token: response.access_token,
                            ...token_parsed,
                            roles,
                            companyName: space?.companyName || ''
                        })
                    }
                } catch (e) {
                    return Promise.reject("Clé de licence invalide!");
                }

            }

            return {roles, isAuthorized: true};
        } else {
            return {roles, isAuthorized: false};
        }


        // if (response) {
        //     setAuthorization(response.access_token)
        //     const token_parsed = jwtDecode(response.access_token);
        //     try {
        //         const roles = token_parsed.realm_access.roles.filter((item) => item === 'ROLE_ADMIN' || item === 'ROLE_BACK_OFFICE_MANAGER' || item === 'ROLE_SUPER_ADMIN');
        //
        //         if (!roles.includes('ROLE_SUPER_ADMIN')) {
        //             const isLicenceKeyValid = await api.get(url.CHECK_COMPANY_LICENCE(token_parsed.company));
        //             if(isLicenceKeyValid) {
        //                 if (AUTHORITIES.some(r => roles.includes(r))) {
        //                     const space = await api.get(url.GET_SPACE_BY_COMPANY(token_parsed.company))
        //                     sessionStorage.setItem("authUser", JSON.stringify({
        //                         token: response.access_token,
        //                         fullName: token_parsed.name,
        //                         email: token_parsed.email,
        //                         roles: roles,
        //                         company: token_parsed.company,
        //                         companyName: space && space?.companyName,
        //                         superAdminId: token_parsed.superAdminId,
        //                         userId: token_parsed.userId,
        //                         isSuperAdmin: !!roles.includes('ROLE_SUPER_ADMIN')
        //                     }));
        //                     return {roles, isAuthorized: true};
        //                 }
        //             }
        //         } else {
        //             if (AUTHORITIES.some(r => roles.includes(r))) {
        //                 sessionStorage.setItem("authUser", JSON.stringify({
        //                     token: response.access_token,
        //                     fullName: token_parsed.name,
        //                     email: token_parsed.email,
        //                     roles: roles,
        //                     company: token_parsed.company,
        //                     companyName: token_parsed?.company,
        //                     superAdminId: token_parsed.superAdminId,
        //                     userId: token_parsed.userId,
        //                     isSuperAdmin: !!roles.includes('ROLE_SUPER_ADMIN')
        //                 }));
        //                 return {roles, isAuthorized: true};
        //             }
        //         }
        //
        //     } catch (e) {
        //         return Promise.reject("Clé de licence invalide!");
        //     }
        //
        //     return {...response, isAuthorized: false};
        // }
        // return Promise.reject(response);
    } catch (e) {
        return Promise.reject("Vous n'êtes pas autorisé à accéder à la ressource!");
    }
}

export const saveUserToSessionStorage = (user) => {
    if (user) {
        sessionStorage.setItem('authUser', JSON.stringify({
            token: user.token,
            fullName: user.name,
            email: user.email,
            roles: user.roles,
            company: user.company,
            companyName: user.companyName || user.company,
            superAdminId: user.superAdminId,
            userId: user.userId,
            isSuperAdmin: !!user.roles.includes('ROLE_SUPER_ADMIN')
        }))
    }
}

export const logout = () => {
    sessionStorage.clear();
    return true;
}