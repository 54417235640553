import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import toast from "react-hot-toast";

const api = new APIClient();

export const createCommand = async (data) => {
    try {
        const response = await api.create(url.ADD_COMMAND, data);
        if (response && data.taskId) {
            await api.create(url.ADD_COMMAND({auditTaskId: data.taskId,clientId: response.id}))
        }
        return response;
    } catch (error) {
        console.error("Erreur lors de la création de la commande :", error);
        throw error; // Rejeter l'erreur pour qu'elle puisse être gérée par le code appelant
    }
}

export const getCommands = async () => {
    try {
        const response = await api.get(url.GET_COMMANDS, {"company": JSON.parse(sessionStorage.getItem("authUser"))?.company});
        console.log(response);
        return response;
    } catch (error) {
        console.error("Erreur lors de la récupération des commandes :", error);
        throw error;
    }
}

export const getCommandById = async (id) => {
    try {
        const response = await api.get(url.GET_COMMANDS+'/'+id);
        return response;
    } catch (error) {
        console.error("Erreur lors de la récupération de la commande par ID :", error);
        throw error;
    }
}

export const getCommandByCompany = async (company) => {
    try {
        const response = await api.get(url.GET_COMMANDS_COMPANY+ '/' +company);
        return response;
    } catch (error) {
        console.error("Erreur lors de la récupération de la commande par entreprise :", error);
        throw error;
    }
}

export const downloadCommandPDF = async (commandId, commandNumber) => {
    try {
        // Récupérer le document PDF depuis l'API
        const response = await api.get(url.DOWNLOAD_PDF + commandId, {
            responseType: 'arraybuffer',
        });
        
        // Créer un objet Blob pour le PDF
        const pdfBlob = new Blob([response], { type: 'application/pdf' });

        // Créer un URL pour le Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Créer un lien de téléchargement pour le PDF
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.setAttribute('download', `Commande_${commandNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error("Erreur lors du téléchargement du PDF de la commande :", error);
        // Afficher un message d'erreur à l'utilisateur
        toast.error('Les données récupérées ne sont pas correctes. Veuillez réessayer plus tard.');
    }
};
