import React from "react";
import { FileUploader } from "../../../../Components/Common/FileUpload/FileUploader";
import * as XLSX from "xlsx";
import { useMutation, useQueryClient } from "react-query";
import { reversAuditByPlanBpe } from "../../../../api/audits/auditsApi";
import toast from "react-hot-toast";


export default function ImportAuditToBpe() {
  const fileType = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const queryClient = useQueryClient()
  const reverseBpeMutation = useMutation(reversAuditByPlanBpe,{
    onSuccess: ()=>{
      toast.success("Plan de boite reverse")
      queryClient.invalidateQueries("audits")
    },
    onError: ()=>{
      toast.error("erreur lors du reverse")
    }
  })

  function handleFileUpload(event) {
    if (event.target.files[0]) {
      if (
        event.target.files[0] &&
        fileType.includes(event.target.files[0].type)
      ) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
          const arrayBuffer = e.target.result;
          const workbook = XLSX.read(arrayBuffer, { type: "array" });

          //cassettes sheet
          const sheet1 = workbook.SheetNames[0];
          const worksheet1 = workbook.Sheets[sheet1];

          //cables sheet
          const sheet2 = workbook.SheetNames[1];
          const worksheet2 = workbook.Sheets[sheet2];

          const headerinfo = headerInfoExtract(worksheet1);
          const cablesinfo = cableInfoExtract(worksheet2);
          const cassettesinfo = cassettesInfoExtract(worksheet1, cablesinfo);
          
          const data =  {
            auditTask: {
              name: headerinfo?.boxReference,
              address: headerinfo?.adresse,
              company: headerinfo?.company,
              superAdminId: headerinfo?.superAdminId,
            },
            box: {
              boxReference: headerinfo?.boxReference,
              cablesNumber: cablesinfo?.length,
              cassetteNumber: cassettesinfo.cassetesData.length,
              isBoxBottomEmpty: cassettesinfo.BoxBottomEmpty,
            },
            cables: cablesinfo,
            cassetteFullRequests: cassettesinfo.cassetesData,
            boxBottom: cassettesinfo.fondDeboite,
          };

          console.log(data);
          reverseBpeMutation.mutate(data)
        };

        reader.readAsArrayBuffer(file);
      }
    }
  }

  function headerInfoExtract(worksheet) {
    const sessionInfo = JSON.parse(sessionStorage.getItem("authUser"));
    const data = {
      superAdminId: sessionInfo?.superAdminId,
      company: sessionInfo?.company,
      boxReference: worksheet["G4"]?.v || null,
      adresse: worksheet["G10"]?.v || null,
      typeboite: worksheet["G6"]?.v || null,
    };

    return data;
  }

  function cassettesInfoExtract(worksheet, cables) {
    const rowdatas = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    let cassetteDatas = [];
    let FondDeBoite = {};
    let isBoxBottomEmpty = true;
    let colors = [
      'FF0000','0000FF','18F10E','FFFF00',
      '81007F','FFFFFF','FF7F00','AFAFAF',
      'B05121','000000','17EAE3','FC8BFF'
      ]

    for (let i = 14; i < rowdatas.length; ) {
      console.log(rowdatas[i][0]);
      let lineDatas = [];
      let cassetteType = rowdatas[i][0];

      let j = i + 1;

      while (j < rowdatas.length && rowdatas[j] && rowdatas[j].length != 1) {
        let row = rowdatas[j];
        let linedata = {};
        let k = j + 1;
        let cablein = row[3] !== undefined ? row[3] : null;
        let cableout = row[9] !== undefined ? row[9] : null;
        let statut = row[6] !== undefined ? row[6] : null;
        let tubein = row[4] !== undefined ? row[4] : null;
        let tubeout = row[8] !== undefined ? row[8] : null;
        let fibreInEnd = row[5] !== undefined ? row[5] : null;
        let fibreOutEnd = row[7] !== undefined ? row[7] : null;

//les fonds de boites on une forme differente donc c'est le meme traitement mais la formation de la data est differente
// j'espére ne plus avoir a debogger ce code ca va tellement etre chiant sinon bonne chance

        if (cassetteType.toLowerCase().includes("fond de boite")) {

          while (
            k < rowdatas.length &&
            rowdatas[k] &&
            cablein == rowdatas[k][3] &&
            cableout == rowdatas[k][9] &&
            tubein == rowdatas[k][4] &&
            tubeout == rowdatas[k][8] &&
            statut == rowdatas[k][6]
          ) {
            
            k++;
          }
  
          linedata = {
            ncableOut: row[9] !== undefined ? cableNum(cables,row[9]) : null,
            ncableIn: row[3] !== undefined ? cableNum(cables,row[3]) : null,
  
            tubeInStart: row[4] !== undefined ? row[4] : null,
            tubeInEnd: row[4] !== undefined ? row[4] : null,
  
           
            tubeOutStart: row[8] !== undefined ? row[8] : null,
            tubeOutEnd: row[8] !== undefined ? row[8] : null,
            type:row[6] !== undefined ? row[6] : null,
            
          };
        }
        else{ 

        while (
          k < rowdatas.length &&
          rowdatas[k] &&
          cablein == rowdatas[k][3] &&
          cableout == rowdatas[k][9] &&
          tubein == rowdatas[k][4] &&
          tubeout == rowdatas[k][8] &&
          statut == rowdatas[k][6]
        ) {
          fibreInEnd = rowdatas[k][5];
          fibreOutEnd = rowdatas[k][7];
          k++;
        }

         linedata = {
          ncableOut: row[9] !== undefined ? cableNum(cables,row[9]) : null,
          ncableIn: row[3] !== undefined ? cableNum(cables,row[3]) : null,

          tubeIn: {
            tubeNumber: row[4] !== undefined ? row[4] : null,
            tubeColor: colorsvalue(row[4]),
            ring: 0,
          },

          fiberInStart: row[5] !== undefined ? row[5] : null,
          fiberInEnd: fibreInEnd,
          tubeOut: {
            tubeNumber: row[8] !== undefined ? row[8] : null,
            tubeColor: colorsvalue(row[8]),
            ring: 0,
          },
          type:row[6] !== undefined ? row[6] : null,
          fiberOutStart: row[7] !== undefined ? row[7] : null,
          fiberOutEnd: fibreOutEnd,
        };

      }

        lineDatas.push(linedata);

        j = k;
      }

      if (cassetteType.toLowerCase().includes("fond de boite")) {
        isBoxBottomEmpty = false;
        FondDeBoite = {
          cassetteType: cassetteType,
          bottomLines: lineDatas,
        };
      } else {
        cassetteDatas.push({
          cassetteType: cassetteType,
          lines: lineDatas,
        });
      }

      i = j;
    }

    return {
      cassetesData: cassetteDatas,
      fondDeboite: FondDeBoite,
      BoxBottomEmpty: isBoxBottomEmpty,
    };
  }

  function cableInfoExtract(worksheet) {
    const cableRow = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    let cablesData = [];
    let num = 1;
    for (let i = 26; i < cableRow.length; i++) {
      let row = cableRow[i];

      cablesData.push({
        cableName: row[0] !== undefined ? row[0] : null,
        cableCapacity: row[1] !== undefined ? row[1] : null,
        cableModulo: row[2] !== undefined ? row[2] : null,
        nbrTube: row[3] !== undefined ? row[3] : null,
        manufacturerReference: row[4] !== undefined ? row[4] : null,
        manufacturingYear: row[5] !== undefined ? row[5] : null,
        installationYear: row[6] !== undefined ? row[6] : null,
        fciNumber: row[7] !== undefined ? row[7] : null,
        n: num,
      });
      num++;
    }

    return cablesData;
  }

  function cableNum(cables,cable_name){

   const res = cables.filter((cable)=>cable.cableName == cable_name)
  if(res.length>0){
    return res[0].n;
  }
  else{
    return null;
  }
   
  }

  function colorsvalue(tube){
    
    let colors = [
      'FF0000','0000FF','18F10E','FFFF00',
      '81007F','FFFFFF','FF7F00','AFAFAF',
      'B05121','000000','17EAE3','FC8BFF'
      ]

     let index = tube;

     if(index>12){
      index = tube % 12;
     }
     
     return colors[index-1]
  }

  return (
    <FileUploader
      handleFileUpload={handleFileUpload}
      text={"import avec plan boite"}
      color="#C9C9C9"
    />
  );
}
