import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import axios from "axios";
const api = new APIClient();

export const getBoxTypeImgs = async () => {
    const response = await api.get(url.GET_BOX_TYPE_IMAGES, {"company": JSON.parse(sessionStorage.getItem("authUser"))?.company});
    return response;
}

export const addBoxImgType = async (data) => {
    // for (var value of data.values()) {
    //     console.log(value);
    // }
    const response = await axios.post(url.ADD_BOX_TYPE_IMAGE, data, {
        headers: {
        "Content-Type": "multipart/form-data",
        },
    });
    
    return response;
}

export const deleteBoxImgType = async (name) => {
    const response = await api.delete(url.DELETE_BOX_TYPE_IMAGES(name));
    return response;
}

export const updateAllBoxImages = async (data) => {
    const response = await axios.put(url.UPDATE_ALL_BOX_IMAGES(data.imageName), {"image": data.file}, {
        headers: {
        "Content-Type": "multipart/form-data",
        }
    });
    return response;
}

export const onLoad = async (img) => {
    fetch(`${process.env.REACT_APP_API_ORIGINAL_IMG_URL}/${img}`)
        .then((data) => data.blob())
}
