import React, { useState } from "react";
import { Button, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createCommand } from "../../../api/commands/commandsApi";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import QuotaCard from "./quotaCard";
import { Badge } from 'react-bootstrap';

const AddQuotaForm = ({ modal, setModal, toggle, quotaRestant }) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [showSummary, setShowSummary] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [emailContent, setEmailContent] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(null); // Nouvel état pour le choix de paiement
  const currentUser = JSON.parse(sessionStorage.getItem('authUser')) || null;
  const orderDateTime = new Date().toLocaleString('fr-FR');

  const queryClient = useQueryClient();

  const validationSchema = Yup.object().shape({
    numberOfTask: Yup.string().required("Le choix du quota est obligatoire"),
  });

  const formik = useFormik({
    initialValues: {
      numberOfTask: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await createCommand({
          numberOfTask: values.numberOfTask,
          company: currentUser?.company || '',
          userId: currentUser?.userId || ''
        });
        await queryClient.invalidateQueries("commands");
        setShowSummary(false);
        formik.resetForm();
        setTotalAmount(0);
        toggle();
        toast.success("La commande a été ajoutée avec succès !");
      } catch (error) {
        console.error("Erreur lors de la création de la commande :", error);
      }
    },
  });

  const calculateTotalAmount = (numberOfTask) => {
    let amount = 0;
    switch (numberOfTask) {
      case "1":
        amount = 280;
        break;
      case "50":
        amount = 10000;
        break;
      case "100":
        amount = 15000;
        break;
      case "500":
        amount = 37500;
        break;
      default:
        amount = 0;
    }
    return amount;
  };

  const handlenumberOfTaskChange = (numberOfTask, index) => {
    formik.setFieldValue("numberOfTask", numberOfTask);
    const total = calculateTotalAmount(numberOfTask);
    setTotalAmount(total);
    setActiveIndex(index); // Met à jour l'index de la carte active
  };

  const handleSendEmail = async (emailText) => {
    try {
      // Ici, vous devriez appeler votre API pour envoyer l'e-mail avec le contenu de emailText
      console.log("Envoi de l'e-mail avec le texte :", emailText);
      // Simulons un succès pour l'exemple
      toast.success("E-mail envoyé avec succès! Notre équipe vous reviendra le plus vite possible");
      // Vous pouvez ajouter ici la logique d'envoi de l'e-mail réel
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'e-mail :", error);
      toast.error("Erreur lors de l'envoi de l'e-mail");
    }
  };

  const handlePaymentSelection = (method) => {
    setPaymentMethod(method);
    if (method === 'espèces') {
      formik.setFieldTouched('paymentMethod', true); // Marquer le champ comme touché pour la validation
    } else {
      // Afficher une alerte ou un message que la méthode n'est pas encore intégrée
      toast.success("Méthode de paiement par carte bancaire n'est pas encore intégrée. Vous pouvez payer par espèce pour le moment");
    }
  };

  return (
    <Modal
      id="showModalClient"
      isOpen={modal}
      toggle={toggle}
      centered
      backdrop={"static"}
      style={{ maxWidth: '1000px' }}
    >
      <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
        Commande de forfait (Forfait: {quotaRestant || "non trouvé"})
      </ModalHeader>
      <Form onSubmit={formik.handleSubmit}>
        <ModalBody>
          {!showSummary && (
            <Row className="g-3">
              <Col lg={12}>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <Label htmlFor="numberOfTask-field" className="form-label">
                    Choix du forfait : <span className="text-danger mx-1">*</span>
                  </Label>
                  <span className="text-muted">Partie 1/2</span>
                </div>
              </Col>
              <Col lg={12}>
                <Row className="g-3">
                  <div className="col-md-4">
                    <QuotaCard
                      title={<>Forfait 1 <Badge bg="primary">Nouveau</Badge></>}
                      description="Utilisez Audexa pour vos projets personnels. Vous pouvez meme commander Un seul quota. "
                      price={280}
                      onClick={() => handlenumberOfTaskChange("1", 0)}
                      isActive={activeIndex === 0}
                    />
                  </div>
                  <div className="col-md-4">
                    <QuotaCard
                      title={<>Forfait 50 <Badge bg="success">Promo</Badge></>}
                      description="Faites une économie de 20% en optant pour ce forfait pour vos projets de taille moyenne."
                      price={10000}
                      onClick={() => handlenumberOfTaskChange("50", 1)}
                      isActive={activeIndex === 1}
                    />
                  </div>
                  <div className="col-md-4">
                    <QuotaCard
                      title={<>Forfait 100 <Badge bg="info">Réduction</Badge></>}
                      description=" Allez plus loin avec une économie de 35%. Vous pouvez commander ce forfait."
                      price={15000}
                      onClick={() => handlenumberOfTaskChange("100", 2)}
                      isActive={activeIndex === 2}
                    />
                  </div>
                  <div className="col-md-4">
                    <QuotaCard
                      title={<>Forfait 500 <Badge bg="warning">Spécial</Badge></>}
                      description="Economisez 50% et réalisez vos audits  de bout en bout. "
                      price={37500}
                      onClick={() => handlenumberOfTaskChange("500", 3)}
                      isActive={activeIndex === 3}
                    />
                  </div>
                  <div className="col-md-4">
                    <QuotaCard
                      title="Plus de forfait"
                      description={(
                        <div>
                          Vous voulez plus ? <br />
                          Parlez à l’un de nos commerciaux qui vous communiquera le prix le plus vite possible. <br />
                          N'attendez plus!!!
                        </div>
                      )}
                      price="A définir"
                      onClick={() => handlenumberOfTaskChange("plus", 4)} // Utilisez "plus" comme identifiant pour la logique spéciale
                      isActive={activeIndex === 4}
                      onSendEmail={handleSendEmail}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
          )}
          {showSummary && (
            <Row className="g-4">
              <Col lg={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Choix de paiement</h5>
                  <span className="text-muted">Partie 2/2</span>
                </div>
                <Row className="g-3">
                  <div className="col-md-6">
                    <div className={`card ${paymentMethod === 'espèces' ? 'border-primary' : ''}`}>
                      <div className="card-body">
                        <h5 className="card-title">Paiement en espèces</h5>
                        <p className="card-text">Payer en espèces.</p>
                        <Button
                          color="primary"
                          onClick={() => handlePaymentSelection('espèces')}
                          disabled={paymentMethod === 'espèces'}
                        >
                          Sélectionner
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={`card ${paymentMethod === 'carte bancaire' ? 'border-primary' : ''}`}>
                      <div className="card-body">
                        <h5 className="card-title">Paiement par carte bancaire</h5>
                        <p className="card-text">Cette méthode de paiement n'est pas encore intégrée.</p>
                        <Button
                          color="primary"
                          onClick={() => handlePaymentSelection('carte bancaire')}
                          disabled={false} // Toujours désactivé car non intégré
                        >
                          Sélectionner
                        </Button>
                      </div>
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            {!showSummary && (
              <Button color="light" onClick={() => setModal(false)}>
                Annuler
              </Button>
            )}
            {!showSummary && (
              <Button
                type="button"
                color="success"
                id="add-btn"
                onClick={() => {
                  if (formik.values.numberOfTask || activeIndex === 4) {
                    setShowSummary(true);
                  }
                }}
                disabled={!formik.values.numberOfTask && activeIndex !== 4}
              >
                Suivant
              </Button>
            )}
            {showSummary && (
              <Button type="button" color="success" onClick={() => setShowSummary(false)}>
                Modifier
              </Button>
            )}
            {showSummary && paymentMethod === 'espèces' && (
              <Button type="submit" color="success" id="confirm-order-btn">
                Valider
              </Button>
            )}
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddQuotaForm;
