import React from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { Card, CardBody, CardHeader } from "reactstrap";

const mapStyles = {
    width: "100%",
    height: "100%",
};

const LoadingContainer = () => <div>Loading...</div>;

const MapSection = (props) => {
    return (
        <Card>
            <CardHeader>
                <h4 className="card-title mb-0">Localisation</h4>
            </CardHeader>
            <CardBody>
                <div id="leaflet-map-popup" className="leaflet-map">
                    {props.location.latitude ? (
                        <div
                            id="gmaps-markers"
                            className="gmaps"
                            style={{ position: "relative" }}
                        >
                            <Map
                                google={props.google}
                                zoom={15}
                                style={mapStyles}
                                initialCenter={{
                                    lat: props.location.latitude,
                                    lng: props.location.longitude,
                                }}
                            >
                                <Marker
                                    position={{
                                        lat: props.location.latitude,
                                        lng: props.location.longitude,
                                    }}
                                />
                            </Map>
                        </div>
                    ) : (
                        <div className="py-4 text-center">
                            <div>
                                <lord-icon
                                    src="https://cdn.lordicon.com/surcxhka.json"
                                    trigger="loop"
                                    colors="primary:#405189,secondary:#0ab39c"
                                    style={{ width: "72px", height: "72px" }}
                                ></lord-icon>
                            </div>

                            <div className="mt-4">
                                <h5>Désolé! Localisation non trouvée</h5>
                            </div>
                        </div>
                    )}
                </div>
            </CardBody>
        </Card>
    );
};

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    LoadingContainer: LoadingContainer,
    v: "3",
})(MapSection);