import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import { Navigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import {useQuery} from "react-query";
import {logout} from "../../api/auth/authApi";

const Logout = (props) => {
  const [isUserLogout, setIsUserLogout] = useState(false);
  const logoutQuery = useQuery({
    queryKey: ["logout"],
    queryFn: logout
  });

  useEffect(() => {
    if (logoutQuery.status === 'success') {
      setIsUserLogout(logoutQuery.data);
    }
  }, [logoutQuery.data])

  if (isUserLogout) {
    return <Navigate to="/login" />;
  }

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);