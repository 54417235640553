import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
// Formik validation
import * as Yup from "yup";
import {useFormik} from "formik";
import {useMutation} from "react-query";
import {authUser} from "../../api/auth/authApi";
import '../../assets/login_page_assets/css/login.css';
import logoaudexablanc from "../../assets/login_page_assets/images/audexa.blanc.png";
import presentation_image from "../../assets/login_page_assets/images/presentation-image.png";
import arrow_keyboard from "../../assets/login_page_assets/images/arrow-up-1--arrow-up-keyboard.png";
import audexaimagebluelogo from "../../assets/login_page_assets/images/X.logoblue.png";
import { Button, Form, FormFeedback, Input, Label, Spinner} from "reactstrap";
import {Alert} from "antd";
import toast from "react-hot-toast";

const Login = (props) => {
    document.title = `Connexion | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;
    const [error, setError] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);

    //Login User Mutation
    const {isLoading, mutate} = useMutation(authUser, {
        onSuccess: (data) => {
            if (data?.isAuthorized) {
                props.router.navigate("/dashboard");
            } else {
                setError("Connexion non autorisée!");
                sessionStorage.clear();
            }
        },
        onError: (error) => {
            setError(error)
        }
    });

    //Formulaire Validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Identifiant incorrect!"),
            password: Yup.string().required("Mot de passe incorrect!"),
        }),
        onSubmit: (values) => {
            const {
                username,
                password
            } = validation.values;
            const formData = {
                "username": username,
                "password": password,
                "grant_type": process.env.REACT_APP_GRANT_TYPE,
                "client_id": process.env.REACT_APP_CLIENT_ID
            };
            //Login Mutation
            mutate(formData);
        },
    });

    return (
        <div>
            <div className="mybox">
                <div className="left-box">
                    <div className="left-children">
                        <img src={logoaudexablanc} alt="Audexa" width="104.5px" height="25px" className="login-logo"/>
                        <span className="audit-text">
                            Des audit efficaces
                        </span>
                        <p className="presentation-text">
                            Audexa propose un logiciel de pointe pour les audits, couvrant la collecte de données sur le
                            terrain jusqu'à la gestion en back-office. Optimisez vos opérations avec précision et
                            efficacité, tout en réduisant significativement vos efforts.
                        </p>
                        <img src={presentation_image} alt="" className="screenshoot-img"/>
                        <div className="foot-box">
                            <span className="foot-text">En savoir plus sur Audexa</span>
                            <Link to="http://www.audexa.io" target="_blank">
                                <img src={arrow_keyboard} alt="arrow"/>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="rigth-box">
                    <div className="rigth-children">
                        <div className="rigth-logo-box">
                            <img src={audexaimagebluelogo} alt="" height="80px" width="80px"/>
                        </div>
                        {error && error ? (
                            <Alert message={`${error}`} type="error" showIcon />
                        ) : null}
                        <div className="p-2 mt-4">
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                                action="#"
                            >
                                <div className="mb-4">
                                    <Label htmlFor="username" className="form-label">
                                        Nom d'utilisateur
                                    </Label>
                                    <Input
                                        autoComplete="on"
                                        name="username"
                                        id="username"
                                        className="form-control login-fc"
                                        placeholder="Entrer le nom d'utilisateur"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.username || ""}
                                        invalid={
                                            !!(validation.touched.username &&
                                                validation.errors.username)
                                        }
                                    />
                                    {validation.touched.username &&
                                    validation.errors.username ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.username}
                                        </FormFeedback>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <div className="float-end">
                                        <Link to="/forgot-password" className="text-muted">
                                            Mot de Passe oublié?
                                        </Link>
                                    </div>
                                    <Label
                                        className="form-label"
                                        htmlFor="password-input"
                                    >
                                        Mot de Passe
                                    </Label>
                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                        <Input
                                            autoComplete="on"
                                            name="password"
                                            id="password-input"
                                            value={validation.values.password || ""}
                                            type={passwordShow ? "text" : "password"}
                                            className="form-control pe-5 login-fc"
                                            placeholder="Entrer le mot de passe"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                !!(validation.touched.password &&
                                                    validation.errors.password)
                                            }
                                        />
                                        {validation.touched.password &&
                                        validation.errors.password ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.password}
                                            </FormFeedback>
                                        ) : null}
                                        <button
                                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                            type="button"
                                            id="password-addon"
                                            onClick={() => setPasswordShow(!passwordShow)}
                                        >
                                            <i className="ri-eye-fill align-middle fs-18"></i>
                                        </button>
                                    </div>
                                </div>

                                <div className="form-check mt-2">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="auth-remember-check"
                                    />
                                    <Label
                                        className="form-check-label"
                                        htmlFor="auth-remember-check"
                                    >
                                        Se souvenir de moi
                                    </Label>
                                </div>

                                <div className="d-flex justify-content-center mt-4">
                                    <Button
                                        disabled={!(validation.isValid && validation.dirty)}
                                        className="btn w-75 log-btn"
                                        type="submit"
                                    >
                                        {isLoading ? (
                                            <Spinner size="sm" className="me-2">
                                                {" "}
                                                Loading...{" "}
                                            </Spinner>
                                        ) : null}
                                        Connexion
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    );
};

export default withRouter(Login);
