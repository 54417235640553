import React from "react";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
  Link,
} from "@react-pdf/renderer";
import logo from "../../../../assets/login_page_assets/images/audexa.logo.png";
import logo_spie from "../../../../assets/login_page_assets/images/LogoSPIE.png";
import logo_twelve from "../../../../assets/images/twelve_groupbasiccom-1.png";

const styles = StyleSheet.create({
    header:{
      flexDirection: "row",
      justifyContent: "space-between",
    }
  });
const environnement =process.env.REACT_APP_EXCEL_ENTREPRISE_NAME;

export default function Pageheader() {
  return (
    <View style={styles.header}>
      <Image
        src={logo}
        style={{ height: 30, width: 120, marginBottom: 25, marginTop: 15 }}
      />
      
        { environnement == "Twelve" ?(<Image src={logo_twelve} style={{ height: 35, width: 120, marginTop: 10 }} />):
        (<Image src={logo_spie} style={{ height: 45, width: 120}} />)}
      
    </View>
  );
}
