import { useState } from "react";
import "./InlineInputEdit.css"

const InlineInputEdit = ({ name,value, setValue, onfocus, setIsFocused }) => {
    const [editingValue, setEditingValue] = useState(value);

    const onChange = (event) => setEditingValue(event.target.value);

    const onKeyDown = (event) => {
        if (event.key === "Enter" || event.key === "Escape") {
            event.target.blur();
        }
    }

    const onBlur = (event) => {
        if (event.target.value.trim() === "") {
            setValue(value);
        } else {
            setValue(event.target.value)
        }
        setIsFocused(false)
    }

    return (
        <input
            className="inline-input"
            name={name}
            onFocus={onfocus}
            type="text"
            aria-label="Field name"
            value={editingValue}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
        />
    )
}

export default InlineInputEdit;