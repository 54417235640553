import React, {useEffect, useState} from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./MyCaroussel.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import audexaLogo from "../../assets/login_page_assets/images/xnoir.png";
import noImage from "../../assets/images/no-image.jpg";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import EasyCropImage from "./CropImage/EasyCropImage";
import {Grow} from "transitions-kit";
import {AsyncImage} from "loadable-image";
import {
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    ExpandOutlined,
    SaveOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {Avatar, Button, Card} from 'antd';
import PreviewImageCard from "./PreviewImageCard/PreviewImageCard";
const { Meta } = Card;


const MyCarousel = ({ content,status }) => {
  const [boxImgCropModal, setBoxImgCropModal] = useState(false);
  const [boxImgs, setBoxImgs] = useState([]);
  const [cropImage, setCropImage] = useState(null);
  const [index, setIndex] = useState(0);
  const handleOpenCrop = (item, index) => {
    setCropImage(item);
    setIndex(index)
    setBoxImgCropModal(!boxImgCropModal);
  };

    // const [previewImgMdl, setPreviewImgMdl] = useState(false);
    // const [index, setIndex] = useState(0);
    // const togglePrevModal = (item, index) => {
    //     // setCropImage(item);
    //     setIndex(index)
    //     setPreviewImgMdl(!previewImgMdl);
    // };


    const [isCrop, setIsCrop] = useState(false)
    const toggleCrop = () => {
        setIsCrop(!isCrop)
    }

  return (
    <React.Fragment>
      <Carousel showThumbs={false}>
        {content && content.map((item, index) => {
          return (
            <div
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() => handleOpenCrop(item, index)}
            >
                <AsyncImage
                    key={index}
                    src={`${process.env.REACT_APP_API_IMG_URL}/${item?.img}`}
                    style={{ width: 400, height: 377, borderRadius: 3 }}
                    loader={<div style={{ background: '#888' }} />}
                    Transition={Grow}
                />
              <p className="legend">{item.name}</p>
            </div>
          );
        })}
      </Carousel>
        {/*<PreviewImageCard*/}
        {/*    content={content}*/}
        {/*    previewImgMdl={previewImgMdl}*/}
        {/*    togglePrevModal={togglePrevModal}*/}
        {/*    index={index}*/}
        {/*/>*/}
      <Modal
        id="boxImgCropModal"
        className="modal-xl"
        isOpen={boxImgCropModal}
        toggle={() => {
          handleOpenCrop();
        }}
        backdrop={"static"}
      >
        <ModalHeader
          className="modal-title"
          id="boxImgCropModal"
          toggle={() => {
            handleOpenCrop();
          }}
        >
            {/*<span className="me-4">{content[index]?.name}</span>*/}
            {/*<Button onClick={toggleCrop} icon={<ExpandOutlined />} size="small" />*/}
        </ModalHeader>
        <ModalBody>
            <Carousel showThumbs={false} showIndicators={false} selectedItem={index}>
                {content && content.filter((i) => i.img != null).map((item, index) => {
                return (
                    <>
                    <Card
                        title={`${item?.name}`}
                    >
                        <Card.Grid style={{width: '70%', textAlign: 'center'}}>
                            <EasyCropImage
                                status={status}
                                key={index}
                                item={item}
                            />
                        </Card.Grid>
                    </Card>
                    </>
                )})}
            </Carousel>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default MyCarousel;
