import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import Dragula from "react-dragula";
import React, { useCallback, useEffect, useState } from "react";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { deleteAudit } from "../../../../api/audits/auditsApi";
import * as moment from "moment";
import { Button, Tag } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DeleteFilled,
  DiffOutlined,
  FileDoneOutlined,
  SyncOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

const handleValidDate = (date) => {
  const date1 = moment(new Date(date)).format("DD MMM Y");
  return date1;
};

const TaskTab = ({ tasks }) => {
  const queryClient = useQueryClient();
  const deleteProjectTaskMutation = useMutation(deleteAudit, {
    onSuccess: () => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("projects");
      toast.success("La tache a été supprimée avec succès");
    },
    onError: (error) => {
      // An error happened!
      toast.error(error);
    },
  });

  const navigate = useNavigate();
  const [task, setTask] = useState({});
  // Delete Task
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [tasksList, setTasksList] = useState([]);

  useEffect(() => {
    if (tasks) {
      setTasksList(tasks);
    }
  }, [tasks]);

  const onClickTodoDelete = (task) => {
    setTask(task);
    setDeleteModal(true);
  };

  // Delete Data
  const handleDeleteTask = () => {
    if (task) {
      deleteProjectTaskMutation.mutate(task.id);
      setDeleteModal(false);
    }
  };

  const handleTodoClick = (item) => {
    navigate(`/demande-overview/${item.id}`);
  };

  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      let options = {};
      Dragula([componentBackingInstance], options);
    }
  };

  // Checked All
  const checkedAllTask = useCallback(() => {
    const checkall = document.getElementById("checkBoxAllTask");
    const ele = document.querySelectorAll(".taskCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".taskCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  //Delete multiples tasks
  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAllTask");
    selectedCheckBoxDelete.forEach((element) => {
      deleteProjectTaskMutation.mutate(element.value);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const [statut, setStatut] = useState(null);

  const handleFilterTask = (status) => {
    setStatut(status);
    let filteredTasks = tasks;
    if (status !== "all") {
      filteredTasks = tasks.filter((task) => task.status === status);
    }
    setTasksList(filteredTasks);
  };

  const exportmultiExcel = ()=>{
    const data = []

    selectedCheckBoxDelete.forEach((task)=>{
      data.push(task.value);
    })
    
    navigate("/excel-export",{state: {data:data}})
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTask}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Row>
        <Col>
          <Card>
            <CardHeader className="align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">
                {statut === "In_Progress" ? (
                  <Tag icon={<SyncOutlined spin />} color="processing">
                    En cours
                  </Tag>
                ) : statut === "Affected" ? (
                  <Tag icon={<ClockCircleOutlined />} color="default">
                    En attente
                  </Tag>
                ) : statut === "Created" ? (
                  <Tag icon={<ClockCircleOutlined />} color="default">
                    En attente
                  </Tag>
                ) : statut === "Completed" ? (
                  <Tag icon={<CheckCircleOutlined />} color="success">
                    Complète
                  </Tag>
                ) : statut === "Validated" ? (
                  <Tag icon={<FileDoneOutlined />} color="warning">
                    Validée
                  </Tag>
                ) : (
                  <Tag icon={<UnorderedListOutlined />} color="default">
                    Toutes les tâches
                  </Tag>
                )}
              </h4>
              {isMultiDeleteButton && (
                <div className="d-flex">
                   <div className="flex-shrink-0 me-2">
                  <div className="d-flex justify-content-end hstack text-nowrap gap-2">
                    <Button
                      onClick={() => setDeleteModalMulti(true)}
                      type="primary"
                      danger
                      size="large"
                      shape="circle"
                      icon={<DeleteFilled />}
                    />
                  </div>
                </div>

                  <Button onClick={() => exportmultiExcel()} type="primary"
                      success shape="circle"  icon={<DiffOutlined/>}
                      size="large"/>
                </div>
               



              )}
              <div className="flex-shrink-0">
                <UncontrolledDropdown className="card-header-dropdown">
                  <DropdownToggle
                    tag="a"
                    className="text-reset dropdown-btn"
                    role="button"
                  >
                    <span className="text-muted">
                      Filtrer par statut
                      <i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem onClick={() => handleFilterTask("all")}>
                      Toutes les tâches
                    </DropdownItem>
                    <DropdownItem onClick={() => handleFilterTask("Affected")}>
                      Tâches en attentes
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => handleFilterTask("In_Progress")}
                    >
                      Tâches en cours
                    </DropdownItem>
                    <DropdownItem onClick={() => handleFilterTask("Validated")}>
                      Tâches validées
                    </DropdownItem>
                    <DropdownItem onClick={() => handleFilterTask("Completed")}>
                      Tâches complètes
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </CardHeader>
            <CardBody>
              <div
                className="todo-content position-relative px-4 mx-n4"
                id="todo-content"
              >
                <div className="todo-task" id="todo-task">
                  <div className="table-responsive">
                    <table className="table align-middle position-relative table-nowrap">
                      <thead className="table-active">
                        <tr>
                          <th scope="col">
                            <span className="mx-1">
                              <input
                                type="checkbox"
                                id="checkBoxAllTask"
                                className="form-check-input"
                                onClick={() => checkedAllTask()}
                              />
                            </span>
                            Tâches
                          </th>
                          <th scope="col">Intervention</th>
                          <th scope="col">Date Limite</th>
                          <th scope="col">Adresse</th>
                          <th scope="col">Statut</th>
                          <th scope="col">Priorité</th>
                          {/* <th scope="col">Assignée à</th> */}
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>

                      <tbody id="task-list" ref={dragulaDecorator}>
                        {(tasksList || []).map((item, key) => (
                          <tr key={key}>
                            <td>
                              <input
                                className="form-control filelist-id"
                                type="hidden"
                                value="1"
                                id="tasklist-1"
                              />
                              <div className="d-flex align-items-center">
                                <div className="mx-1">
                                  <input
                                    type="checkbox"
                                    className="taskCheckBox form-check-input"
                                    value={item.id}
                                    onChange={() => deleteCheckbox()}
                                  />
                                </div>
                                <div className="flex-grow-1 filelist-name">
                                  {item?.name}
                                </div>
                              </div>
                            </td>
                            <td>{handleValidDate(item.endDate)}</td>
                            <td>{handleValidDate(item.dueDate)}</td>
                            <td>{item.address}</td>
                            <td>
                              {item.status === "In_Progress" ? (
                                <Tag
                                  icon={<SyncOutlined spin />}
                                  color="processing"
                                >
                                  En cours
                                </Tag>
                              ) : item.status === "Affected" ? (
                                <Tag
                                  icon={<ClockCircleOutlined />}
                                  color="default"
                                >
                                  En attente
                                </Tag>
                              ) : item.status === "Created" ? (
                                <Tag
                                  icon={<ClockCircleOutlined />}
                                  color="default"
                                >
                                  En attente
                                </Tag>
                              ) : item.status === "Completed" ? (
                                <Tag
                                  icon={<CheckCircleOutlined />}
                                  color="success"
                                >
                                  Complète
                                </Tag>
                              ) : item.status === "Validated" ? (
                                <Tag
                                  icon={<FileDoneOutlined />}
                                  color="warning"
                                >
                                  Validée
                                </Tag>
                              ) : null}
                            </td>
                            <td>
                              {/* <Priority priority={item.priority} /> */}
                              {item.priority === "Medium" ? (
                                <span className="badge bg-primary text-uppercase">
                                  Important
                                </span>
                              ) : item.priority === "High" ? (
                                <span className="badge bg-warning text-uppercase">
                                  Urgent
                                </span>
                              ) : item.priority === "Highest" ? (
                                <span className="badge bg-danger text-uppercase">
                                  Très urgent
                                </span>
                              ) : null}
                            </td>
                            <td>
                              <div className="hstack gap-2">
                                <button
                                  className="btn btn-sm btn-soft-danger remove-list"
                                  onClick={() => onClickTodoDelete(item)}
                                >
                                  <i className="ri-delete-bin-5-fill align-bottom" />
                                </button>
                                <button
                                  className="btn btn-sm btn-soft-info edit-list"
                                  onClick={() => handleTodoClick(item)}
                                >
                                  <i className="ri-eye-fill align-bottom" />
                                </button>
                                <button
                                  className="btn btn-sm btn-soft-warning edit-list"
                                  onClick={(event) => {event.preventDefault(); 
                                    window.open(`/demande-overview/${item.id}`);}} 
                                    target="_blank"
                                >
                                  <i className="ri-share-box-fill align-bottom"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="py-4 mt-4 text-center"
                  id="noresult"
                  style={{ display: "none" }}
                >
                  <lord-icon
                    src="https://cdn.lordicon.com/msoeawqm.json"
                    trigger="loop"
                    colors="primary:#405189,secondary:#0ab39c"
                    style={{ width: "72px", height: "72px" }}
                  ></lord-icon>
                  <h5 className="mt-4">Sorry! No Result Found</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TaskTab;
