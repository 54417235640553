import { useState } from "react";

export default function PreviewCompanyImg({file}) {
    const [preview, setPreview] = useState();

    if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setPreview(reader.result);
        }
    }

    return (
        <img
            src={preview}
            alt=""
            className="rounded-circle avatar-xl img-thumbnail user-profile-image"
            id="customer-img"
        />
    )
}