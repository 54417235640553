import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { useNavigate } from "react-router-dom";
import { StatusFormat, StatusColor } from "../../../helpers/status_format";

const mapStyles = {
  width: "100%",
  height: "100%",
};

// const LoadingContainer = () => <div>Loading...</div>;

const GoogleMapsBalises = (props) => {
  const navigate = useNavigate();

  const handleOpenTask = (item) => {
    navigate(`/demande-overview/${item?.taskId}`, {
      state: {
        id: item?.place?.id,
      },
    });
  };

  const getMakerColorMap = (status)=>{
    switch(status){
        case 'Completed':
            return 'green';
        case 'In_Progress':
            return 'blue';
        case 'Affected':
            return 'grey';
        case 'Validated':
            return 'yellow';
        case 'Created':
            return 'grey';
        default:
            return 'dark';
    }
  };

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0">Localisation BPE</h4>
          </CardHeader>
          <CardBody className="border border-dark">
            <div
              id="gmaps-markers"
              className="gmaps"
              style={{ position: "relative" }}
            >
              {props?.balises?.length>0 && (
                  <Map
                      google={props.google}
                      zoom={12}
                      style={mapStyles}
                      initialCenter={{
                        lat: props.balises[0]?.place?.location?.latitude,
                        lng: props.balises[0]?.place?.location?.longitude,
                      }}
                  >
                    {props.balises?.map((item, index) => {
                      return (
                          <Marker
                              key={index}
                              position={{
                                lat: item?.place?.location?.latitude,
                                lng: item?.place?.location?.longitude,
                              }}
                              icon={{
                                  url: `http://maps.google.com/mapfiles/ms/icons/${getMakerColorMap(item.status)}-dot.png`,
                              }}
                              onClick={() => handleOpenTask(item)}
                              title={`● Tâche: ${item.tache} \n● Ref Boîte: ${item?.ref} \n● Statut: ${StatusFormat(item?.status)} \n● Technicien: ${item?.technician?.firstName} ${item?.technician?.lastName} \n● Adresse: ${item?.place?.address}`}
                              name={"SOMA"}
                              
                          ></Marker>
                      );
                    })}
                  </Map>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  // LoadingContainer: LoadingContainer,
  v: "3",
})(GoogleMapsBalises);
