import React, {useContext, useState} from "react";
import TaskContext from "../../../hook/TaskContext";
import SimpleBar from "simplebar-react";
import {Col, Row, Table} from "reactstrap";
import ShowCableModal from "./showCableModal/ShowCableModal";
import LineTable from "./LineTable";
import PreviewImageCard from "../../../../../Components/Common/PreviewImageCard/PreviewImageCard";


const CableTable = () => {
    const {cables, status} = useContext(TaskContext);
    const [showCableMdl, setShowCableMdl] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    function toggleModal(index) {
        setActiveIndex(index);
        setShowCableMdl(!showCableMdl);
    }

    return (
        <>
            <SimpleBar style={{ maxHeight: "300px" }} className="px-3">
                <Row>
                    <Col lg={12}>
                        <div className="table-responsive mt-4 mt-xl-0">
                            <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                                <thead>
                                <tr>
                                    <th scope="col">Référence Câble</th>
                                    <th scope="col">Capacité</th>
                                    <th scope="col">Modulo</th>
                                    <th scope="col">Numéro FCI</th>
                                    <th scope="col">Fabriquant</th>
                                    <th scope="col">Année de Fabrication</th>
                                    <th scope="col">Année d'Installation</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {(cables || []).map((item, index) => {
                                    return <LineTable item={item} status={status} index={index} toggleModal={toggleModal}/>;
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </SimpleBar>
            <ShowCableModal
                toggleModal={toggleModal}
                showCableMdl={showCableMdl}
                cables={cables}
                activeIndex={activeIndex}
                status={status}
            />
        </>
    )
}

export default CableTable;