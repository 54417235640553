import React, { useEffect, useState } from 'react';
import Flatpickr from "react-flatpickr";
import { French } from 'flatpickr/dist/esm/l10n/fr.js';
import { Link } from 'react-router-dom';
import { handleValidTime } from '../../../helpers/datetime_format';
import * as moment from "moment";

const TasksSchedules = ({tasks}) => {
    const [filterTasks, setFilterTasks] = useState(tasks.filter((item) => moment(item.dueDate).format('yyyy-MM-DD') === moment(new Date()).format('yyyy-MM-DD')));
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (selectedDate) {
            setFilterTasks(tasks.filter((item) => moment(item.dueDate).format('yyyy-MM-DD') === moment(selectedDate[0]).format('yyyy-MM-DD')))
        }
    },[selectedDate])

    return (
        <React.Fragment>
            <div className="col-xxl-3">
                <div className="card card-height-100 border border-dark">
                    <div className="card-header border-0">
                        <h4 className="card-title mb-0">Tâches Planifiées</h4>
                    </div>
                    <div className="card-body pt-0">
                        <div className="upcoming-scheduled">
                            <Flatpickr
                                className="form-control"
                                options={{
                                    dateFormat: "d M, Y",
                                    inline: true,
                                    locale: {
                                        ...French
                                    }
                                }}
                                onChange={(date) => setSelectedDate(date)}
                            />
                        </div>

                        <h6 className="text-uppercase fw-semibold mt-4 mb-3 text-muted">Tâches:</h6>
                        {filterTasks.slice(0, 3).map((item, index) => {
                            return [
                                <Link to={`/demande-overview/${item.id}`} key={index}>
                                    <div className="mini-stats-wid d-flex align-items-center mt-3">
                                            <div className="flex-shrink-0 avatar-sm">
                                                <span style={{backgroundColor:"#BCED09"}} className="mini-stat-icon avatar-title rounded-circle text-dark  fs-6">
                                                    {/* {new Date(item?.endDate).getDay()} */}
                                                    {item?.assignedTo[0]?.firstName?.charAt(0).toUpperCase()}
                                                    {item?.assignedTo[0]?.lastName?.charAt(0).toUpperCase()}
                                                </span>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h6 className="mb-1">{item?.name}</h6>
                                                <p className="text-muted mb-0">{item?.address?.toLowerCase()} </p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <p className="text-muted mb-0">{handleValidTime(item?.dueDate)}</p>
                                            </div>
                                    </div>
                                </Link>
                            ]
                        })}
                        <div className="mt-3 text-center">
                            <Link to="/audits" className="text-muted text-decoration-underline">Voir Toutes les Tâches</Link>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TasksSchedules;