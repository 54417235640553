import React, {useState} from "react";
import {Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";

import Slack from "../../../assets/login_page_assets/images/xnoir.png";
import {Dropdown, Space, Button, Tag, Tooltip} from "antd";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    FileDoneOutlined,
    FileExcelOutlined,
    FilePdfOutlined,
    SyncOutlined,
    DownOutlined, MoreOutlined
} from "@ant-design/icons";
import * as moment from "moment/moment";
import 'moment/locale/fr';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    deleteAudit,
    generateAuditTask,
    getExportPdfReport,
    joinFiletoDemande,
    moveTaskToRootProject,
    removeAssignation,
    updateAuditStatus
} from "../../../api/audits/auditsApi";
import toast from "react-hot-toast";
import {Link, useParams} from "react-router-dom";
import ExcelExport from "../component/excelExport";
import {addTaskToProject, getAllProjects} from "../../../api/projects/projectsApi";
import NewPdfExport from "../component/newpdfexport";

const handleValidDate = (date) => {
    moment.locale('fr');
    const date1 = moment(new Date(date)).format("LL");
    return date1;
};


const HeaderSection = ({audit}) => {
    const params = useParams();
    const queryClient = useQueryClient();
    //Get All Projects
    const projectsQuery = useQuery({
        queryKey: ["projects"],
        queryFn: getAllProjects,
        select: (data) =>
            data.map((item) => ({ label: item.projectName, value: item.projectId })),
    });

    //Add Audit To Project Mutation
    const addAuditToProjectMutation = useMutation(addTaskToProject, {
        onSuccess: () => {
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("audit");
            toast.success("Tâche ajoutée au projet avec succès!");
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    function handleSelectProject(value) {
        addAuditToProjectMutation.mutate({projectId: value, taskId: parseInt(params?.id)})
    }

    // Validate Audit task
    const validateAutitTaskMutation = useMutation(updateAuditStatus, {
        onSuccess: (data) => {
            queryClient.invalidateQueries("audit");
            toast.success("Tâche validée")
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    //Generate Audit Report Excel Mutation
    const generatorAuditReportMutation =  useMutation(generateAuditTask, {
        onSuccess:async (data) => {
            var res= await  ExcelExport(data, audit);
            //recuperation du file et envois vers le mutateur pour l'attacher a l'audit
            handleJoinExcelToAudit(res);
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    //generate pdf data
    const generatepdfdataMutation = useMutation(getExportPdfReport,{
        onSuccess:async (data)=>{
            toast.success("Votre pdf est en cours de construction , cela peut prendre quelques minutes")
            NewPdfExport(data)
        },
        onError:(error)=>{
            toast.error("erreur lors de la génération du pdf")
        }
    })

    //Add Excel to audit after generate
    const handleJoinExcelToAudit = (file)=>{
        const data = new FormData();
        data.append("file",file);
        joinFiletoDemandeMutation.mutate({id:params?.id,data:data})
    };

    // join file to audit after generate audit
    const joinFiletoDemandeMutation = useMutation(joinFiletoDemande,{
        onSuccess :() =>{
            toast.success("file attache")
            queryClient.invalidateQueries("audit_files")
        },
        onError : (error)=>{
            toast.error("erreur lors de l'attache du rapport a l'audit")
        }
    });

    const takeOffTaskToProjectMutation = useMutation(moveTaskToRootProject, {
        onSuccess: () => {
            queryClient.invalidateQueries("audit");
            toast.success("Tâche retirée du projet")
        },
        onError:(error)=>{
            toast.error(error)
        }
    })

    // Delete Task
    const handleDeleteTask = (item) => {
        if(item) deleteAuditMutation.mutate(item?.id);
    };
    // Delete Task Mutation
    const deleteAuditMutation = useMutation(deleteAudit, {
        onSuccess: (data) => {
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("audit");
            navigation("/audits");
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const items = [
        {
            key: '1',
            label: 'Modifier la tâche',
            disabled: true
        },
        {
            key: '2',
            label: audit?.project ? <a onClick={() => takeOffTaskToProjectMutation.mutate({projectId: 0,taskId: parseInt(params?.id)})}>Retirer la tâche du projet</a> : 'Ajouter à un projet',
            children: !audit?.project ? projectsQuery?.data?.map((item) => ({
                key: item?.value,
                label: <a onClick={() => handleSelectProject(item?.value)}>{item?.label}</a>,
            })) : null
        },
        {
            key: '3',
            label: <a onClick={() => handleDeleteTask(audit)}>Supprimer la tâche</a>,
        },
    ];

   const pdf = [
        {
            key: '1',
            label: <Link onClick={(event) => {event.preventDefault(); window.open(`/demande-exportpdf/${audit.id}`);}} target="_blank">Voir le pdf</Link>,
        },{
            key: '2',
            label: <a onClick={()=>generatepdfdataMutation.mutate(params?.id)}>Télécharger le PDF</a>
        }
    ];

   

    return (
        <React.Fragment>
            <Col lg={12}>
                <Card className="mt-n4 mx-n4">
                    <div className="bg-secondary-subtle">
                        <CardBody className="px-4 pb-4">
                            <Row className="mb-3">
                                <Col className="col-md">
                                    <Row className="row align-items-center g-3">
                                        <Col className="col-md-auto">
                                            <div className="avatar-md">
                                                <div className="avatar-title bg-white rounded-circle">
                                                    <img src={Slack} alt="" className="avatar-xs" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="col-md">
                                            <div>
                                                <h4 className="fw-bold">{audit?.name}</h4>
                                                <div className="hstack gap-3 flex-wrap">
                                                    <div>
                                                        <Link to={`/projects/overview/${audit?.project?.actualProject?.projectId}`}>
                                                        <i className="ri-building-line align-bottom me-1"></i>{" "}
                                                        {audit?.project?.actualProject?.projectName || 'Aucun projet'}
                                                        </Link>
                                                    </div>
                                                    <div className="vr"></div>
                                                    <div>
                                                        <i className="ri-map-pin-2-line align-bottom me-1"></i>{" "}
                                                        {audit?.address}
                                                    </div>
                                                    <div className="vr"></div>
                                                    <div>
                                                        Créée le :{" "}
                                                        <span className="fw-semibold">{handleValidDate(audit?.created)}</span>
                                                    </div>
                                                    <div className="vr"></div>
                                                    <div>
                                                        Limite :{" "}
                                                        <span className="fw-semibold">{handleValidDate(audit?.endDate)}</span>
                                                    </div>
                                                    <div className="vr"></div>
                                                    {audit?.status === "In_Progress" ? (
                                                        <Tag icon={<SyncOutlined spin />} color="processing">
                                                            En cours
                                                        </Tag>
                                                    ) : audit?.status === "Affected" ? (
                                                        <Tag icon={<ClockCircleOutlined />} color="default">
                                                            En attente
                                                        </Tag>
                                                    ) : audit?.status === "Created" ? (
                                                        <Tag icon={<ClockCircleOutlined />} color="default">
                                                            En attente
                                                        </Tag>
                                                    ) : audit?.status === "Completed" ? (
                                                        <Tag icon={<CheckCircleOutlined />} color="success">
                                                            Complète
                                                        </Tag>
                                                    ) : audit?.status === "Validated" ? (
                                                        <Tag icon={<FileDoneOutlined />} color="warning">
                                                            Validée
                                                        </Tag>
                                                    ) : audit?.status === "Shared" ? (
                                                        <span className="badge bg-success-subtle text-success text-uppercase">
                                                          Partagée
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="col-md-auto">
                                    <div className="hstack gap-1 flex-wrap mt-4 mt-md-0">
                                        <Tooltip title="Valider la tâche">
                                            <Button onClick={() => validateAutitTaskMutation.mutate({id: audit?.id, status: 'Validated'})} shape="circle" icon={<CheckCircleOutlined style={{color: 'green'}} />} />
                                        </Tooltip>
                                       
                                        <Dropdown
                                            className="mx-3"
                                            menu={{items:pdf}}>
                                            <a onClick={(e) => e.preventDefault()}>
                                            <Tooltip title="Génération PDF">
                                            <Button type="primary" danger shape="circle" icon={<FilePdfOutlined />} />
                                        </Tooltip>
                                            </a>
                                        </Dropdown>

                                        <Tooltip title="Télécharger le rapport Excel">
                                            <Button onClick={() => generatorAuditReportMutation.mutate(params?.id)} type="primary" shape="circle" icon={<FileExcelOutlined />} />
                                        </Tooltip>
                                        
                                       
                                        <Dropdown
                                            disabled={`${audit?.status}` === "Validated" && true}
                                            className="mx-3"
                                            menu={{
                                                items:items,
                                            }}
                                        >
                                            <a onClick={(e) => e.preventDefault()}>
                                                <Space>
                                                    <MoreOutlined style={{ fontSize: '20px' }} />
                                                </Space>
                                            </a>
                                        </Dropdown>
                                        
                                        
                                        
                                        
                                        {/*<UncontrolledDropdown>*/}
                                        {/*    <DropdownToggle*/}
                                        {/*        href="#"*/}
                                        {/*        className="btn btn-icon btn-sm btn-ghost-primary fs-16"*/}
                                        {/*        tag="button"*/}
                                        {/*    >*/}
                                        {/*        <i className="ri-more-2-fill"></i>*/}
                                        {/*    </DropdownToggle>*/}
                                        {/*    <DropdownMenu className="dropdown-menu-end">*/}
                                        {/*        /!*<DropdownItem>*!/*/}
                                        {/*        /!*    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}*!/*/}
                                        {/*        /!*    Modifier la tâche*!/*/}
                                        {/*        /!*</DropdownItem>*!/*/}
                                        {/*        <DropdownItem>*/}
                                        {/*            <i className="ri-file-excel-line align-bottom me-2 text-muted"></i>{" "}*/}
                                        {/*            Ajouter à un projet*/}
                                        {/*        </DropdownItem>*/}

                                        {/*        <DropdownItem divider />*/}

                                        {/*        <DropdownItem*/}
                                        {/*            href="#"*/}
                                        {/*            onClick={() =>*/}
                                        {/*                handleDeleteTask(audit)*/}
                                        {/*            }*/}
                                        {/*        >*/}
                                        {/*            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}*/}
                                        {/*            Supprimer la tâche*/}
                                        {/*        </DropdownItem>*/}

                                        {/*    </DropdownMenu>*/}
                                        {/*</UncontrolledDropdown>*/}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default HeaderSection;
