import React, { useEffect, useRef, useState } from "react";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
  Link,
} from "@react-pdf/renderer";
import { useQuery } from "react-query";
import { getExportPdfReport } from "../../../api/audits/auditsApi";
import logo2 from "../../../assets/login_page_assets/images/blanc.png";
import noImage from "../../../assets/images/no-image.jpg";
import PdfRowData from "./exportPdfComponents/pdf_row_data";
import CableRow from "./exportPdfComponents/cable_row";
import Pageheader from "./exportPdfComponents/page_header";

const styles = StyleSheet.create({
  container: {
    margin: 25,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  watermark: {
    width: "50%",
    height: "50%",
    opacity: 0.6,
    marginTop: 250,
  },
  contenus: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 20,
  },
  bottom: {
    position: "absolute",
    bottom: -150,
    right: 20,
    flexDirection: "row",
  },
  bottom_text: {
    fontSize: 10,
    color: "#808080",
  },
  header:{
    flexDirection: "row",
   
    justifyContent: "space-between",

  },
  titre: {
    fontSize: 15,
    fontWeight: "heavy",
    fontFamily: "Helvetica-Bold",
  },

  text: {
    fontSize: 13,
  },

  conformite_box_green: {
    backgroundColor: "#a3f0b7",
    width: "100%",
    padding: 13,
  },
  conformite_box_red: {
    backgroundColor: "#e6a19a",
    width: "100%",
    padding: 13,
  },
  conformite_text_green: {
    color: "#174a24",
    fontSize: 13,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    marginBottom: 10,
  },
  conformite_text_red: {
    color: "#912114",
    fontSize: 13,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    marginBottom: 10,
  },
});

export default function Pdfbuilder({data}) {
  
 
  const photoBpe = [
    {
      image: data?.boxReferenceImageUrl,
      legende: "Image Box",
    },
    {
      image: data?.cablesNumberImageUrl,
      legende: "Image Cable",
    },
    {
      image: data?.cassetteNumberImageUrl,
      legende: "Image Cassette",
    },
  ];

  const dataformate = (value) => {
    const moisfr = [
      "Janvier",
      "Février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ];

    var date = new Date(value);
    var jour = date.getDate();
    var mois = date.getMonth();
    var annee = date.getFullYear();

    return jour + " " + moisfr[mois] + " " + annee;
  };

  const [isload,setload]=useState(true)

  const gethoursAndMinutes=(value)=>{
    var date = new Date(value);
    var hours = date.getHours();
    var minutes = date.getMinutes();

    return hours+":"+minutes
  }

  function segmenterTableau(tab, nbr_element) {
    let debut = 0;
    let fin = nbr_element;
    let dataslice = [];

    for (let i = 0; i < tab.length; i += nbr_element) {
      dataslice.push(tab.slice(debut, fin));
      debut = fin;
      fin += nbr_element;
    }

    return dataslice;
  }

  function changeToUpperCase(text){
    
    return text.toUpperCase();
  }


  return (
    <Document >
              <Page size="A4">
                <View style={styles.container}>
                  <Image src={logo2} style={styles.watermark} />

                  <View style={styles.contenus}>
                  
                    <Pageheader/>

                    <View style={{ marginBottom: 50 }}>
                      <Text style={styles.titre}>CR Audit BPE</Text>
                      <Text style={styles.text}>{data?.taskName}</Text>
                    </View>

                    <View style={{ marginTop: 20, marginBottom: 40 }}>
                      <Text style={styles.titre}>Information sur l'audit</Text>
                    </View>

                    <View>
                      <PdfRowData
                        titre={"Date de l'audit"}
                        value={
                          <Text style={styles.text}>
                            {dataformate(data?.auditDate)}
                          </Text>
                        }
                      />
                      <PdfRowData
                        titre={"Heure de début de l’audit"}
                        value={
                          <Text style={styles.text}>{gethoursAndMinutes(data?.startTime)}</Text>
                        }
                      />
                      <PdfRowData
                        titre={"Heure de fin de l’audit"}
                        value={<Text style={styles.text}>{gethoursAndMinutes(data?.endTime)}</Text>}
                      />
                      <PdfRowData
                        titre={"Coordonées G.P.S."}
                        value={
                          <Text style={styles.text}>
                            {data?.place?.location?.latitude +
                              "," +
                              data?.place?.location?.longitude}
                          </Text>
                        }
                      />
                      <PdfRowData
                        titre={"Adrese postale"}
                        value={
                          <Text style={styles.text}>
                            {changeToUpperCase(data?.place?.address)}
                          </Text>
                        }
                      />
                      <PdfRowData
                        titre={"Type d’environnement"}
                        value={
                          <Text style={styles.text}>{changeToUpperCase(data?.place["type"])}</Text>
                        }
                      />
                      <PdfRowData
                        titre={" Référence BPE audité"}
                        value={
                          <Text style={styles.text}>{changeToUpperCase(data?.boxReference)}</Text>
                        }
                      />
                      <PdfRowData
                        titre={"Type BPE"}
                        value={
                          <Text style={styles.text}>{changeToUpperCase(data?.boxType?.type)}</Text>
                        }
                      />
                      <PdfRowData
                        titre={" Nombre de cable"}
                        value={
                          <Text style={styles.text}>{data?.cables.length}</Text>
                        }
                      />
                    </View>
                  </View>

                  <View style={styles.bottom}>
                    <Text style={styles.bottom_text}>Powered by Audexa  </Text>
                    <Text style={styles.bottom_text}>  - -  www.audexa.io</Text>
                  </View>
                </View>
              </Page>

              {Object.keys(data?.qcm).length > 0 && (
                <Page size="A4">
                  <View style={styles.container}>
                    <Image src={logo2} style={styles.watermark} />

                    <View style={styles.contenus}>
                    <Pageheader/>
                    
                      <View style={{ marginTop: 1, marginBottom: 40 }}>
                        <Text style={styles.titre}>
                          Information sur l'audit
                        </Text>
                      </View>

                      <View style={{ flex: 2, flexDirection: "column" }}>
                        {Object.keys(data?.qcm).map((item, index) => (
                          <PdfRowData
                            key={index}
                            titre={item}
                            value={
                              data?.qcm[item]?.response ? (
                                <View style={styles.conformite_box_green}>
                                  <Text style={styles.conformite_text_green}>
                                    Conforme
                                  </Text>
                                </View>
                              ) : (
                                <View style={styles.conformite_box_red}>
                                  <Text style={styles.conformite_text_red}>
                                    Non conforme
                                  </Text>
                                </View>
                              )
                            }
                          />
                        ))}
                      </View>
                    </View>

                    <View style={styles.bottom}>
                    <Text style={styles.bottom_text}>Powered by Audexa  </Text>
                    <Text style={styles.bottom_text}>  - -  www.audexa.io</Text>
                  </View>
                  </View>
                </Page>
              )}

              {Object.keys(data?.qcm).length > 0 &&
                segmenterTableau(Object.keys(data?.qcm), 4).map(
                  (data_tableau, index) => (
                    <Page size="A4" key={index}>
                      <View style={styles.container}>
                        <Image src={logo2} style={styles.watermark} />

                        <View style={styles.contenus}>

                        <Pageheader/>
                        
                          

                          <View style={{ marginBottom: 20 }}>
                            <Text style={styles.titre}>
                              Photo conformité et non conformité
                            </Text>
                          </View>

                          <View>
                            {data_tableau.map((item, index) => (
                              <PdfRowData
                                key={index}
                                titre={item}
                                value={
                                  <Link src={
                                    `${process.env.REACT_APP_API_ORIGINAL_IMG_URL}`+"/" +
                                    data?.qcm[item]?.imgUrl
                                  }>
                                  <Image
                                    src={
                                      `${process.env.REACT_APP_API_IMG_URL}` +
                                      "/"+data?.qcm[item]?.imgUrl
                                    }
                                    style={{ height: 100 }}
                                  />
                                  </Link>
                                }
                              />
                            ))}
                          </View>
                        </View>

                        <View style={styles.bottom}>
                    <Text style={styles.bottom_text}>Powered by Audexa  </Text>
                    <Text style={styles.bottom_text}>  - -  www.audexa.io</Text>
                  </View>
                      </View>
                    </Page>
                  )
                )}

              <Page size="A4">
                <View style={styles.container}>
                  <Image src={logo2} style={styles.watermark} />

                  <View style={styles.contenus}>
                  <Pageheader/>
                  
                    <View style={{ marginBottom: 20 }}>
                      <View style={{ marginBottom: 20 }}>
                        <Text style={styles.titre}>
                          Photo de l'environnement
                        </Text>
                      </View>
                      {data?.place?.photoUrl ? (
                        <Link href={
                          `${process.env.REACT_APP_API_ORIGINAL_IMG_URL}`+"/" +
                          data?.place?.photoUrl
                        }>
                        <Image
                          src={
                            `${process.env.REACT_APP_API_IMG_URL}`+"/" +
                            data?.place?.photoUrl
                          }
                          style={{ height: 200, marginBottom: 10 }}
                        />
                        </Link>
                      ) : (
                        <Image
                          src={noImage}
                          style={{ height: 200, marginBottom: 10 }}
                        />
                      )}
                    </View>

                    <View>
                      <View style={{ marginBottom: 20 }}>
                        <Text style={styles.titre}>Photo BPE</Text>
                      </View>

                      {photoBpe.map((bpe_element, index) => (
                        <PdfRowData
                          key={index}
                          titre={bpe_element.legende}
                          value={
                            bpe_element.image ? (
                              <Link href={`${process.env.REACT_APP_API_ORIGINAL_IMG_URL}`+"/" +
                              bpe_element.image}>
                              <Image
                                src={
                                  `${process.env.REACT_APP_API_IMG_URL}`+"/" +
                                  bpe_element.image
                                }
                                style={{ height: 120 }}
                              />
                              </Link>
                            ) : (
                              <Image src={noImage} style={{ height: 120 }} />
                            )
                          }
                        />
                      ))}
                    </View>
                  </View>

                  <View style={styles.bottom}>
                    <Text style={styles.bottom_text}>Powered by Audexa  </Text>
                    <Text style={styles.bottom_text}>  - -  www.audexa.io</Text>
                  </View>
                </View>
              </Page>

              {data?.cables.length > 0 &&
                segmenterTableau(data?.cables, 3).map((data_tableau, index) => (
                  <Page size="A4" key={index}>
                    <View style={styles.container}>
                      <Image src={logo2} style={styles.watermark} />

                      <View style={styles.contenus}>
                      <Pageheader/>
                      

                        <View style={{ marginBottom: 20 }}>
                          <Text style={styles.titre}>Cables</Text>
                        </View>

                        {data_tableau.map((element, index) => (
                          <CableRow
                          key={index}
                            image={element?.cableImageUrl}
                            cable_datas={
                              <View>
                                <PdfRowData
                                  titre={"référence cable"}
                                  value={
                                    <Text style={styles.text}>
                                      {element?.cableName}
                                    </Text>
                                  }
                                />
                                <PdfRowData
                                  titre={"année de fabrication"}
                                  value={
                                    <Text style={styles.text}>
                                      {element?.manufacturingYear}
                                    </Text>
                                  }
                                />
                                <PdfRowData
                                  titre={"année de pose"}
                                  value={
                                    <Text style={styles.text}>
                                      {element?.installationYear}
                                    </Text>
                                  }
                                />
                                <PdfRowData
                                  titre={"numéro de FCI"}
                                  value={
                                    <Text style={styles.text}>
                                      {element?.ncinumber}
                                    </Text>
                                  }
                                />
                                <PdfRowData
                                  titre={"capacité cable"}
                                  value={
                                    <Text style={styles.text}>
                                      {element?.cableCapacity}
                                    </Text>
                                  }
                                />
                                <PdfRowData
                                  titre={"modulo cable"}
                                  value={
                                    <Text style={styles.text}>
                                      {element?.cableModulo}
                                    </Text>
                                  }
                                />
                              </View>
                            }
                          />
                        ))}
                      </View>

                      <View style={styles.bottom}>
                    <Text style={styles.bottom_text}>Powered by Audexa  </Text>
                    <Text style={styles.bottom_text}>  - -  www.audexa.io</Text>
                  </View>
                    </View>
                  </Page>
                ))}

              {data?.cassettes.length > 0 &&
                segmenterTableau(data?.cassettes, 4).map(
                  (data_tableau, index) => (
                    <Page size="A4" key={index}>
                      <View style={styles.container}>
                        <Image src={logo2} style={styles.watermark} />

                        <View style={styles.contenus}>
                        <Pageheader/>
                        
                          <View style={{ marginBottom: 20 }}>
                            <Text style={styles.titre}>Cassettes</Text>
                          </View>

                          {data_tableau.map((item, index) => (
                            <PdfRowData
                            key={index}
                              titre={
                                item?.cassetteType
                                  .toLowerCase()
                                  .includes("cas") ||
                                item?.cassetteType
                                  .toLowerCase()
                                  .includes("fond")
                                  ? item?.cassetteType
                                  : "Cassete " + item?.cassetteType
                              }
                              value={
                                <Link href={
                                  `${process.env.REACT_APP_API_ORIGINAL_IMG_URL}`+"/" +
                                  item?.cassetteImageUrl
                                }>
                                <Image
                                  src={
                                    `${process.env.REACT_APP_API_IMG_URL}`+"/" +
                                    item?.cassetteImageUrl
                                  }
                                  style={{ height: 100 }}
                                />
                                </Link>
                              }
                            />
                          ))}
                        </View>

                        <View style={styles.bottom}>
                    <Text style={styles.bottom_text}>Powered by Audexa  </Text>
                    <Text style={styles.bottom_text}>  - -  www.audexa.io</Text>
                  </View>
                      </View>
                    </Page>
                  )
                )}
                
                {data?.afterImages.length > 0 &&
                segmenterTableau(data?.afterImages, 9).map(
                  (data_tableau, index) => (
                    <Page size="A4" key={index}>
                      <View style={styles.container}>
                        <Image src={logo2} style={styles.watermark} />

                        <View style={styles.contenus}>
                        <Pageheader/>
                        

                          <View style={{ marginBottom: 20 }}>
                            <Text style={styles.titre}>Photos apres travaux </Text>
                          </View>
                          <View  style={{flexDirection: "row",display: "flex",flexWrap: "wrap",}}>
                          {data_tableau.map((item, index) => (
                            <Link href={`${process.env.REACT_APP_API_ORIGINAL_IMG_URL}`+"/"+item}>
                                <Image src={`${process.env.REACT_APP_API_IMG_URL}`+"/"+item} key={index} style={{width:150,margin:5,height:170}}/>
                        
                            </Link>
                          ))}
                          </View>
                          
                        </View>

                        <View style={styles.bottom}>
                    <Text style={styles.bottom_text}>Powered by Audexa  </Text>
                    <Text style={styles.bottom_text}>  - -  www.audexa.io</Text>
                  </View>
                      </View>
                    </Page>
                  )
                )}
            </Document>
  )


}
