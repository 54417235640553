import React, {useContext, useState} from "react";
import {
    Container,
    Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb/BreadCrumb";
import AddEditDrawerSpace from "./components/addEditSpace";
import {useQuery} from "react-query";
import { getSpacesBySuperAdminId } from "../../api/space/spaceAPI";
import 'moment/locale/fr';
import SpaceCard from "./components/spaceCard";
import {Button, Empty, Result, Spin} from "antd";
import HistoryContext from "../../Components/Hooks/HistoryContext";

const CustomerSpace = () => {
    const history = useContext(HistoryContext)
    const {isError, error, isLoading, data: spaces} = useQuery(["spaces", JSON.parse(sessionStorage.getItem("authUser"))?.userId], () => getSpacesBySuperAdminId(JSON.parse(sessionStorage.getItem("authUser"))?.userId))
    const [key, setKey] = useState("")
    const [item, setItem] = useState(null);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };

    if (isLoading) {
        return <Spin spinning={isLoading} fullscreen />
    }

    if (isError) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Result
                        status="warning"
                        title='La ressource demandée est introuvable. Veuillez vérifier votre connexion et réessayer.'
                        extra={
                            <Button type="primary" key="console" onClick={() => window.location.reload()}>
                                Refresh
                            </Button>
                        }
                    />
                </Container>
            </div>
        );
    }

    // if (spaces && !spaces?.length>0) {
    //     return (
    //         <div className="page-content">
    //             <Container fluid>
    //                 <Empty
    //                     image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    //                     imageStyle={{
    //                         height: 150,
    //                     }}
    //                     description={
    //                         <span>
    //                             Aucun espace n'est disponible!
    //                         </span>
    //                     }
    //                 >
    //                     <Button
    //                         onClick={showDrawer}
    //                         type="primary">Créer Maintenant
    //                     </Button>
    //                 </Empty>
    //             </Container>
    //         </div>
    //     )
    // }

    const filteredData = spaces?.filter((item) =>
        item?.clientSpace?.companyName.toLowerCase().includes(key?.toLowerCase())
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        page="Espaces"
                        history={history}
                        data={spaces?.map((item) => ({...item, name: item?.clientSpace?.companyName}))}
                        setKey={setKey}
                        btnName="Nouveau espace client"
                        handleClick={showDrawer}
                    />
                    <Row className="mt-4">
                        {filteredData && filteredData.map((space, key) => (
                            <SpaceCard setIsEdit={setIsEdit} setItem={setItem} setOpen={setOpen} space={space} key={key}/>
                        ))}
                        {!filteredData.length>0 && (
                            <Empty
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                imageStyle={{
                                    height: 250,
                                }}
                                description={
                                    <span>
                                Aucun espace n'est disponible!
                            </span>
                                }
                            >
                            </Empty>
                        )}
                    </Row>
                    <AddEditDrawerSpace
                        setIsEdit={setIsEdit}
                        isEdit={isEdit}
                        setItem={setItem}
                        item={item}
                        open={open}
                        setOpen={setOpen}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CustomerSpace;