import {APIClient} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import axios from "axios";
import {GET_SPACES_BY_SUPER_ADMIN_ID} from "../../helpers/url_helper";

const api = new APIClient();

export const createAdminSpace = async (data) => {
    const response = await api.create(url.ADD_ADMIN_SPACE, data.formData);
    if (data.profile) {
        await axios.put(url.UPLOAD_USER_IMG(response.id), {'profile': data.profile}, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }
    return {...response, userId: response.id, phone: response.mobileNumber};
}

export const addSpace = async (data) => {
    return await axios.post(url.ADD_SPACE, {...data}, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export const updateSpace = async (data) => {
    return await axios.put(url.UPDATE_SPACE(data.id), {...data.space}, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export const getSpaces = async () => {
    return await api.get(url.GET_SPACES);
}

export const deleteSpace = async (id) => {
    const response = await api.delete(url.DELETE_SPACE(id));
    return response;
}

export const updateSpaceState = async (data) => {
    const response = await api.put(url.UPDATE_SPACE(data.id), {...data}, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return response;
}

export const getSpacesBySuperAdminId = async (id) => {
    const response = await api.get(url.GET_SPACES_BY_SUPER_ADMIN_ID(id));
    return response;
}