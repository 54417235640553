import React, { useState } from 'react';
// Import React FilePond
import { FilePond,  registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const PojectUpload = ({files, setFiles}) => {
    const handleUpdateFiles = (files) => {
        const resources = files.map((file) => ({
            source: file.source,
            tag: 'interior',
        }));
        setFiles(resources);
    };

    return (
        <FilePond
            files={files}
            onupdatefiles={handleUpdateFiles}
            allowMultiple={true}
            maxFiles={3}
            name="files"
            className="filepond filepond-input-multiple"
            labelIdle='Glisser et déposer vos fichiers ou <span class="filepond--label-action">Browse</span>'
        />
    );
};

export default PojectUpload;