import React, { useState, useCallback, useMemo, useContext } from "react";
import { Col, Container, Row, Card, CardBody, CardHeader } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb/BreadCrumb";
import TableContainerMin from "../../Components/Common/TableContainerMin";
import { useQuery } from "react-query";
import { downloadCommandPDF,getCommands, getCommandByCompany } from "../../api/commands/commandsApi";
import AddQuotaForm from "./component/addQuotaForm";
import { ShoppingCartOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Spin, Modal } from "antd";
import HistoryContext from "../../Components/Hooks/HistoryContext";

const Commandes = () => {
  const history = useContext(HistoryContext);
  document.title = `Commandes | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;
  
  const [modal, setModal] = useState(false);

  const currentUser = JSON.parse(sessionStorage.getItem('authUser')) || null;

  const company = currentUser.company;
  const { isLoading, isError, data: commands } = useQuery(
      ["commands", company],
      () => getCommandByCompany(company)
  );

 const quotaRestant = commands && commands.length > 0 
    ? ((commands[0]?.quota || 0) - (commands[0]?.quotaUsedTask || 0)) 
    : "non trouvé";

  const toggle = useCallback(() => {
    setModal((prevModal) => !prevModal);
  }, []);

  const { confirm } = Modal;
  const handleDownload = async (id, commandNumber) => {
    try {
      await downloadCommandPDF(id, commandNumber);
    } catch (error) {
      console.error("Erreur lors du téléchargement du PDF de la commande :", error);
    }
  }
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('fr-FR');
    const formattedTime = date.toLocaleTimeString('fr-FR');
    return { formattedDate, formattedTime };
  };

  const columns = useMemo(
    () => [
      {
        Header: "Numéro commande",
        accessor: "commandNumber",
        filterable: false,
      },
      {
        Header: "Commande",
        accessor: "numberOfTask",
        filterable: false,
      },
      {
        Header: "Montant total",
        accessor: "totalAmount",
        filterable: false,
      },
      {
        Header: "Entreprise",
        accessor: "companyName",
        Cell: ({ row }) => (
          <div>
            {row.original.companyName || "Non trouvé"}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "Téléphone",
        accessor: "userDto",
        Cell: ({ row }) => (
          <div>
            {row.original.userDto.phone || "Non trouvé"}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "Statut",
        accessor: "status",
        Cell: ({ value }) => (
          <span className={`badge bg-${value === "paid" ? "success" : "info"}-subtle text-${value === "paid" ? "success" : "info"}`}>
            {value}
          </span>
        ),
        filterable: false,
      },
      {
        Header: "Date commande",
        accessor:  (row) => {
          const { formattedDate, formattedTime } = formatDate(row.creationDate);
          return (
            <span>
              {formattedDate} - {formattedTime}
            </span>
          );
        },
        filterable: false,
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <Button
            color="success"
            onClick={() => {
              confirm({
                title: 'Confirmation',
                content: 'Êtes-vous sûr de vouloir télécharger cette commande en PDF ?',
                onOk() {
                  handleDownload(row.original.id, row.original.commandNumber);
                },
              });
            }}
          >
            <DownloadOutlined />
          </Button>
        ),
        filterable: false,
      },
    ],
    []
  );

  const HeaderTable = () => (
    <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
      <div className="hstack text-nowrap gap-2" style={{ backgroundColor: '#D0F0C0', color: '#000', fontSize: '1.1rem', fontWeight: 'bold', padding: '5px 10px', borderRadius: '5px' }}>
        Forfait : {quotaRestant}
      </div>
      <div className="flex-shrink-0">
        <div className="hstack text-nowrap gap-2">
          <Button
            type="button"
            className="btn btn-soft-success"
            icon={<ShoppingCartOutlined />}
            onClick={toggle}
          >
            Commander un forfait
          </Button>
        </div>
      </div>
    </div>
  );

  const handleResultDisplay = () => {
    if (isLoading) {
      return <Spin spinning={isLoading} fullscreen />;
    }
    if (isError) {
      return (
        <div className="page-content">
          <Container fluid>
            <Result
              status="warning"
              title="La ressource demandée est introuvable. Veuillez vérifier votre connexion et réessayer."
              extra={
                <Button
                  type="primary"
                  key="console"
                  onClick={() => window.location.reload()}
                >
                  Refresh
                </Button>
              }
            />
          </Container>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb page="Quotas" history={history} />
          <Row>
            <Col>
              <Card id="contactList">
                <CardHeader>
                  <HeaderTable />
                </CardHeader>
                <CardBody className="pt-0">
                  <div>
                    <TableContainerMin
                      columns={columns}
                      data={commands || []}
                      isGlobalSearch={true}
                      isGlobalFilter={true}
                      isUsersFilter={false}
                      customPageSize={5}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                      SearchPlaceholder="Recherche..."
                      HeaderTable={HeaderTable}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <AddQuotaForm modal={modal} setModal={setModal} toggle={toggle} quotaRestant={quotaRestant} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Commandes;
