import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Col,
  FormFeedback,
    Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as moment from "moment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { createTask } from "../../../api/planning/planningApi";
import { getMembers } from "../../../api/users/usersApi";
import { useParams } from "react-router";
import {DatePicker , Form as FormAntd} from 'antd';
const { RangePicker } = DatePicker;

const AddEditFormTask = ({
  modal,
  toggle,
  event,
}) => {
    const params = useParams();
    const queryClient = useQueryClient();
    //Get All Projects
    const techniciansQuery = useQuery({
        queryKey: ['users'],
        queryFn: getMembers,
        select: (data) => data.filter((item) => item.userRole === "ROLE_MOBILE_USER"),
    })

    const addTaskMutation = useMutation(createTask, {
        onSuccess: () => {
        // Invalidates cache and refetch data
        queryClient.invalidateQueries("projects");
        toast.success("La tâche a été créé avec succès")
        },
        onError: (error) => {
        // An error happened!
        toast.error(error)
        },
    });

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
        name: (event && event.name) || "",
        description: (event && event.description) || "",
        address: (event && event.address) || "",
        dueDate: (event && event.dueDate) || "",
        endDate: (event && event.endDate) || "",
        priority: (event && event.priority) || "Medium",
        userId: (event && event.userId) || "",
        },

        validationSchema: Yup.object({
        name: Yup.string().required("Le libellé de la tâche est obligatoire"),
        address: Yup.string().required("L'adresse est obligatoire"),
        }),
        onSubmit: (values) => {
            const {
                name,
                description,
                address,
                priority,
                dueDate,
            } = values;
            const formData = new FormData();
            formData.append("name", name);
            formData.append("description", description);
            formData.append("address", address);
            formData.append("priority", priority);
            formData.append("status", "In_Progress");
            formData.append("dueDate", dueDate && moment(dueDate[0]?.$d).format('yyyy-MM-DDThh:mm:ss'));
            formData.append("endDate", dueDate && moment(dueDate[1]?.$d).format('yyyy-MM-DDThh:mm:ss'));
            formData.append("interlocFirstname", "");
            formData.append("interlocLastname", "");
            formData.append("interlocEmail", "");
            formData.append("interlocPhone", "");
            formData.append("company", JSON.parse(sessionStorage.getItem("authUser"))?.company);
            const data = {
                formData,
                userId: values.userId,
                projectId: params.id && params.id
            };
            // for (const value of formData.values()) {
            //     console.log(value);
            // }
            addTaskMutation.mutate(data)
            validation.resetForm();
        },
    });

    return (
        <Modal isOpen={modal} id="addTaskForm" centered backdrop={"static"}>
        <ModalHeader
            toggle={toggle}
            tag="h5"
            className="p-3 bg-info-subtle modal-title"
        >
            {/*Ajouter une nouvelle tâche*/}
        </ModalHeader>
        <ModalBody>
            <Form
            className="needs-validation"
            name="event-form"
            id="form-event"
            onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}
            >
            <Row className="event-form">
                <Col xs={12}>
                <div className="mb-3">
                    <Label className="form-label">Tâche<span className="text-danger mx-1">*</span></Label>
                    <Input
                        className="form-control d-block"
                    placeholder="Saisir le nom de la tâche"
                    type="text"
                    name="name"
                    id="event-title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      !!(validation.touched.name &&
                          validation.errors.name)
                    }
                    />
                    {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                        {validation.errors.name}
                    </FormFeedback>
                    ) : null}
                </div>
                </Col>
                <Col xs={12}>
                <div className="mb-3">
                    <Label className="form-label">Description</Label>
                    <textarea
                    className="form-control d-block"
                    id="event-description"
                    name="description"
                    placeholder="Donner une description"
                    rows="3"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    ></textarea>
                    {validation.touched.description &&
                    validation.errors.description ? (
                    <FormFeedback type="invalid">
                        {validation.errors.description}
                    </FormFeedback>
                    ) : null}
                </div>
                </Col>
                <Col xs={12}>
                <div className="mb-3">
                    <Label htmlFor="event-location">Lieu d'Intervention<span
                        className="text-danger mx-1">*</span></Label>
                    <div>
                    <Input
                        type="text"
                        className="form-control d-block"
                        name="address"
                        id="event-location"
                        placeholder="Entrer l'adresse du lieu"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address || ""}
                        invalid={
                            !!(validation.touched.address &&
                                validation.errors.address)
                        }
                    />
                    {validation.touched.address && validation.errors.address ? (
                        <FormFeedback type="invalid">
                        {validation.errors.address}
                        </FormFeedback>
                    ) : null}
                    </div>
                </div>
                </Col>
                <Col xs={12}>
                <div className="mb-3">
                    <Label>Date d'Intervention</Label>
                    <div
                    className="input-group"
                    >
                        <FormAntd.Item>
                            <RangePicker
                                name="dueDate"
                                onChange={(value) => validation.setFieldValue("dueDate", value)}
                                showTime
                                style={{width: '100%'}}
                                size="large"
                                format="DD-MM-YYYY HH:mm"
                            />
                        </FormAntd.Item>
                    </div>
                </div>
                </Col>
                <Col xs={12}>
                <div className="mb-3">
                    <Label className="form-label">Techniciens</Label>
                    <Input
                    className="input-group"
                    name="userId"
                    id="event-category"
                    type="select"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.userId || ""}
                    >
                    <option value=""></option>
                    {techniciansQuery &&
                        techniciansQuery?.data?.map(
                        ({ firstname, lastname, userId }, index) => (
                            <option key={index} value={userId}>
                            {firstname} {lastname}
                            </option>
                        )
                        )}
                    </Input>
                    {validation.touched.userId && validation.errors.userId ? (
                    <FormFeedback type="invalid">
                        {validation.errors.userId}
                    </FormFeedback>
                    ) : null}
                </div>
                </Col>
                <Col lg={12}>
                    <div className="mt-2">
                        <div className="form-check form-radio-primary mb-3">
                            <Input
                                className="form-check-input"
                                type="radio"
                                name="priority"
                                id="formradioRight5"
                                value="Medium"
                                checked={validation.values.priority === "Medium"}
                                onChange={validation.getFieldProps("priority").onChange}
                                defaultChecked/>
                            <Label className="form-check-label" for="formradioRight5">
                                Important
                            </Label>
                        </div>

                        <div className="form-check form-radio-warning mb-3">
                            <Input
                                className="form-check-input"
                                type="radio"
                                name="priority"
                                id="formradioRight8"
                                value="High"
                                checked={validation.values.priority === "High"}
                                onChange={validation.getFieldProps("priority").onChange}
                            />
                            <Label className="form-check-label" for="formradioRight8">
                                Urgent
                            </Label>
                        </div>

                        <div className="form-check form-radio-danger mb-3">
                            <Input
                                className="form-check-input"
                                type="radio"
                                name="priority"
                                id="formradioRight9"
                                value="Highest"
                                checked={validation.values.priority === "Highest"}
                                onChange={validation.getFieldProps("priority").onChange}
                            />
                            <Label className="form-check-label" for="formradioRight9">
                                Très Urgent
                            </Label>
                        </div>
                    </div>
                </Col>
            </Row>
                <div className="hstack gap-2 justify-content-end mt-2">
                    <button
                        type="submit"
                        className="btn btn-success"
                        id="btn-save-event"
                    >
                        Ajouter
                    </button>
                </div>
            </Form>
        </ModalBody>
        </Modal>
    );
};

export default AddEditFormTask;
