import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb/BreadCrumb";
import HistoryContext from "../../Components/Hooks/HistoryContext";
import { useQuery } from "react-query";
import { getEventsByTech } from "../../api/planning/planningApi";
import Timeline from "./component/Timeline";
import loading from "../../assets/images/loading.gif"

const Planning = () => {
  const history = useContext(HistoryContext);
  document.title = `Planning | ${process.env.REACT_APP_NAME} - ${process.env.REACT_APP_DESCRIPTION}`;
  const { isLoading, data: resources } = useQuery({
    queryKey:"tasks", 
    queryFn:getEventsByTech,
  });

  if (isLoading) {
    return (
      <div className="page-content">
        <Container style={{display:"flex",'justify-content': 'center','align-items': 'center',height:"80vh"}}>
        <img src={loading} alt='loading'/>
        </Container>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            page="Planning"
            history={history}
          />
          <Timeline sections={resources?.sections} events={resources?.events} />
        </Container>
      </div>
    </React.Fragment>
  );
};

Planning.propTypes = {
  events: PropTypes.any,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default Planning;
