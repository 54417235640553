import ExcelJS from 'exceljs';


function borderOutline(worksheet ) {
  
 const rangeLine = ["B","C","D","E","F","G","H","I"]
//la ligne du haut plus les bordures de coin
  for (let line of rangeLine){

    let cell =worksheet.getCell(line+2)
    
    if(line+2=="B2"){

      cell.border={
        top: {style:'medium', color: {argb:'00000000'}},
        left:{style:'medium'}
      }

      }
      else if(line+2=="I2"){
        cell.border={
          top: {style:'medium', color: {argb:'00000000'}},
          right:{style:'medium'}
        }
   }
      else{
        cell.border={
          top: {style:'medium', color: {argb:'00000000'}},
        }
      }
    }
    
    
//ligne du bas plus bordure du coins
    for (let line of rangeLine){
    let cell =worksheet.getCell(line+12)
    if(line+12=="B12"){

      cell.border={
        left: {style:'medium', color: {argb:'00000000'}},
        bottom:{style:'medium'}
      }

      }
      else if(line+12=="I12"){
        cell.border={
          right: {style:'medium', color: {argb:'00000000'}},
          bottom:{style:'medium'}
        }
   }
      else{
        cell.border={
          bottom: {style:'medium', color: {argb:'00000000'}},
        }
      }
    }
  
    //les bars latteral sans les coins
    for (let i =3;i<=11;i++){
    let cell =worksheet.getCell("B"+i)
    cell.border={
      left: {style:'medium', color: {argb:'00000000'}},
    }

    cell =worksheet.getCell("I"+i)
    cell.border={
      right: {style:'medium', color: {argb:'00000000'}},
    }
    }
  
}

function makehearderforexcel(workbook,taskdatas){
  
  const worksheet = workbook.addWorksheet('Liste des cassettes')
    
  
  worksheet.mergeCells("C2:H2")
   let textcell1= worksheet.getCell('G2')
   textcell1.value="Audit de Boite de protection d'eppissure - "+`${process.env.REACT_APP_EXCEL_ENTREPRISE_NAME}`
   textcell1.alignment = { horizontal: 'center', vertical: 'middle' };
   textcell1.font={
    bold:true,
    size:18,
    //color: { argb: 'FFFFFF' },

  }

  textcell1 = worksheet.getCell('C6')
  textcell1.value = "Local Technique :"

  textcell1 = worksheet.getCell('D6')
  textcell1.value = taskdatas.boxRef

  textcell1 = worksheet.getCell('F4')
  textcell1.value = "Numero Boite :"
  
  textcell1 = worksheet.getCell('C9')
  textcell1.value = "Version du :"
  
  textcell1 = worksheet.getCell('D9')
  textcell1.value =new Date().toISOString().split('T')[0];


  textcell1 = worksheet.getCell('G4')
  textcell1.value = taskdatas.boxRef

  textcell1 = worksheet.getCell('F6')
  textcell1.value = "Modéle Boite :"

  textcell1 = worksheet.getCell('G6')
  textcell1.value = taskdatas?.boxType?.type

  textcell1 = worksheet.getCell('F8')
  textcell1.value = "Support Boite :"


  textcell1 = worksheet.getCell('F10')
  textcell1.value = "Adresse :"

  textcell1 = worksheet.getCell('G10')
  textcell1.value = taskdatas.address;

  
    const headers = [
      ,"Position","Equipement Amont","Capacité", "Câble", 
      "Tube/Gaine", "Fibre/Fil", "Statut",
      "Fibre/Fil", "Tube/Gaine","Câble", "Capacité",
       "Equipement aval"
    ];

    worksheet.addRow()
    worksheet.addRow()
    worksheet.addRow()
    worksheet.addRow()
   let row = worksheet.addRow(headers);
    row.eachCell((cell) => {
      cell.fill={
        type:'pattern',
        pattern:"solid",
        fgColor:{argb:"999999"}
    }
      cell.font={
        bold:true
      }
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });

    borderOutline(worksheet)
  return worksheet;
}


function fiberColor(cell,val) {

  switch (val) {
    case "1":
        cell.fill={
            type:"pattern",
            pattern:"solid",
            fgColor:{argb:"FF0000"},   
        }

        cell.font = {
            color: { argb: 'FFFFFF' }, // Blanc
          };

        break;
        case "2":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"0000FF"}
        }
        cell.font = {
            color: { argb: 'FFFFFF' }, // Blanc
          };
        
        break;
        case "3":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"18F10E"}
        }
        
        
        break;
        case "4":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"FFFF00"}
        }
        
        
        break;
        case "5":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"81007F"}
        }
        cell.font = {
            color: { argb: 'FFFFFF' }, // Blanc
          };
          
        break;
        case "6":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"FFFFFF"}
        }
        
        break;
        case "7":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"FF7F00"}
        }
        cell.font = {
            color: { argb: 'FFFFFF' }, // Blanc
          };
          
        break;
        case "8":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"AFAFAF"}
        }
        cell.font = {
            color: { argb: 'FFFFFF' }, // Blanc
          };
          
        break;
        case "9":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"B05121"}
        }
        cell.font = {
            color: { argb: 'FFFFFF' }, // Blanc
          };
          
        break;
        case "10":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"000000"},
       }
        cell.font = {
            color: { argb: 'FFFFFF' },
             // Blanc
          };
          
        break;
        case "11":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"17EAE3"}
        }
          
        break;
        case "12":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"FC8BFF"}
        }
          
        break;

    default:
        break;
}
  
}


async function ExcelExport(taskdatas,cableData,download=true) {
  //
   // Créer un nouveau classeur Excel
    const workbook = new ExcelJS.Workbook();
    const worksheet = makehearderforexcel(workbook,taskdatas);
    workbook.creator ="Audexa";
    workbook.lastModifiedBy="Audexa";
    
    cableListe(workbook,cableData?.box?.cables);
    
    // Définir la largeur des colonnes
    const columnWidths = {
      A: 13,
      B: 24,
      C:13,
      D:24,
      E:13,
      F:12,
      H:12,
      I:12,
      G: 24,
      J:24,
      L: 24,
      M: 24,
    };
  
    for (const col in columnWidths) {
      worksheet.getColumn(col).width = columnWidths[col];
    }
  
    // Initialiser le numéro de ligne
    let rowNumber = 16;
  
    for (const data of taskdatas.cassettesData) {

      const faceCell = worksheet.getCell(`G${rowNumber}`);
     
      // Fusionner les cellules
      worksheet.mergeCells(`A${rowNumber}`, `M${rowNumber}`);

      if(data.cassetteType.toLowerCase().includes("cas") || data.cassetteType.toLowerCase().includes("fond de boite")){
        faceCell.value = data.cassetteType;
      }
      else{
        faceCell.value = "CAS "+data.cassetteType;
      }
      
      faceCell.alignment = { horizontal: 'center', vertical: 'middle' };
  
    
      const fibres = data.linesData;
      
      //ceci est un controle pour les cassette sans fibre

      if(fibres?.length==0){
       const vide_cell = worksheet.getCell(`G${rowNumber+1}`);
        worksheet.mergeCells(`A${rowNumber+1}`, `M${rowNumber+1}`);
        vide_cell.value = "  vide  ";
        vide_cell.alignment = { horizontal: 'center', vertical: 'middle' };
        rowNumber = rowNumber + 2;
        continue;
      }
      
      else{

      rowNumber = rowNumber + fibres?.length + 1;

      
      let position = 1;
      for (const fiber of fibres) {
          var fiberin = fiber.fiberDataReportIn;
          var fiberout = fiber.fiberDataReportOut;

          var row = worksheet.addRow([
            position,fiber.refBoxUpStream, fiberin?.cableCapacity, fiberin?.cableName,
            fiberin?.tubeNumber,fiberin?.fiberNumber, fiber.status,
            fiberout?.fiberNumber, fiberout?.tubeNumber,
             fiberout?.cableName,fiberout?.cableCapacity, fiber.refBoxDownStream,
          ]);

        position = position+1;

      //center all cell
        row.eachCell((cell) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
          });

   // Contrôler la couleur en fonction de la valeur de la cellule "fibre entrant"
        
   //recuperation de la bonne cellule et appel de la fonction generique pour appliquer la couleur

   if(fiberin!=null){
    const statusCellF = row.getCell('F'); 
    fiberColor(statusCellF,fiberin.fiberNumber)

    const statusCellE = row.getCell('E');
        statusCellE.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:fiberin.tubeColor}
        }
        if(["FF0000","0000FF","81007F","FF7F00","AFAFAF","B05121","000000"].includes(fiberin.tubeColor)){
            statusCellE.font={
                color: { argb: 'FFFFFF' }, 
            }
        }



   }
  
   if (fiberout!=null) {
    const statusCellH = row.getCell('H'); 
        fiberColor(statusCellH,fiberout.fiberNumber)

        const statusCellI = row.getCell('I');
        statusCellI.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:fiberout.tubeColor}
        }
        if(["FF0000","0000FF","81007F","FF7F00","AFAFAF","B05121","000000"].includes(fiberout.tubeColor)){
            statusCellI.font={
                color: { argb: 'FFFFFF' }, 
            }
        }

   }
                
      }
    }
    }
    
    // Générer le fichier Excel
    var blob ;
   await workbook.xlsx.writeBuffer().then((buffer) => {
      blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if(download){
      var   url = window.URL.createObjectURL(blob);
      var   a = document.createElement('a');
      a.href = url;
      a.download = cableData?.name+'.xlsx'; // Nom du fichier Excel
      a.click();
      window.URL.revokeObjectURL(url);
      }
      
    });
    const file = new File([blob], cableData?.name+'.xlsx', { type: blob.type });

    return file;
  }
  
 
  //************************** seconde feuille **************//
  function cableListe(workbook,cables){
 
    const worksheet = workbook.addWorksheet("Détails du contenant (Noeu...");
    make_header_To_seconde_sheet(worksheet);
    if (cables) {
      for (let cable of cables) {
        let row = worksheet.addRow([
          cable?.cableName,
          cable?.cableCapacity,
          cable?.cableModulo,
          cable?.cableCapacity/Number(cable?.cableModulo),
          cable?.manufacturerReference,
          cable?.manufacturingYear,
          cable?.installationYear,
          cable?.fciNumber
        ]);
        row.eachCell((cell) => {
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });
      }
    }
 
  }

  function make_header_To_seconde_sheet(worksheet,headerData){

    worksheet.mergeCells("B1:H1")
    let textcell1= worksheet.getCell('D1')
    textcell1.value="Contenant des cassettes"
    textcell1.alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getRow(1).height =35;
    textcell1.font={
     bold:true,
     size:18
   }

    setvalueToTitleCell(worksheet,"B2","Version du:")
   
    textcell1 = worksheet.getCell('C2')
    textcell1.value=new Date().toISOString().split('T')[0];
    

   setvalueToTitleCell(worksheet,"F2","Auteur")
   setvalueToTitleCell(worksheet,"F3","Auteur")
  
    
    setvalueToTitleCell(worksheet,"G2","a definir",false)
    setvalueToTitleCell(worksheet,"G3","a definir",false)

    worksheet.addRow( )
    cadreAndValue(worksheet)

    // cable Liste HeaderSection
    const headers = [
       "Code", "Capacité","Modulo",
      "Nb de tubes", "Fabricant","ANN. Fabric","ANN. Pose","NUM. FCI"];
    worksheet.addRow( )
    worksheet.addRow( )

   let row = worksheet.addRow(headers);
    row.eachCell((cell) => {
      cell.fill={
        type:'pattern',
        pattern:"solid",
        fgColor:{argb:"999999"}
    }
      cell.font={
        bold:true
      }
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });
    
    //Size collone width
    const columnWidths = {
      A: 24,
      B: 24,
      C:13,
      D:13,
      E:20,
      F:20,
      H:14,
      G: 14
    };
  
    for (const col in columnWidths) {
      worksheet.getColumn(col).width = columnWidths[col];
    }

  }
  
  function cadreAndValue(worksheet){

    borderallsection(worksheet,6,6,5,7)
    borderallsection(worksheet,10,14,9,15)
    borderallsection(worksheet,18,22,17,23)

    worksheet.mergeCells("B5:C5")
   setvalueToTitleCell(worksheet,"B5","Identification :")
   setvalueToTitleCell(worksheet,"B6","Nom :")
   setvalueToTitleCell(worksheet,"F6","Code :")

   worksheet.mergeCells("C6:E6")
   //let textcell1= worksheet.getCell('C6')
   setvalueToTitleCell(worksheet,'C6','a definir',false)

   worksheet.mergeCells("G6:I6")
   setvalueToTitleCell(worksheet,'G6','a definir',false)
   
   //second cadre 
   
   //worksheet.mergeCells("B11:C11")
   setvalueToTitleCell(worksheet,'B9','Situation')
 worksheet.mergeCells("E9:G9")
   setvalueToTitleCell(worksheet,'E9','Nom')
   worksheet.mergeCells("H9:J9")
   setvalueToTitleCell(worksheet,'H9','Code')
   
   worksheet.mergeCells("C11:D11")
   setvalueToTitleCell(worksheet,'C11','Point Technique')
 worksheet.mergeCells("E11:G11")
   setvalueToTitleCell(worksheet,'E11','a definir',false)

 worksheet.mergeCells("H11:I11")
   setvalueToTitleCell(worksheet,'H11','a definir',false)

   worksheet.mergeCells("E13:G13")
   setvalueToTitleCell(worksheet,'E13','Address')
worksheet.mergeCells("E14:G14")
   setvalueToTitleCell(worksheet,'E14','a definir',false)
  
   
   // troisieme section 
   worksheet.mergeCells("B17:C17")
   setvalueToTitleCell(worksheet,'B17','Caractéristiques générales')
   setvalueToTitleCell(worksheet,'B19','Type : ')
   setvalueToTitleCell(worksheet,'B20','Propriétaire :')
   setvalueToTitleCell(worksheet,'B21','Fabriquant :')
   setvalueToTitleCell(worksheet,'B22','Type structurel :')

   worksheet.mergeCells("C19:E19")
   setvalueToTitleCell(worksheet,'C19','a definir ',false)
worksheet.mergeCells("C20:E20")
   setvalueToTitleCell(worksheet,'C20','a definir',false)
worksheet.mergeCells("C21:E21")
   setvalueToTitleCell(worksheet,'C21','a definir',false)
worksheet.mergeCells("C22:E22")
   setvalueToTitleCell(worksheet,'C22','a definir',false)


   setvalueToTitleCell(worksheet,'F20','Gestionnaire :')
   setvalueToTitleCell(worksheet,'F21','Référence :')
   setvalueToTitleCell(worksheet,'F22','Type fonctionnel :')

   worksheet.mergeCells("G20:I20")
   setvalueToTitleCell(worksheet,'G20','a definir',false)
worksheet.mergeCells("G21:I21")
   setvalueToTitleCell(worksheet,'G21','a definir',false)
worksheet.mergeCells("G22:I22")
   setvalueToTitleCell(worksheet,'G22','a definir',false)

  }

  function setvalueToTitleCell(worksheet,cell,values,isbold=true){
    let textcell1= worksheet.getCell(cell)
    textcell1.value=values
    textcell1.alignment = {vertical: 'middle',horizontal: 'center', };
    if(isbold){
      textcell1.font={
        bold:true
      }
    }
    
  }

  function borderallsection(worksheet,lateraldebut,latteralfin,toprownumber,bottomrownumber){
  
    const rangeLine = ["B","C","D","E","F","G","H","I","J"]
   //la ligne du haut plus les bordures de coin
     for (let line of rangeLine){
   
       let cell =worksheet.getCell(line+toprownumber)
       
       if(line+toprownumber=="B"+toprownumber){
   
         cell.border={
           top: {style:'medium', color: {argb:'00000000'}},
           left:{style:'medium'}
         }
   
         }
         else if(line+toprownumber=="J"+toprownumber){
           cell.border={
             top: {style:'medium', color: {argb:'00000000'}},
             right:{style:'medium'}
           }
      }
         else{
           cell.border={
             top: {style:'medium', color: {argb:'00000000'}},
           }
         }
       }
       
       
   //ligne du bas plus bordure du coins
       for (let line of rangeLine){
       let cell =worksheet.getCell(line+bottomrownumber)
       if(line+bottomrownumber=="B"+bottomrownumber){
   
         cell.border={
           left: {style:'medium', color: {argb:'00000000'}},
           bottom:{style:'medium'}
         }
   
         }
         else if(line+bottomrownumber=="J"+bottomrownumber){
           cell.border={
             right: {style:'medium', color: {argb:'00000000'}},
             bottom:{style:'medium'}
           }
      }
         else{
           cell.border={
             bottom: {style:'medium', color: {argb:'00000000'}},
           }
         }
       }
     
       //les bars latteral sans les coins
       for (let i =lateraldebut;i<=latteralfin;i++){
       let cell =worksheet.getCell("B"+i)
       cell.border={
         left: {style:'medium', color: {argb:'00000000'}},
       }
   
       cell =worksheet.getCell("J"+i)
       cell.border={
         right: {style:'medium', color: {argb:'00000000'}},
       }
       }
     
   }
  //**************************


export default ExcelExport;
