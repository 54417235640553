import {Link, useParams} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import {getImgAfterAuditById} from "../../../../../api/audits/auditsApi";
import React, {useContext, useState} from "react";
import {Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import audexaLogo from "../../../../../assets/login_page_assets/images/xnoir.png";
import EasyCropImage from "../../../../../Components/Common/CropImage/EasyCropImage";
import TaskContext from "../../../hook/TaskContext";
import {Card, Checkbox, Segmented} from "antd";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {Grow} from "transitions-kit";
import {AsyncImage} from "loadable-image";
import {EditOutlined, EllipsisOutlined, SettingOutlined} from "@ant-design/icons";
const { Meta } = Card
const Medias = () => {
    const {boxImages} = useContext(TaskContext)
    const params = useParams();
    const [value, setValue] = useState('Images avant audit');
    const {
        data: imgAfterAudit,
    } = useQuery(["imgAfterAudit", params.id], () => getImgAfterAuditById(params.id));

    const downloadImage = (item) => {
        if (item.img) {
            saveAs(`${process.env.REACT_APP_API_IMG_URL}/${item?.img}`, `${item.name}`); // Put your image URL here.
        }
    };

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };


    return (
        <>
            <div className="d-flex">
                <Segmented options={['Images avant audit', 'Images après audit']} value={value} onChange={setValue} />
                <Checkbox onChange={onChange}></Checkbox>
            </div>
            <SimpleBar style={{ maxHeight: "440px" }} className="px-3">
                {value === 'Images avant audit' && (
                    <Row>
                        {boxImages && boxImages.map((item, index) => {
                            return [
                                <Col xxl={2} key={index}>
                                    <Card
                                        style={{
                                            width: 300,
                                        }}
                                        cover={
                                            <img
                                                alt="example"
                                                src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                                            />
                                        }
                                        actions={[
                                            <SettingOutlined key="setting" />,
                                            <EditOutlined key="edit" />,
                                            <EllipsisOutlined key="ellipsis" />,
                                        ]}
                                    >
                                        <Meta
                                            title="Card title"
                                            description="This is the description"
                                        />
                                    </Card>
                                    {/*<div className="swiper-wrapper">*/}
                                    {/*    <div*/}
                                    {/*        className="gallery-box card"*/}
                                    {/*        style={{ height: "160px" }}*/}
                                    {/*    >*/}
                                    {/*        <div className="gallery-container">*/}
                                    {/*            <div*/}
                                    {/*                className="form-check form-check-danger fs-15 d-flex justify-content-end"*/}
                                    {/*                style={{*/}
                                    {/*                    position: "absolute",*/}
                                    {/*                    zIndex: "1000",*/}
                                    {/*                    marginTop: "2px",*/}
                                    {/*                }}*/}
                                    {/*            >*/}
                                    {/*                <input*/}
                                    {/*                    onChange={() => selectCheckbox()}*/}
                                    {/*                    className="imgCheckBox form-check-input"*/}
                                    {/*                    type="checkbox"*/}
                                    {/*                    value={item.img}*/}
                                    {/*                    id={"folderlistCheckbox_" + item.img}*/}
                                    {/*                />*/}
                                    {/*                <label*/}
                                    {/*                    className="form-check-label"*/}
                                    {/*                    htmlFor={"folderlistCheckbox_" + item.img}*/}
                                    {/*                ></label>*/}
                                    {/*            </div>*/}
                                    {/*            <Link*/}
                                    {/*                className="image-popup"*/}
                                    {/*                to={item.img ? `${process.env.REACT_APP_API_IMG_URL}/${item?.img}` : item.img}*/}
                                    {/*                target="_blanc"*/}
                                    {/*            >*/}
                                    {/*                <AsyncImage*/}
                                    {/*                    // rootMargin='600px'*/}
                                    {/*                    key={index}*/}
                                    {/*                    src={`${process.env.REACT_APP_API_IMG_URL}/${item?.img}`}*/}
                                    {/*                    style={{ width: 200, height: 100, borderRadius: 3 }}*/}
                                    {/*                    loader={<div style={{ background: '#888' }} />}*/}
                                    {/*                    Transition={Grow}*/}
                                    {/*                />*/}
                                    {/*                <div className="gallery-overlay">*/}
                                    {/*                    <h5 className="overlay-caption">*/}
                                    {/*                        {item.name}*/}
                                    {/*                    </h5>*/}
                                    {/*                </div>*/}
                                    {/*            </Link>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="box-content">*/}
                                    {/*            <div className="d-flex align-items-center mt-1">*/}
                                    {/*                <div className="flex-shrink-0">*/}
                                    {/*                    <div className="d-flex gap-3">*/}
                                    {/*                        <button*/}
                                    {/*                            type="button"*/}
                                    {/*                            className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"*/}
                                    {/*                            onClick={() => toggleModal(item)}*/}
                                    {/*                        >*/}
                                    {/*                            <i className="ri-crop-line text-muted align-bottom me-1"></i>*/}
                                    {/*                        </button>*/}
                                    {/*                        <button*/}
                                    {/*                            type="button"*/}
                                    {/*                            className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"*/}
                                    {/*                            onClick={() => downloadImage(item)}*/}
                                    {/*                        >*/}
                                    {/*                            <i className="ri-download-fill text-muted align-bottom me-1"></i>*/}
                                    {/*                        </button>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </Col>,
                            ];
                        })}
                    </Row>
                )}
                {value === 'Images après audit' && (
                    <Row>
                        {imgAfterAudit && imgAfterAudit.map((item, index) => {
                            return [
                                <Col lg={2} md={4} key={index}>
                                    <div className="swiper-wrapper">
                                        <div
                                            className="gallery-box card"
                                            style={{ height: "160px" }}
                                        >
                                            <div className="gallery-container">
                                                <div
                                                    className="form-check form-check-danger fs-15 d-flex justify-content-end"
                                                    style={{
                                                        position: "absolute",
                                                        zIndex: "1000",
                                                        marginTop: "2px",
                                                    }}
                                                >
                                                    <input
                                                        onChange={() => selectCheckbox()}
                                                        className="imgCheckBox2 form-check-input"
                                                        type="checkbox"
                                                        value={item.img}
                                                        id={"folderlistCheckbox_" + item.img}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={"folderlistCheckbox_" + item.img}
                                                    ></label>
                                                </div>
                                                <Link
                                                    className="image-popup"
                                                    to={item.img ? `${process.env.REACT_APP_API_IMG_URL}/${item?.img}` : null}
                                                    target="_blanc"
                                                >
                                                    <LazyLoadImage
                                                        className="gallery-img img-fluid mx-auto"
                                                        effect="blur"
                                                        src={item.img ? `${process.env.REACT_APP_API_IMG_URL}/${item?.img}` : null}
                                                        alt="img_name"
                                                        placeholderSrc={audexaLogo}
                                                        height={100}
                                                    />
                                                    <div className="gallery-overlay">
                                                        <h5 className="overlay-caption">
                                                            Image After
                                                        </h5>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="box-content">
                                                <div className="d-flex align-items-center mt-1">
                                                    <div className="flex-shrink-0">
                                                        <div className="d-flex gap-3">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"
                                                                onClick={() => toggleModal(item)}
                                                            >
                                                                <i className="ri-crop-line text-muted align-bottom me-1"></i>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"
                                                                onClick={() => downloadImage(item)}
                                                            >
                                                                <i className="ri-download-fill text-muted align-bottom me-1"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>,
                            ];
                        })}
                    </Row>
                )}
            </SimpleBar>
        </>
    )
    // const {boxImages} = useContext(TaskContext)
    // const params = useParams();
    // //Get Audit Details
    // const {
    //     isLoading,
    //     data: imgAfterAudit,
    // } = useQuery(["imgAfterAudit", params.id], () => getImgAfterAuditById(params.id));
    //
    // const downloadImage = (item) => {
    //     if (item.img) {
    //         saveAs(`${process.env.REACT_APP_API_IMG_URL}/${item?.img}`, `${item.name}`); // Put your image URL here.
    //     }
    // };
    //
    // const [modalImage, setModalImage] = useState(false);
    // const [image, setImage] = useState(null);
    // function toggleModal(item) {
    //     setImage({img: item?.img, name: item?.name});
    //     setModalImage(!modalImage);
    // }
    //
    // // Custom Vertical Tabs
    // const [customverticalTab, setcustomverticalTab] = useState("1");
    // const customtoggleVertical = (tab) => {
    //     if (customverticalTab !== tab) {
    //         setcustomverticalTab(tab);
    //     }
    // };

    // return (
    //     <React.Fragment>
    //         <Row>
    //             <Col lg={12}>
    //                 <Nav tabs className="nav-tabs mb-3">
    //                     <NavItem>
    //                         <NavLink
    //                             style={{ cursor: "pointer" }}
    //                             className={classnames({ active: customverticalTab === "1" })}
    //                             onClick={() => {
    //                                 customtoggleVertical("1");
    //                             }}
    //                         >
    //                             <div className="d-flex">
    //                                 <span className="me-1">Avant</span>
    //                                 {customverticalTab === "1" && (
    //                                     <div className="form-check form-check-danger fs-15">
    //                                         <input
    //                                             onClick={() => checkedAll()}
    //                                             className="form-check-input"
    //                                             id="checkBoxAll"
    //                                             type="checkbox"
    //                                         />
    //                                         <label
    //                                             className="form-check-label"
    //                                             htmlFor="checkBoxAll"
    //                                         ></label>
    //                                     </div>
    //                                 )}
    //                             </div>
    //                         </NavLink>
    //                     </NavItem>
    //                     <NavItem>
    //                         <NavLink
    //                             style={{ cursor: "pointer" }}
    //                             className={classnames({ active: customverticalTab === "2" })}
    //                             onClick={() => {
    //                                 customtoggleVertical("2");
    //                             }}
    //                         >
    //                             <div className="d-flex">
    //                                 <span className="me-1">Après</span>
    //                                 {customverticalTab === "2" && (
    //                                     <div className="form-check form-check-danger fs-15">
    //                                         <input
    //                                             onClick={() => checkedAll2()}
    //                                             className="form-check-input"
    //                                             id="checkBoxAll2"
    //                                             type="checkbox"
    //                                         />
    //                                         <label
    //                                             className="form-check-label"
    //                                             htmlFor="checkBoxAll2"
    //                                         ></label>
    //                                     </div>
    //                                 )}
    //                             </div>
    //                         </NavLink>
    //                     </NavItem>
    //                 </Nav>
    //                 <TabContent activeTab={customverticalTab} className="text-muted">
    //                     <TabPane tabId="1" id="before">
    //                         <SimpleBar style={{ maxHeight: "440px" }} className="px-3">
    //                             <Row>
    //                                 {boxImages && boxImages.map((item, index) => {
    //                                     return [
    //                                         <Col lg={2} md={4} key={index}>
    //                                             <div className="swiper-wrapper">
    //                                                 <div
    //                                                     className="gallery-box card"
    //                                                     style={{ height: "160px" }}
    //                                                 >
    //                                                     <div className="gallery-container">
    //                                                         <div
    //                                                             className="form-check form-check-danger fs-15 d-flex justify-content-end"
    //                                                             style={{
    //                                                                 position: "absolute",
    //                                                                 zIndex: "1000",
    //                                                                 marginTop: "2px",
    //                                                             }}
    //                                                         >
    //                                                             <input
    //                                                                 onChange={() => selectCheckbox()}
    //                                                                 className="imgCheckBox form-check-input"
    //                                                                 type="checkbox"
    //                                                                 value={item.img}
    //                                                                 id={"folderlistCheckbox_" + item.img}
    //                                                             />
    //                                                             <label
    //                                                                 className="form-check-label"
    //                                                                 htmlFor={"folderlistCheckbox_" + item.img}
    //                                                             ></label>
    //                                                         </div>
    //                                                         <Link
    //                                                             className="image-popup"
    //                                                             to={item.img ? `${process.env.REACT_APP_API_IMG_URL}/${item?.img}` : item.img}
    //                                                             target="_blanc"
    //                                                         >
    //                                                             <LazyLoadImage
    //                                                                 className="gallery-img img-fluid mx-auto"
    //                                                                 effect="blur"
    //                                                                 src={item.img ? `${process.env.REACT_APP_API_IMG_URL}/${item?.img}` : item.img}
    //                                                                 alt="img_name"
    //                                                                 placeholderSrc={audexaLogo}
    //                                                                 height={100}
    //                                                             />
    //                                                             <div className="gallery-overlay">
    //                                                                 <h5 className="overlay-caption">
    //                                                                     {item.name}
    //                                                                 </h5>
    //                                                             </div>
    //                                                         </Link>
    //                                                     </div>
    //                                                     <div className="box-content">
    //                                                         <div className="d-flex align-items-center mt-1">
    //                                                             <div className="flex-shrink-0">
    //                                                                 <div className="d-flex gap-3">
    //                                                                     <button
    //                                                                         type="button"
    //                                                                         className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"
    //                                                                         onClick={() => toggleModal(item)}
    //                                                                     >
    //                                                                         <i className="ri-crop-line text-muted align-bottom me-1"></i>
    //                                                                     </button>
    //                                                                     <button
    //                                                                         type="button"
    //                                                                         className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"
    //                                                                         onClick={() => downloadImage(item)}
    //                                                                     >
    //                                                                         <i className="ri-download-fill text-muted align-bottom me-1"></i>
    //                                                                     </button>
    //                                                                 </div>
    //                                                             </div>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                         </Col>,
    //                                     ];
    //                                 })}
    //                             </Row>
    //                         </SimpleBar>
    //                     </TabPane>
    //                     <TabPane tabId="2" id="after">
    //                         {isLoading ? (
    //                             <div className="d-flex justify-content-center align-items-center">
    //                                 <Spinner color="danger">Loading...</Spinner>
    //                             </div>
    //                         ) : (
    //                             <SimpleBar style={{ maxHeight: "440px" }} className="px-3">
    //                                 <Row>
    //                                     {imgAfterAudit && imgAfterAudit.map((item, index) => {
    //                                         return [
    //                                             <Col lg={2} md={4} key={index}>
    //                                                 <div className="swiper-wrapper">
    //                                                     <div
    //                                                         className="gallery-box card"
    //                                                         style={{ height: "160px" }}
    //                                                     >
    //                                                         <div className="gallery-container">
    //                                                             <div
    //                                                                 className="form-check form-check-danger fs-15 d-flex justify-content-end"
    //                                                                 style={{
    //                                                                     position: "absolute",
    //                                                                     zIndex: "1000",
    //                                                                     marginTop: "2px",
    //                                                                 }}
    //                                                             >
    //                                                                 <input
    //                                                                     onChange={() => selectCheckbox()}
    //                                                                     className="imgCheckBox2 form-check-input"
    //                                                                     type="checkbox"
    //                                                                     value={item.img}
    //                                                                     id={"folderlistCheckbox_" + item.img}
    //                                                                 />
    //                                                                 <label
    //                                                                     className="form-check-label"
    //                                                                     htmlFor={"folderlistCheckbox_" + item.img}
    //                                                                 ></label>
    //                                                             </div>
    //                                                             <Link
    //                                                                 className="image-popup"
    //                                                                 to={item.img ? `${process.env.REACT_APP_API_IMG_URL}/${item?.img}` : null}
    //                                                                 target="_blanc"
    //                                                             >
    //                                                                 <LazyLoadImage
    //                                                                     className="gallery-img img-fluid mx-auto"
    //                                                                     effect="blur"
    //                                                                     src={item.img ? `${process.env.REACT_APP_API_IMG_URL}/${item?.img}` : null}
    //                                                                     alt="img_name"
    //                                                                     placeholderSrc={audexaLogo}
    //                                                                     height={100}
    //                                                                 />
    //                                                                 <div className="gallery-overlay">
    //                                                                     <h5 className="overlay-caption">
    //                                                                         Image After
    //                                                                     </h5>
    //                                                                 </div>
    //                                                             </Link>
    //                                                         </div>
    //                                                         <div className="box-content">
    //                                                             <div className="d-flex align-items-center mt-1">
    //                                                                 <div className="flex-shrink-0">
    //                                                                     <div className="d-flex gap-3">
    //                                                                         <button
    //                                                                             type="button"
    //                                                                             className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"
    //                                                                             onClick={() => toggleModal(item)}
    //                                                                         >
    //                                                                             <i className="ri-crop-line text-muted align-bottom me-1"></i>
    //                                                                         </button>
    //                                                                         <button
    //                                                                             type="button"
    //                                                                             className="btn btn-sm fs-15 btn-link text-body text-decoration-none px-0"
    //                                                                             onClick={() => downloadImage(item)}
    //                                                                         >
    //                                                                             <i className="ri-download-fill text-muted align-bottom me-1"></i>
    //                                                                         </button>
    //                                                                     </div>
    //                                                                 </div>
    //                                                             </div>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </Col>,
    //                                         ];
    //                                     })}
    //                                 </Row>
    //                             </SimpleBar>
    //                         )}
    //                     </TabPane>
    //                 </TabContent>
    //             </Col>
    //         </Row>
    //         <Modal
    //             id="imageModal"
    //             className="modal-xl"
    //             isOpen={modalImage}
    //             toggle={() => {
    //                 toggleModal();
    //             }}
    //             backdrop={"static"}
    //         >
    //             <ModalHeader
    //                 className="modal-title"
    //                 id="imageModal"
    //                 toggle={() => {
    //                     toggleModal();
    //                 }}
    //             ></ModalHeader>
    //             <ModalBody>
    //                 <EasyCropImage
    //                     item={image}
    //                 />
    //             </ModalBody>
    //         </Modal>
    //     </React.Fragment>
    // );
}

export default Medias;