import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Card, Descriptions} from "antd";
import React, {useState} from "react";
import {DeleteOutlined, EditOutlined, SaveOutlined, SettingOutlined} from "@ant-design/icons";
import {Grow} from "transitions-kit";
import {AsyncImage} from "loadable-image";
import {Link} from "react-router-dom";

const ShowCableModal = ({toggleQcmModal, showQcmMdl, qcm}) => {

    return (
        <Modal
            size="xl"
            id="qcmModal"
            // className="modal-fullscreen"
            isOpen={showQcmMdl}
            toggle={() => {
                toggleQcmModal();
            }}
            backdrop={"static"}
        >
            <ModalHeader
                className="modal-title"
                id="qcmModal"
                toggle={() => {
                    toggleQcmModal();
                }}
            ></ModalHeader>
            <ModalBody>
                <Card
                    // extra={[
                    //     <a className="me-4"><EditOutlined key="edit"/></a>,
                    //     <a className="me-4"><DeleteOutlined key="delete"/></a>
                    // ]}
                >
                    <Card.Grid style={{width: '70%', textAlign: 'center'}}>
                        <AsyncImage
                            src={`${process.env.REACT_APP_API_IMG_URL}/${qcm?.imgUrl}`}
                            style={{ width: '100%', height: 600, borderRadius: 3 }}
                            loader={<div style={{ background: '#888' }} />}
                            Transition={Grow}
                        />
                    </Card.Grid>
                    <Card.Grid style={{width: '30%',}}>
                        <ul className="ps-3 text-muted">
                            <li className="py-2">
                                <Link to="#" className="text-muted">
                                    {'Ordre:'}{" "}
                                    <span className="float-end">{qcm?.order}</span>
                                </Link>
                            </li>
                            <li className="py-2">
                                <Link to="#" className="text-muted">
                                    {'Question:'}{" "}
                                    <span className="float-end">{qcm?.question}</span>
                                </Link>
                            </li>
                            <li className="py-2">
                                <Link to="#" className="text-muted">
                                    {'Réponse:'}{" "}
                                    <span className="float-end">{qcm?.qcmResponse}</span>
                                </Link>
                            </li>
                        </ul>
                    </Card.Grid>
                </Card>
            </ModalBody>
        </Modal>
    )
}

export default ShowCableModal;