import React, { useEffect, useState } from "react";
import { Navigate, Route } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import useNetworkStatus from "../Components/Hooks/useNetworkStatus";

const AuthProtected = (props) => {
    // const isOnline = useNetworkStatus();
    let currentUser;
    if (sessionStorage.getItem('authUser')) {
        currentUser = JSON.parse(sessionStorage.getItem('authUser'));
    }

    if (currentUser) {
        if (jwtDecode(currentUser.token).exp > Date.now() / 1000) {
            if (
                currentUser.roles.includes('ROLE_ADMIN') ||
                currentUser.roles.includes('ROLE_SUPER_ADMIN') ||
                currentUser.roles.includes('ROLE_BACK_OFFICE_MANAGER')) {
                return <>{props.children}</>
            } else {
                return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
            }
        } else {
            return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
        }
    } else {
        return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    }
  /*
    Navigate is un-auth access protected routes via url
    */
    // if (!sessionStorage.getItem('authUser')) {
    //     return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    // }
    //
    // if (sessionStorage.getItem('authUser')) {
    //   if (jwtDecode(JSON.parse(sessionStorage.getItem('authUser')).token).exp < Date.now() / 1000) {
    //     return (
    //         <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    //     );
    //   }
    // }

    // if (isOnline) {
    //     return <Navigate to={{ pathname: "/offline", state: { from: props.location } }} />
    // }

    // return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };