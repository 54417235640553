import { Link } from "react-router-dom";
import { Card, CardBody, Col, Table } from "reactstrap";
import { UserRoleFormat } from "../../../helpers/role_format";
import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";

const SideCardInfo = ({info, setInfo}) => {

    const handleCloseView = () => {
        setInfo(null);
    };

    return (
        <Col xxl={3}>
            <Card id="contact-view-detail">
                <CardBody className="text-center">
                <div className="d-flex justify-content-end">
                    <button onClick={handleCloseView} className="btn btn-outline">
                    <i className="ri-close-line"></i>
                    </button>
                </div>
                <div className="position-relative d-inline-block">
                    <img
                    src={`${
                        info.userImgUrl
                        ? process.env.REACT_APP_API_IMG_URL +
                            "/" +
                            info.userImgUrl
                        : dummyImg
                    }`}
                    alt=""
                    className="avatar-lg rounded-circle img-thumbnail"
                    />
                    <span className="contact-active position-absolute rounded-circle bg-success">
                    <span className="visually-hidden"></span>
                    </span>
                </div>
                <h5 className="mt-4 mb-1">
                    {info.firstname || "XXX"} {info.lastname || "XXX"}
                </h5>
                <p className="text-muted">{UserRoleFormat(info.userRole) || "XXX"}</p>

                <ul className="list-inline mb-0">
                    <li className="list-inline-item avatar-xs">
                    <Link
                        to="#"
                        className="avatar-title bg-success-subtle text-success fs-15 rounded"
                    >
                        <i className="ri-phone-line"></i>
                    </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                    <Link
                        to="#"
                        className="avatar-title bg-danger-subtle text-danger fs-15 rounded"
                    >
                        <i className="ri-mail-line"></i>
                    </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                    <Link
                        to={`/profile/${info.userId}`}
                        className="avatar-title bg-warning-subtle text-warning fs-15 rounded"
                    >
                        <i className="ri-eye-line"></i>
                    </Link>
                    </li>
                </ul>
                </CardBody>
                <CardBody>
                <h6 className="text-muted text-uppercase fw-semibold mb-3">
                    Informations Personnelles
                </h6>
                {/* <p className="text-muted mb-4">
                            Hello, I'm {info.firstname || "Tonya Noble"}, The most effective objective is one
                            that is tailored to the job you are applying for.
                            </p> */}
                <div className="table-responsive table-card">
                    <Table className="table table-borderless mb-0">
                    <tbody>
                        <tr>
                        <td className="fw-medium">Nom Utilisateur</td>
                        <td>{info.username || "@username"}</td>
                        </tr>
                        <tr>
                        <td className="fw-medium">Email</td>
                        <td>{info.email || "example@mail.com"}</td>
                        </tr>
                        <tr>
                        <td className="fw-medium">Téléphone</td>
                        <td>{info.phone || "000-0000-0000"}</td>
                        </tr>
                        <tr>
                        <td className="fw-medium">Nombre d'Audit</td>
                        <td>
                            <span className="badge bg-primary-subtle text-primary me-1">
                            {info.auditTasks?.length || 0}
                            </span>
                        </td>
                        </tr>
                        <tr>
                        <td className="fw-medium">Compte</td>
                        <td>
                            {info.enabled ? (
                            <span className="badge bg-primary-subtle text-success me-1">
                                Active
                            </span>
                            ) : (
                            <span className="badge bg-primary-subtle text-danger me-1">
                                Desactive
                            </span>
                            )}
                        </td>
                        {/* <td>
                                    {handleValidDate(info.last_contacted || "2021-04-13T18:30:00.000Z")}{" "}
                                    <small className="text-muted">{handleValidTime(info.last_contacted || "2021-04-13T18:30:00.000Z")}</small>
                                    </td> */}
                        </tr>
                    </tbody>
                    </Table>
                </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default SideCardInfo;
