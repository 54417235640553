import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Carousel} from "react-responsive-carousel";
import EasyCropImage from "../../../../../../Components/Common/CropImage/EasyCropImage";
import InlineInputEditCable from "./InlineEditCable/InlineEditCable";
import {Card} from "antd";
import React, {useState} from "react";
import {CloseCircleOutlined, DeleteOutlined, EditOutlined, SaveOutlined, SettingOutlined} from "@ant-design/icons";

const ShowCableModal = ({toggleModal, showCableMdl, cables, status, activeIndex}) => {

    const [isEdit, setIsEdit] = useState(false);
    const onEdit = () => {
        setIsEdit(!isEdit)
    }

    return (
        <Modal
            size="xl"
            id="cableModal"
            // className="modal-fullscreen"
            isOpen={showCableMdl}
            toggle={() => {
                toggleModal();
            }}
            backdrop={"static"}
        >
        <ModalHeader
            className="modal-title"
            id="cableModal"
            toggle={() => {
                toggleModal();
            }}
        ></ModalHeader>
        <ModalBody>
            <Carousel
                showThumbs={false}
                showIndicators={false}
                selectedItem={activeIndex}
            >
                {(cables || []).map((item, index) => {
                    const cableImg = {img: item.cableImageUrl, name: item.cableImageUrl}
                    return (
                        <Card
                            title={`${item?.cableName}`}
                            extra={[
                                <>{status !== 'Validated' ? (!isEdit ? (<a onClick={onEdit} className="me-4"><EditOutlined key="edit"/></a>) : (
                                    <a onClick={onEdit} className="me-4"><CloseCircleOutlined key="close" /></a>)) : null}</>,
                                // <a className="me-4"><EditOutlined key="edit"/></a>,
                                // <a className="me-4"><DeleteOutlined key="delete"/></a>
                            ]}
                        >
                            <Card.Grid style={{width: '70%', textAlign: 'center'}}>
                                <EasyCropImage
                                    status={status}
                                    key={index}
                                    item={cableImg}
                                    content={<InlineInputEditCable cable={item} />}
                                />
                            </Card.Grid>
                            <Card.Grid style={{width: '30%', textAlign: 'center'}}>
                                <InlineInputEditCable
                                    cable={item}
                                    onEdit={onEdit}
                                    isEdit={isEdit}
                                    setIsEdit={setIsEdit}
                                />
                            </Card.Grid>
                        </Card>
                    );
                })}
            </Carousel>
        </ModalBody>
    </Modal>
    )
}

export default ShowCableModal;