export const message = {
    LOGIN_SUCCESSFULLY: 'Connexion réussie',
    UNAUTHORIZED: "Vous n'êtes pas autorisé à accéder à cette application!",
    NOT_FOUND: "Données non disponibles!",
    //entreprise
    CLIENT_CREATED_SUCCESSFULLY: "Entreprise créée avec succès!",
    CLIENT_CREATED_UNSUCCESSFULLY: "Impossible de créer l'entreprise, veuillez vérifier les informations!",
    CLIENT_DELETE_SUCCESSFULLY: "Entreprise supprimée avec succès!",
    CLIENT_DELETE_UNSUCCESSFULLY: "Impossible de supprimer, l'entreprise est peut être liée à un audit!",
    CLIENT_UPDATED_SUCCESSFULLY: "Entreprise modifiée avec succès!",
    CLIENT_UPDATED_UNSUCCESSFULLY: "Impossible de modifier les informations l'entreprise!",
    //User
    USER_CREATED_SUCCESSFULLY: "Utilisateur créé avec succès!",
    USER_CREATED_UNSUCCESSFULLY: "Impossible de créer l'utilisateur, veuillez vérifier les informations!",
    USER_DELETE_SUCCESSFULLY: "Utilisateur supprimé avec succès!",
    USER_DELETE_UNSUCCESSFULLY: "Impossible de supprimer, l'utilisateur est peut être lié à un audit!",
    USER_UPDATED_SUCCESSFULLY: "Utilisateur modifié avec succès!",
    USER_UPDATED_UNSUCCESSFULLY: "Impossible de modifier les informations l'utilisateur!",
    //Audit
    AUDIT_CREATED_SUCCESSFULLY: "Audit créé avec succès!",
    AUDIT_CREATED_UNSUCCESSFULLY: "Impossible de créer l'audit, veuillez vérifier les informations!",
    AUDIT_DELETE_SUCCESSFULLY: "Audit supprimé avec succès!",
    AUDIT_DELETE_UNSUCCESSFULLY: "Impossible de supprimer, l'audit est peut être lié à un audit!",
    AUDIT_UPDATED_SUCCESSFULLY: "Audit modifié avec succès!",
    AUDIT_UPDATED_UNSUCCESSFULLY: "Impossible de modifier les informations de l'audit!",
    AUDIT_ASSIGNATION_DELETED_SUCCESSFULLY: "Assignation supprimés avec succès!",
    AUDIT_ASSIGNATION_ENTERPRISE_SUCCESSFULLY: "Audit assigné à l'entreprise avec succès!",
    AUDIT_ASSIGNATION_PROJECT_SUCCESSFULLY: "Audit ajouté au projet avec succès!",

}