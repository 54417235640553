import React, { useEffect, useRef, useState } from "react";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
  Link,
  PDFDownloadLink,
  pdf
} from "@react-pdf/renderer";
import Pdfbuilder from "./Pdfbuilder";
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});


const NewPdfExport = async (data) => {
  const blob = await pdf(<Pdfbuilder data={data} />).toBlob();
  // Créez un lien de téléchargement
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = data.taskName+".pdf";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
  // const file = new File([blob],data.taskName+".pdf", { type: blob.type });
  // return file;
};

export default NewPdfExport;
