import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import {
  addCassetteLine,
  deleteCassetteLine,
  updateCassetteLine,
} from "../../../../../api/audits/auditsApi";
import toast from "react-hot-toast";

const BoxBottomLineForm = ({
  lineData,
  handleDelete,
  cassetteId,
  isEdit,
  setIsEdit,
  cables,
  setLines
}) => {
  const colors = {
    1: "FF0000",
    2: "0000FF",
    3: "18F10E",
    4: "FFFF00",
    5: "81007F",
    6: "FFFFFF",
    7: "FF7F00",
    8: "AFAFAF",
    9: "B05121",
    10: "000000",
    11: "17EAE3",
    12: "FC8BFF",
  };
  function getValueByKey(object, row) {
    return object[row];
  }
  const queryClient = useQueryClient();
  const addCassetteLineMutation = useMutation(addCassetteLine, {
    onSuccess: (data) => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("cassettes");
      toast.success("Ligne de cassette ajoutée avec succès!");
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  const updateCassetteLineMutation = useMutation(updateCassetteLine, {
    onSuccess: () => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("cassettes");
      toast.success("Cassette modifiée avec succès!");
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  const deleteCassetteLineMutation = useMutation(deleteCassetteLine, {
    onSuccess: () => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("cassettes");
      toast.success("Ligne de cassette supprimée avec succès!");
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      idCableIn: (lineData && lineData.idCableIn) || "",
      idCableOut: (lineData && lineData.idCableOut) || "",
      tubeInEnd: (lineData && lineData.tubeInEnd) || "",
      tubeInStart: (lineData && lineData.tubeInStart) || "",
      tubeOutEnd: (lineData && lineData.tubeOutEnd) || "",
      tubeOutStart: (lineData && lineData.tubeOutStart) || "",
      type: (lineData && lineData.type) || "",
    },
    validationSchema: Yup.object({
      //ringIn: Yup.number().required().positive().integer(),
      //ringOut: Yup.number().required().positive().integer(),
    }),

    onSubmit: (values) => {
      const {
        idCableIn,
        idCableOut,
        tubeInEnd,
        tubeInStart,
        tubeOutEnd,
        tubeOutStart,
        type,
      } = validation.values;
      const data = {
        // formData: {
        //   idLine: lineData?.lineId,
        //   idCableIn: idCableIn,
        //   tubeIn: {
        //     tubeNumber: tubeInNumber,
        //     tubeColor: getValueByKey(colors, tubeInNumber),
        //     ring: ringIn,
        //   },
        //   fiberInStart: fiberInStart,
        //   fiberInEnd: fiberInEnd,
        //   idCableOut: idCableOut,
        //   tubeOut: {
        //     tubeNumber: tubeOutNumber,
        //     tubeColor: getValueByKey(colors, tubeOutNumber),
        //     ring: ringOut,
        //   },
        //   fiberOutStart: fiberOutStart,
        //   fiberOutEnd: fiberOutEnd,
        //   type: type,
        // },
        // idCassette: cassetteId,
        // idLine: lineData?.lineId,
      };
      if (!isEdit) {
        addCassetteLineMutation.mutate(data);
        setIsEdit(true);
      } else {
        updateCassetteLineMutation.mutate(data);
        setIsEdit(true);
      }
    },
  });

  const TypesSoudures = [
    { label: "STOCKEE", value: "STOCKEE" },
    { label: "EPISSUREE", value: "EPISSUREE" },
    { label: "PASSAGE", value: "PASSAGE" },
  ];

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody className="bg-light-subtle">
            <Form
              id={lineData.lineId}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="g-3 d-flex justify-content-around">
                <Col xxl={1} sm={1}>
                  <select
                    name="idCableIn"
                    id="cableIn_id"
                    className="form-select border-dashed"
                    aria-label="Default select example"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.idCableIn || ""}
                  >
                    <option>Cables IN</option>
                    {cables.map(({ cableName, cableId }, index) => (
                      <option key={index} value={cableId}>
                        {cableName}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col xxl={1} sm={1}>
                  <Input
                    name="tubeInStart"
                    type="text"
                    className="form-control border-dashed"
                    id="tubeInStart_id"
                    placeholder="Tube Ent. Debut"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.tubeInStart}
                  />
                </Col>
                <Col xxl={1} sm={1}>
                  <Input
                    name="tubeInEnd"
                    type="text"
                    min = "0"
                    className="form-control border-dashed"
                    id="tubeInEnd_id"
                    placeholder="Tube Ent. Fin"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.tubeInEnd}
                  />
                </Col>
                <Col xxl={1} sm={1}>
                  <select
                    name="type"
                    id="type_id"
                    className="form-select border-dashed"
                    aria-label="Default select example"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.type || ""}
                  >
                    <option>Statut </option>
                    {TypesSoudures.map(({ value, label }, index) => (
                      <option key={index} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col xxl={1} sm={1}>
                  <Input
                    name="tubeOutStart"
                    type="text"
                    className="form-control border-dashed"
                    id="tubeOutStart_id"
                    placeholder="Tube Sortant Debut"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.tubeOutStart}
                  />
                </Col>
                <Col xxl={1} sm={1}>
                  <Input
                    name="tubeOutEnd"
                    type="text"
                    className="form-control border-dashed"
                    id="tubeOutEnd_id"
                    placeholder="Tube Sort. End"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.tubeOutEnd}
                  />
                </Col>
                <Col xxl={1} sm={1}>
                  <select
                    name="idCableOut"
                    id="cableOut_id"
                    className="form-select border-dashed"
                    aria-label="Default select example"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.idCableOut || ""}
                  >
                    <option>Cable OUT </option>
                    {cables.map(({ cableName, cableId }, index) => (
                      <option key={index} value={cableId}>
                        {cableName}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col xxl={1} sm={4}>
                  <Button
                    onClick={() => {
                      deleteCassetteLineMutation.mutate(lineData.lineId);
                    }}
                    color="danger"
                    className="btn-icon mx-2"
                    outline
                  >
                    {" "}
                    <i className="ri-close-fill" />{" "}
                  </Button>
                  {/* {!isEdit && (
                                <Button
                                    id={`${lineData.lineId}`}
                                    onClick={handleEditLine}
                                    color="primary"
                                    className="btn-icon"
                                    outline
                                >
                                    {" "}
                                    <i className="ri-edit-fill" />{" "}
                                </Button>
                            )}  */}
                  {/* {isEdit && ( */}
                  <Button
                    type="submit"
                    color="success"
                    className="btn-icon"
                    outline
                  >
                    {" "}
                    <i className="ri-check-fill" />{" "}
                  </Button>
                  {/* )} */}
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default BoxBottomLineForm;
