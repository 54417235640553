import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";
import PreviewImage from "./previewImage";
import {createAdminSpace} from "../../../api/space/spaceAPI";
import {useMutation} from "react-query";
import toast from "react-hot-toast";
import Select from "react-select";
import React, {useState} from "react";

const AddEditForm = ({
  updateMemberMutation,
  addMemberMutation,
  modal,
  setModal,
  toggle,
  isEdit,
  user,
}) => {

  const createAdminSpaceMutation = useMutation(createAdminSpace, {
    onSuccess: (data) => {
      // Invalidates cache and refetch data
      queryClient.invalidateQueries("users");
      toast.success("L'utilisateur a été créé avec succès");
    },
    onError: (error) => {
      console.log(error)
      // An error happened!
      toast.error(error);
    },
  })

  const Roles = [
    { label: "Technicien", value: "ROLE_MOBILE_USER" },
    { label: "Ingénieur", value: "ROLE_BACK_OFFICE_MANAGER" },
    { label: "Admin", value: "ROLE_ADMIN" },
    { label: "Super Admin", value: "ROLE_SUPER_ADMIN" },
  ];

  const [selectedMulti, setselectedMulti] = useState(null);
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
    console.log(selectedMulti)
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: (user && user.firstname) || "",
      lastname: (user && user.lastname) || "",
      email: (user && user.email) || "",
      mobileNumber: (user && user.mobileNumber) || "",
      username: (user && user.username) || "",
      userRole: (user && user.userRole) || "",
      userImageUrl: (user && user.userImageUrl) || "",
      password: (user && user.password) || null,
      superAdminId: (user && user.superAdminId) || "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Ce champ Nom est obligatoire"),
      lastname: Yup.string().required("Ce champ Prenom est obligatoire"),
      email: Yup.string().required("Ce champ email est obligatoire"),
      mobileNumber: Yup.string().required("Ce champ Telephone est obligatoire"),
      username: Yup.string().required("veuillez donner un Nom d'utilisateur"),
      //userRole: Yup.string().required("Selectionnez le Role de l'utilisateur"),
      userImageUrl: Yup.mixed(),
    }),

    onSubmit: (values) => {
      const {
        firstname,
        lastname,
        email,
        mobileNumber,
        username,
        userRole,
        userImageUrl,
        password
      } = validation.values;
      const formData = new FormData();
      formData.append("firstname", firstname);
      formData.append("lastname", lastname);
      formData.append("email", email);
      formData.append("mobileNumber", mobileNumber);
      formData.append("username", username);
      formData.append("userRole", userRole);
      formData.append("password", password);
      formData.append("superAdminId", JSON.parse(sessionStorage.getItem("authUser"))?.superAdminId);
      formData.append("company", JSON.parse(sessionStorage.getItem("authUser"))?.company);
      if (isEdit) {
        const data = {
          formData,
          userId: user && user.userId,
          profile: userImageUrl,
        };
        updateMemberMutation.mutate(data);
        validation.resetForm();
      } else {
        const data = {
          formData,
          profile: userImageUrl,
        };
        // createAdminSpaceMutation.mutate(data);
        addMemberMutation.mutate(data);
        validation.resetForm();
      }
      toggle();
    },
  });

  return (
    <Modal
      id="showModalStaff"
      isOpen={modal}
      toggle={toggle}
      centered
      backdrop={"static"}
    >
      <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
        {!!isEdit ? "Mise a jour Utilisateur" : "Création d'un nouveau compte utilisateur"}
      </ModalHeader>
      <Formik
        htmlFor="staffForm"
        initialValues={validation.initialValues}
        validationSchema={validation.validationSchema}
      >
        {({ setFieldValue }) => (
          <Form
            className="tablelist-form"
            id="staffForm"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <ModalBody>
              <Input type="hidden" id="id-field" />
              <Row className="g-3">
                <Col lg={12}>
                  <div className="text-center">
                    <div className="position-relative d-inline-block">
                      <div className="position-absolute  bottom-0 end-0">
                        <Label htmlFor="customer-image-input" className="mb-0">
                          <div className="avatar-xs cursor-pointer">
                            <div className="avatar-title bg-light border rounded-circle text-muted">
                              <i className="ri-image-fill"></i>
                            </div>
                          </div>
                        </Label>
                        <Input
                          name="userImageUrl"
                          className="form-control d-none"
                          id="customer-image-input"
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          onChange={(e) =>
                            validation.setFieldValue(
                              "userImageUrl",
                              e.target.files[0]
                            )
                          }
                        />
                      </div>
                      <div className="avatar-lg p-1">
                        <div className="avatar-title bg-light rounded-circle">
                          {validation.values.userImageUrl ? (
                            <PreviewImage
                              file={validation.values.userImageUrl}
                            />
                          ) : (
                            <img
                              src={dummyImg}
                              alt="dummyImg"
                              id="customer-img"
                              className="avatar-md rounded-circle object-fit-cover"
                            />
                            // <img
                            // src={
                            //     user?.userImgUrl
                            //     ? `${
                            //         process.env.REACT_APP_API_URL +
                            //         "/data-file/print/" +
                            //         user?.userImgUrl
                            //         }`
                            //     : dummyImg
                            // }
                            // alt="dummyImg"
                            // id="customer-img"
                            // className="avatar-md rounded-circle object-fit-cover"
                            // />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div>
                    <Label htmlFor="lastname-field" className="form-label">
                      Nom
                      <span className="text-danger mx-1">*</span>
                    </Label>
                    <Input
                      name="lastname"
                      id="lastname-field"
                      className="form-control"
                      placeholder="Entrez le Nom"
                      type="text"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastname || ""}
                      invalid={
                        !!(validation.touched.lastname &&
                            validation.errors.lastname)
                      }
                    />
                    {validation.touched.lastname &&
                    validation.errors.lastname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastname}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={12}>
                  <div>
                    <Label htmlFor="firstname-field" className="form-label">
                      Prénom
                      <span className="text-danger mx-1">*</span>
                    </Label>
                    <Input
                      name="firstname"
                      id="firstname-field"
                      className="form-control"
                      placeholder="Entrez le Prenom"
                      type="text"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstname || ""}
                      invalid={
                        !!(validation.touched.firstname &&
                            validation.errors.firstname)
                      }
                    />
                    {validation.touched.firstname &&
                    validation.errors.firstname ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstname}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col lg={12}>
                  <div>
                    <Label htmlFor="username-field" className="form-label">
                      Nom d'utilisateur
                      <span className="text-danger mx-1">*</span>
                    </Label>

                    <Input
                      name="username"
                      id="username-field"
                      className="form-control"
                      placeholder="Entrez un nom d'utilisateur"
                      type="text"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.username || ""}
                      invalid={
                        !!(validation.touched.username &&
                            validation.errors.username)
                      }
                    />
                    {validation.touched.username &&
                    validation.errors.username ? (
                      <FormFeedback type="invalid">
                        {validation.errors.username}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col lg={6}>
                  <div>
                    <Label htmlFor="email_id-field" className="form-label">
                      Email
                      <span className="text-danger mx-1">*</span>
                    </Label>

                    <Input
                      name="email"
                      id="email_id-field"
                      className="form-control"
                      placeholder="Entrez Email"
                      type="text"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        !!(validation.touched.email && validation.errors.email)
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <Label htmlFor="phone-field" className="form-label">
                      Téléphone
                      <span className="text-danger mx-1">*</span>
                    </Label>

                    <Input
                      name="mobileNumber"
                      id="phone-field"
                      className="form-control"
                      placeholder="Entrez No. Téléphone"
                      type="text"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.mobileNumber || ""}
                      invalid={
                        !!(validation.touched.mobileNumber &&
                            validation.errors.mobileNumber)
                      }
                    />
                    {validation.touched.mobileNumber &&
                    validation.errors.mobileNumber ? (
                      <FormFeedback type="invalid">
                        {validation.errors.mobileNumber}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={12}>
                  <div>
                    <Label
                      htmlFor="role-choices"
                      className="form-label font-size-13 text-muted"
                    >
                      Rôle
                      <span className="text-danger mx-1">*</span>
                    </Label>
                    {/*<Select*/}
                    {/*    name="userRole"*/}
                    {/*    value={selectedMulti}*/}
                    {/*    // onBlur={validation.handleBlur}*/}
                    {/*    isMulti={true}*/}
                    {/*    onChange={(e) => {*/}
                    {/*      handleMulti();*/}
                    {/*      console.log(e)*/}
                    {/*    }}*/}
                    {/*    // onChange={validation.handleChange}*/}
                    {/*    options={Roles.filter((role) => JSON.parse(sessionStorage.getItem("authUser")).isSuperAdmin && role.value !== 'ROLE_ADMIN')}*/}
                    {/*/>*/}
                    <select
                        name="userRole"
                        className="form-select"
                        data-choices
                        data-choices-search-false
                        id="choices-categories-input"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.userRole || ""}
                    >
                      <option defaultValue="undefined"></option>
                      {JSON.parse(sessionStorage.getItem("authUser"))?.roles?.includes('ROLE_SUPER_ADMIN') && <option value="ROLE_ADMIN">ADMIN</option>}
                      <option value="ROLE_BACK_OFFICE_MANAGER">INGENIEUR</option>
                      <option value="ROLE_MOBILE_USER">TECHNICIEN</option>
                      {/*{roles.filter((role) => !JSON.parse(sessionStorage.getItem("authUser"))?.roles?.includes(role.value)).map(({ value, label }, index) => (*/}
                      {/*  <option key={index} value={value}>*/}
                      {/*    {label}*/}
                      {/*  </option>*/}
                      {/*))}*/}
                    </select>
                    {validation.touched.userRole &&
                    validation.errors.userRole ? (
                      <FormFeedback type="invalid">
                        {validation.errors.userRole}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  {" "}
                  Fermer{" "}
                </button>
                <button type="submit" className="btn btn-success" id="add-btn">
                  {" "}
                  {!!isEdit ? "Modifier" : "Ajouter"}{" "}
                </button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddEditForm;
