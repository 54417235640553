import {Divider, Drawer, Form, DatePicker} from "antd";
import {Col, FormFeedback, Input, Label, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getMembers} from "../../../api/users/usersApi";
import {getAllProjects} from "../../../api/projects/projectsApi";
import ProjectUpload from "../../Projects/components/projectUpload";
import * as moment from "moment";
import {createAudit, updateAudit} from "../../../api/audits/auditsApi";
import toast from "react-hot-toast";
import {message} from "../../../Components/constants/message";
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';

const AddEditFormDrawer = ({openTF, setOpenTF, isEdit, setIsEdit, task, setTask}) => {
    const queryClient = useQueryClient();
    // const [endDate, setEndDate] = useState("");
    // const [dueDate, setDueDate] = useState("");
    const [files, setFiles] = useState([]);
    const {data: projects} = useQuery("AllProjects", getAllProjects);
    const {data: technicians} = useQuery({queryKey:"members", queryFn:getMembers, select: data => data.filter((item) => item.userRole === "ROLE_MOBILE_USER")});

    // useEffect(() => {
    //     if (task) {
    //         setDueDate(moment(task.dueDate).format('yyyy-MM-DD HH:mm:ss'))
    //         setEndDate(moment(task.endDate).format('yyyy-MM-DD HH:mm:ss'))
    //     }
    // }, [task])

    const addAuditMutation = useMutation(createAudit, {
        onSuccess: (data) => {
            queryClient.invalidateQueries("audits");
            setFiles([])
            toast.success(message.AUDIT_CREATED_SUCCESSFULLY);
        },
        onError: (error) => {
            toast.error(error)
        }
    });

    const updateAuditMutation = useMutation(updateAudit, {
        onSuccess: (data) => {
            queryClient.invalidateQueries("audits");
            setFiles([])
            toast.success(message.AUDIT_UPDATED_SUCCESSFULLY)
        },
        onError: (error) => {
            toast.error(error)
        }
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: (task && task.name) || "",
            description: (task && task.description) || "",
            address: (task && task.address) || "",
            priority: (task && task.priority) || "Medium",
            status: (task && task.status) || "Created",
            // dueDate: (task && task.dueDate) || null,
            // endDate: (task && task.endDate) || null,
            createdDate: (task && task.createdDate) || "",
            interlocFirstname: (task && task.interlocFirstname) || "",
            interlocLastname: (task && task.interlocLastname) || "",
            interlocEmail: (task && task.interlocEmail) || "",
            interlocPhone: (task && task.interlocPhone) || "",
            userId: (task && task.userId) || "",
            projectId: (task && task.projectId) || "",
            date: {
                dueDate: (task && task.dueDate) || null,
                endDate: (task && task.endDate) || null,
            }
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Veuillez donner un nom à la tâche"),
            address: Yup.string().required("Veuillez donner le lieu d'intervention"),
        }),
        onSubmit: (values) => {
            const {
                name,
                description,
                address,
                priority,
                status,
                interlocFirstname,
                interlocLastname,
                interlocEmail,
                interlocPhone,
                date
            } = values;

            const formData = {
                "name": name,
                "description": description,
                "address": address,
                "priority": priority,
                "status": status,
                "dueDate": date.dueDate ? moment(date.dueDate).format('yyyy-MM-DDThh:mm:ss') : JSON.parse(null),
                "endDate": date.endDate ? moment(date.endDate).format('yyyy-MM-DDThh:mm:ss') : JSON.parse(null),
                "interlocFirstname": interlocFirstname,
                "interlocLastname": interlocLastname,
                "interlocEmail": interlocEmail,
                "interlocEmail": interlocEmail,
                "superAdminId": JSON.parse(sessionStorage.getItem("authUser"))?.superAdminId,
                "company": JSON.parse(sessionStorage.getItem("authUser"))?.company,
            }
            if (isEdit) {
                const data = {
                    formData,
                    attachedFiles: files,
                    userId: parseInt(values.userId),
                    projectId: parseInt(values.projectId),
                    id: task && task.id
                };
                updateAuditMutation.mutate(data)
                validation.resetForm();
            } else {
                const data = {
                    formData,
                    attachedFiles: files,
                    userId: parseInt(values.userId),
                    projectId: parseInt(values.projectId),
                };
                addAuditMutation.mutate(data);
                validation.resetForm();
            }
        },
    })


    const onCloseTF = () => {
        setOpenTF(false);
        setIsEdit(false)
        validation.resetForm();
        setTask(null)
    };

    return (
        <Drawer
            zIndex={9999}
            title={`${isEdit ? 'Mise à jour tâche' : 'Création d\'une nouvelle tâche'}`}
            width={520}
            onClose={onCloseTF}
            open={openTF}
            maskClosable={false}
        >
            <form
                autoComplete="off"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <div className="row">
                    <div className="col-xl-12 mb-2">
                        <label htmlFor="taskNameInput" className="form-label text-muted">Intitulé de la tâche<span
                            className="text-danger mx-1">*</span></label>
                        <input
                            id="taskNameInput"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Donnez un nom à la Tâche"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            validate={{
                                required: {value: true}
                            }}
                            invalid={
                                !!(validation.touched.name && validation.errors.name)
                            }
                        />
                        {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                                {validation.errors.name}
                            </FormFeedback>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 mb-2">
                        <label htmlFor="taskDescriptionInput" className="form-label text-muted">Description</label>
                        <textarea
                            id="taskDescriptionInput"
                            name="description" rows="1"
                            className="form-control"
                            placeholder="Donnez une description à la Tâche"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                        ></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 mb-2">
                        <label htmlFor="taskAddressInput" className="form-label text-muted">Adresse d'intervention<span
                            className="text-danger mx-1">*</span></label>
                        <input
                            id="taskAddressInput"
                            name="address"
                            type="text"
                            className="form-control"
                            placeholder="Donnez l'adresse du lieu d'intervention"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            validate={{
                                required: {value: true}
                            }}
                            invalid={
                                !!(validation.touched.address && validation.errors.address)
                            }
                        />
                        {validation.touched.address && validation.errors.address ? (
                            <FormFeedback type="invalid">
                                {validation.errors.address}
                            </FormFeedback>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <label htmlFor="dueDateTaskInput" className="form-label text-muted">Date d'intervention</label>
                        <Form.Item style={{width: '100%'}} id="dueDateTaskInput">
                            <RangePicker
                                name="date"
                                showTime
                                style={{width: '100%'}}
                                size="large"
                                format="DD-MM-YYYY HH:mm"
                                onChange={(value) => {
                                    if (value) {
                                        validation.setFieldValue("date.dueDate", value[0]?.$d)
                                        validation.setFieldValue("date.endDate", value[1]?.$d)
                                    }
                                }}
                                //defaultValue={[dayjs(dayjs(validation.values.dueDate)), dayjs(dayjs(validation.values.endDate))]}
                                value={[(validation.values.date.dueDate ? dayjs(validation.values.date.dueDate) : null), (validation.values.date.endDate ? dayjs(validation.values.date.endDate) : null)]}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 mb-2">
                        <label htmlFor="taskPriorityInput" className="form-label text-muted">Priorité</label>
                        <select
                            id="taskPriorityInput"
                            name="priority"
                            className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.priority || ""}
                        >
                            <option value="Medium" defaultValue="Medium">Important</option>
                            <option value="High">Urgent</option>
                            <option value="Highest">Tres Urgent</option>
                        </select>
                    </div>
                    <div className="col-xl-6 mb-2">
                        <label htmlFor="taskTechnicianInput" className="form-label text-muted">Technicien</label>
                        <select
                            id="taskTechnicianInput"
                            name="userId"
                            className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.userId || ""}
                        >
                            <option defaultValue="undefined">Choisir un technicien</option>
                            {technicians && technicians.map(
                                    ({firstname, lastname, userId}, index) => (
                                        <option key={index} value={userId}>
                                            {firstname} {lastname}
                                        </option>
                                    )
                            )}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 mb-2">
                        <label htmlFor="taskProjectInput" className="form-label text-muted">Projet</label>
                        <select
                            id="taskProjectInput"
                            name="projectId"
                            className="form-select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.projectId || ""}
                        >
                            <option defaultValue="undefined">Ajouter à un projet</option>
                            {projects && projects?.filter((item) => item.projectName !== "root")
                                .map(({ projectName, projectId }, index) => (
                                    <option key={index} value={projectId}>
                                        {projectName}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <ProjectUpload files={files} setFiles={setFiles}/>
                    </div>
                </div>
                <Divider>Contact (Optionnel)</Divider>
                <div className="row">
                    <div className="col-xl-6 mb-2">
                        <label htmlFor="contactFirstNameInput" className="form-label text-muted">Prénom</label>
                        <input
                            id="contactFirstNameInput"
                            name="interlocFirstname"
                            type="text"
                            className="form-control"
                            placeholder="Saisir le prénom du contact"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.interlocFirstname || ""}
                        />
                    </div>
                    <div className="col-xl-6 mb-2">
                        <label htmlFor="contactLastNameInput" className="form-label text-muted">Nom</label>
                        <input
                            id="contactLastNameInput"
                            name="interlocLastname"
                            type="text"
                            className="form-control"
                            placeholder="Saisir le nom du contact"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.interlocLastname || ""}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 mb-2">
                        <label htmlFor="contactPhoneInput" className="form-label text-muted">Téléphone</label>
                        <input
                            id="contactPhoneInput"
                            name="interlocPhone"
                            type="text"
                            className="form-control"
                            placeholder="Saisir le téléphone du contact"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.interlocPhone || ""}
                        />
                    </div>
                    <div className="col-xl-6 mb-2">
                        <label htmlFor="contactEmailInput" className="form-label text-muted">Email</label>
                        <input
                            id="contactEmailInput"
                            name="interlocEmail"
                            type="text"
                            className="form-control"
                            placeholder="Saisir l'email du contact"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.interlocEmail || ""}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 hstack gap-2 justify-content-end">
                        <button type="submit" className="btn btn-primary"><i className="ri-save-3-line align-bottom me-1"></i>Enregistrer
                        </button>
                    </div>
                </div>
            </form>
        </Drawer>
    )
}

export default AddEditFormDrawer;