import React from "react";

const colors = {
    1: "#FF0000",
    2: "#0000FF",
    3: "#18F10E",
    4: "#FFFF00",
    5: "#81007F",
    6: "#FFFFFF",
    7: "#FF7F00",
    8: "#AFAFAF",
    9: "#B05121",
    10: "#000000",
    11: "#17EAE3",
    12: "#FC8BFF",
};

const CassetteLine = ({line, i}) => {

    return (
        <tr key={i}>
            <td align="center" valign="middle">
                {line?.fiberDataReportIn?.cableCapacity}
            </td>
            <td align="center" valign="middle">
                {line?.fiberDataReportIn?.cableName}
            </td>
            <td
                align="center"
                valign="middle"
                bgcolor={line?.fiberDataReportIn?.tubeColor}
            >
                <font
                    color={
                        line?.fiberDataReportIn?.tubeColor ===
                        "FFFFFF"
                            ? "#000000"
                            : "#FFFFFF"
                    }
                >
                    {line?.fiberDataReportIn?.tubeNumber}
                </font>
            </td>
            <td
                align="center"
                valign="middle"
                bgcolor={line?.fiberDataReportIn?.tubeColor}
            >
                <font
                    color={
                        line?.fiberDataReportIn?.tubeColor ===
                        "FFFFFF"
                            ? "#000000"
                            : "#FFFFFF"
                    }
                >
                    {line?.fiberDataReportIn?.ringNumber}
                </font>
            </td>
            {line?.fiberDataReportIn ? (
                <td
                    align="center"
                    valign="middle"
                    bgcolor={
                        colors[line?.fiberDataReportIn?.fiberNumber]
                    }
                >
                    <font
                        color={
                            line?.fiberDataReportIn?.fiberNumber ===
                            "6"
                                ? "#000000"
                                : "#FFFFFF"
                        }
                    >
                        {line?.fiberDataReportIn?.fiberNumber}
                    </font>
                </td>
            ) : (
                <td></td>
            )}
            <td align="center" valign="middle">
                {line?.status}
            </td>
            {line?.fiberDataReportOut ? (
                <td
                    align="center"
                    valign="middle"
                    bgcolor={
                        colors[
                            line?.fiberDataReportOut?.fiberNumber
                            ]
                    }
                >
                    <font
                        color={
                            line?.fiberDataReportOut?.fiberNumber ===
                            "6"
                                ? "#000000"
                                : "#FFFFFF"
                        }
                    >
                        {line?.fiberDataReportOut?.fiberNumber}
                    </font>
                </td>
            ) : (
                <td></td>
            )}
            <td
                align="center"
                valign="middle"
                bgcolor={line?.fiberDataReportOut?.tubeColor}
            >
                <font
                    color={
                        line?.fiberDataReportOut?.tubeColor ===
                        "FFFFFF"
                            ? "#000000"
                            : "#FFFFFF"
                    }
                >
                    {line?.fiberDataReportOut?.ringNumber}
                </font>
            </td>
            <td
                align="center"
                valign="middle"
                bgcolor={line?.fiberDataReportOut?.tubeColor}
            >
                <font
                    color={
                        line?.fiberDataReportOut?.tubeColor ===
                        "FFFFFF"
                            ? "#000000"
                            : "#FFFFFF"
                    }
                >
                    {line?.fiberDataReportOut?.tubeNumber}
                </font>
            </td>
            <td align="center" valign="middle">
                {line?.fiberDataReportOut?.cableName}
            </td>
            <td align="center" valign="middle">
                {line?.fiberDataReportOut?.cableCapacity}
            </td>
        </tr>
    )
}

export default CassetteLine;