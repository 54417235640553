import {Card, CardBody, CardHeader, Label} from "reactstrap";
import img3 from "../../../assets/images/users/user-dummy-img.jpg";
import {Button} from "antd";
import {DisconnectOutlined, LinkOutlined} from "@ant-design/icons";
import React, {useContext} from "react";
import TaskContext from "../hook/TaskContext";
import {useMutation, useQueryClient} from "react-query";
import {removeAssignation} from "../../../api/audits/auditsApi";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";

const TechnicianSection = ({showDrawer,technician}) => {
    const {id} = useParams();
    const queryClient = useQueryClient();

    //Delete user assignation
    const handleDeleteUser = (item) => {
        deleteUserAssignationtMutation.mutate({userId: item.id, auditTaskId: parseInt(id)});
    }
    // Delete User Assignation Mutation
    const deleteUserAssignationtMutation = useMutation(removeAssignation, {
        onSuccess: (data) => {
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("audit");
            toast.success("L'assignation a été supprimée!")
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    return (
        <Card style={{height: '410px'}}>
            <CardHeader>
                <h5 className="card-title mb-0">
                    <i className="ri-user-settings-line align-bottom me-1 text-muted"></i>{" "}
                    Technicien
                </h5>
            </CardHeader>
            <CardBody>
                {technician
                    ? (
                        <>
                            <div className="text-center">
                                <div
                                    className="profile-user position-relative d-inline-block mx-auto  mb-2">
                                    <img src={img3}
                                         className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                         alt="user-profile"/>
                                    <div
                                        className="avatar-xxs p-0 rounded-circle profile-photo-edit">
                                        <Label htmlFor="profile-img-file-input"
                                               className="profile-photo-edit avatar-xxs">
                                                                <span className="avatar-title rounded-circle bg-light text-body">
                                                                    <i className="ri-eye-fill"></i>
                                                                </span>
                                        </Label>
                                    </div>
                                </div>
                                <h5 className="fs-16 mb-3">{`${technician?.firstname} ${technician?.lastname}`}</h5>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <div className="flex-shrink-0">
                                    <p className="text-muted mb-0"><i className="ri-user-fill"></i></p>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-0">{technician?.username}</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <div className="flex-shrink-0">
                                    <p className="text-muted mb-0"><i className="ri-mail-fill"></i></p>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-0">{technician?.email}</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <div className="flex-shrink-0">
                                    <p className="text-muted mb-0"><i className="ri-phone-fill"></i></p>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                    <h6 className="mb-0">{technician?.mobileNumber}</h6>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center mt-4">
                                <Button onClick={() =>handleDeleteUser(technician)} type="primary" danger icon={<DisconnectOutlined/>}>
                                    Désaffecter
                                </Button>
                            </div>
                        </>
                    )
                    : (
                        <>
                            <div className="avatar-lg mx-auto mb-4 mt-4" id="login-tour">
                                <div
                                    className="avatar-title bg-light rounded-circle text-primary fs-24">
                                    <i className="ri-user-add-line"></i>
                                </div>
                            </div>
                            <div
                                className="d-flex justify-content-center align-items-center mt-4 mb-4">
                                <Button onClick={showDrawer} type="primary" icon={<LinkOutlined/>}>
                                    Affecter
                                </Button>
                            </div>
                            <p className="text-muted mb-0 mt-4 mb-4">La tâche n'a pas encore été
                                assignée à un
                                technicien. Vous pouvez cliquer sur ce bouton pour en ajouter.</p>
                        </>
                    )
                }

            </CardBody>
        </Card>
    )
}

export default TechnicianSection;