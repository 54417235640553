import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Cassette from "./cassette/Cassette";
import {Card} from "antd";

const ShowCassetteModal = ({toggleModal, showCassetteMdl, cassette, status}) => {
    return (
        <Modal
            size="xl"
            isOpen={showCassetteMdl}
            toggle={toggleModal}
            className="modal-fullscreen"
            id="cassetteModal"
        >
            <ModalHeader
                // className="modal-title"
                id="cassetteModal"
                toggle={toggleModal}
            >
            </ModalHeader>
            <ModalBody>
                <Cassette isAll={false} cassette={cassette} status={status} toggleModal={toggleModal} />
            </ModalBody>
        </Modal>
    );
}

export default ShowCassetteModal;