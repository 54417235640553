import { scheduler } from "../../../assets/dhtmlxscheduler/dhtmlxscheduler.js?v=7.0.5";
import "../../../assets/dhtmlxscheduler/dhtmlxscheduler.css?v=7.0.5";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createAudit } from "../../../api/audits/auditsApi";
import toast from "react-hot-toast";
import * as moment from "moment";
import { updateTaskTechnician } from "../../../api/planning/planningApi";

const Timeline = ({ sections, events }) => {
  const queryClient = useQueryClient();
  const [event, setEvent] = useState(null);
  const [assignated, setAssignated] = useState(null);

  const addAuditMutation = useMutation(createAudit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("audits");
      toast.success("Tâche ajoutée");
      setEvent(null)
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  const updateAuditAssignationMutation = useMutation(updateTaskTechnician, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("audits");
      toast.success("Affectation changée");
      setAssignated(null)
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  scheduler.plugins({
    timeline: true,
    quick_info: true,
  });

  useEffect(() => {
    scheduler.i18n.setLocale("fr");
    scheduler.config.prevent_cache = true;
    scheduler.locale.labels.timeline_tab = "Timeline";
    scheduler.locale.labels.section_custom = "Section";
    scheduler.config.details_on_create = true;
    scheduler.config.details_on_dblclick = true;

    scheduler.createTimelineView({
      name: "timeline",
      x_unit: "minute",
      x_date: "%H:%i",
      x_step: 30,
      x_size: 24,
      x_start: 16,
      x_length: 48,
      y_unit: sections,
      y_property: "section_id",
      render: "bar",
    });
    scheduler.config.lightbox.sections = [
      {
        name: "description",
        height: 50,
        map_to: "text",
        type: "textarea",
        focus: true,
      },
      {
        name: "Adresse",
        height: 50,
        map_to: "address",
        type: "textarea",
      },
      {
        name: "custom",
        height: 30,
        type: "select",
        options: sections,
        map_to: "section_id",
      },
      { name: "time", height: 72, type: "time", map_to: "auto" },
    ];
    scheduler.init("scheduler_here", new Date(), "timeline");
    scheduler.clearAll();
    scheduler.parse(events);
  }, []);

  scheduler.attachEvent("onEventAdded", function (id, ev) {
    const formData = new FormData();
    formData.append("name", ev.text);
    formData.append("address", ev.address);
    formData.append("priority", "Medium");
    formData.append("status", "Affected");
    formData.append(
      "dueDate",
      moment(ev.start_date).format("yyyy-MM-DDThh:mm:ss")
    );
    formData.append(
      "endDate",
      moment(ev.end_date).format("yyyy-MM-DDThh:mm:ss")
    );
    formData.append(
      "superAdminId",
      JSON.parse(sessionStorage.getItem("authUser"))?.superAdminId
    );
    formData.append(
      "company",
      JSON.parse(sessionStorage.getItem("authUser"))?.company
    );

    const data = {
      formData,
      userId: parseInt(ev.section_id),
    };

    setEvent(data);
  });

  useEffect(() => {
    if (event) {
      for (const value of event.formData.values()) {
        console.log(value);
      }
      addAuditMutation.mutate(event);
    }
  }, [event]);

  scheduler.attachEvent(
    "onBeforeEventChanged",
    function (ev, e, is_new, original) {
      setAssignated({
        old: original.section_id,
        new: ev.section_id,
        task: ev.taskId,
      });
      return true;
    }
  );

  useEffect(() => {
    if (assignated?.old && assignated?.new && assignated.old !== assignated.new) {
      updateAuditAssignationMutation.mutate(assignated)
    }
  }, [assignated]);

  return (
    <>
      <div
        id="scheduler_here"
        className="dhx_cal_container"
        style={{ width: "100%", height: "100vh" }}
      >
        <div className="dhx_cal_navline">
          <div className="dhx_cal_prev_button"></div>
          <div className="dhx_cal_next_button"></div>
          <div className="dhx_cal_today_button"></div>
          <div className="dhx_cal_date"></div>
          <div className="dhx_cal_tab" data-tab="day"></div>
          <div className="dhx_cal_tab" data-tab="week"></div>
          <div className="dhx_cal_tab" data-tab="timeline"></div>
          <div className="dhx_cal_tab" data-tab="month"></div>
        </div>
        <div className="dhx_cal_header"></div>
        <div className="dhx_cal_data"></div>
      </div>
    </>
  );
};

export default Timeline;
