import React, {useEffect, useState} from 'react';
import {Button, Drawer, Space} from 'antd';
import logoDark from "../../../assets/images/companies/company-logo.png";
import {Label, Form, Input, Row, Col, FormFeedback} from "reactstrap";
import Select from "react-select";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getMembers, getMembersBySuperAdmin} from "../../../api/users/usersApi";
import {addSpace, updateSpace} from "../../../api/space/spaceAPI";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import PreviewCompanyImg from "./previewCompanyImg";
import toast from "react-hot-toast";


const AddEditDrawerSpace = ({open, setOpen, setItem, item, isEdit, setIsEdit}) => {
    const queryClient = useQueryClient();
    const {data: admins, isError, error, isFetched: isFetchedAdmin} = useQuery("admins", getMembers, {
        select: (data) => data.filter((item) => item.userRole === 'ROLE_ADMIN')
    });

    const addSpaceMutation = useMutation(addSpace, {
        onSuccess: (data) => {
            queryClient.invalidateQueries("spaces")
            validation.resetForm();
            toast.success("Espace client créé avec succès!");
        },
        onError: (error) => {
            toast.error("Licence invalide!")
            // toast.error(error)
        }
    })

    const editSpaceMutation = useMutation(updateSpace, {
        onSuccess: (data) => {
            queryClient.invalidateQueries("spaces")
            validation.resetForm();
            toast.success("Espace client modifié avec succès!");
        },
        onError: (error) => {
            toast.error(error)
        }
    })

    const onClose = () => {
        setOpen(false);
        setIsEdit(false)
        setItem(null)
        setTechCounter(0)
        setEngCounter(0)
        validation.resetForm();
    };

    const [techCounter, setTechCounter] = useState(0);
    function techCountUP(id, prev_data_attr) {
            id(prev_data_attr + 1);
    }
    function techCountDown(id, prev_data_attr) {
        if (prev_data_attr >0) id(prev_data_attr - 1);
    }

    const [engCounter, setEngCounter] = useState(0);
    function engCountUP(id, prev_data_attr) {
        id(prev_data_attr + 1);
    }
    function engCountDown(id, prev_data_attr) {
        if (prev_data_attr >0) id(prev_data_attr - 1);
    }

    useEffect(() => {
        if (item){
            setTechCounter(item.clientSpace.techNumber)
            setEngCounter(item.clientSpace.engineerNumber)
        }
    }, [item]);
    // validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyLicense: "",
            companyName: (item && item.clientSpace.companyName) || "",
            logoUrl: (item && item.clientSpace.logoUrl) || "",
            engineerNumber: (item && item.clientSpace.engineerNumber) || 0,
            techNumber: (item && item.clientSpace.techNumber) || 0,
            adminId: (item && item.clientSpace.adminId) || null,
            company: (item && item.clientSpace.company) || "",
            logo: (item && item.clientSpace.logo) || "",
            emails: (item && item?.clientSpace?.emails) || "",
            isEnable: (item && item?.clientSpace?.isEnable) || false
        },
        validationSchema: Yup.object({
            companyLicense: Yup.string().required("Clé de licence obligatoire"),
            //engineerNumber: Yup.string().required("Ce champ est obligatoire"),
            //techNumber: Yup.string().required("Ce champ est obligatoire"),
            adminId: Yup.string().required("Veuillez choisir un admin de l'espace"),
            //logo: Yup.mixed(),
        }),

        onSubmit: (values) => {
            const {
                companyLicense,
                companyName,
                engineerNumber,
                techNumber,
                adminId,
                logo,
                logoUrl,
                emails,
                isEnable
            } = validation.values;

            const data = {
                "logo": logo,
                "companyName": companyName,
                "logoUrl": logo && logo.name || logoUrl || "",
                "engineerNumber": engCounter,
                "techNumber": techCounter,
                "adminId": parseInt(adminId),
                "emails": emails,
                "isEnable": isEnable,
                "superAdminId": JSON.parse(sessionStorage.getItem("authUser"))?.userId,
                "company": (Math.random() + 1).toString(36).substring(7).toUpperCase(),
                "companyLicense": companyLicense
            };

            if (isEdit) {
                editSpaceMutation.mutate({id: item && item?.clientSpace?.id, space:data})
            } else {
                addSpaceMutation.mutate(data);
            }
        },
    });

    return (
        <>
            <Drawer
                zIndex={9999}
                title={`${isEdit ? 'Mise à jour espace client' : 'Création d\'un nouveau espace client'}`}
                width={520}
                onClose={onClose}
                open={open}
                maskClosable={false}
                styles={{
                    // body: {
                    //     paddingBottom: 80,
                    // },
                }}
                // extra={
                //     <Space>
                //         <Button onClick={onClose}>Annuler</Button>
                //         <Button onClick={onClose} type="primary">
                //             Enregistrer
                //         </Button>
                //     </Space>
                // }
            >
                <Form
                    className="form-steps"
                    autoComplete="off"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <div className="text-center pt-3 mb-1">
                        <div
                            className="profile-user position-relative d-inline-block mx-auto  mb-4">
                            {validation.values.logo ? (
                                <PreviewCompanyImg
                                    file={validation.values.logo}
                                />
                            ) : (
                                <img
                                    src={logoDark}
                                    alt="dummyImg"
                                    id="customer-img"
                                    className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                />)}
                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                <Input
                                    name="logo"
                                    id="profile-img-file-input"
                                    type="file"
                                    className="profile-img-file-input"
                                    accept="image/png, image/jpg, image/jpeg"
                                    onChange={(e) =>
                                        validation.setFieldValue(
                                            "logo",
                                            e.target.files[0]
                                        )
                                    }
                                />
                                <Label htmlFor="profile-img-file-input"
                                       className="profile-photo-edit avatar-xs">
                                <span className="avatar-title rounded-circle bg-light text-body">
                                    <i className="ri-camera-fill"></i>
                                </span>
                                </Label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="iconInput" className="form-label text-muted">Entreprise</Label>
                                    <div className="form-icon">
                                        <Input
                                            name="companyName"
                                            type="text"
                                            className="form-control form-control-icon"
                                            id="iconInput"
                                            placeholder="Saisir le nom de l'entreprise"
                                            validate={{
                                                required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.companyName || ""}
                                            invalid={
                                                !!(validation.touched.companyName &&
                                                    validation.errors.companyName)
                                            }
                                        />
                                        <i className="ri-hotel-line"></i>
                                        {validation.touched.companyName &&
                                        validation.errors.companyName ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.companyName}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="iconInput" className="form-label text-muted">Administrateur</Label>
                                    <div className="form-icon">
                                        <select
                                            name="adminId"
                                            className="form-select form-control-icon mb-3"
                                            aria-label="Choisir un administeur de l'espace"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.adminId || ""}
                                            validate={{
                                                required: { value: true },
                                            }}
                                            invalid={
                                                !!(validation.touched.adminId &&
                                                    validation.errors.adminId)
                                            }
                                        >
                                            {/*<option className="text-muted" disabled>Choisir un administeur de l'espace</option>*/}
                                            <option className="text-muted" defaultValue="undefined">Choisir un administeur de l'espace</option>
                                            {isFetchedAdmin && admins.map(({firstname, lastname, username, userId}, index) => (
                                                <option key={index} value={userId}>
                                                    {`${firstname} ${lastname} (${username})`.toUpperCase()}
                                                </option>
                                            ))}
                                        </select>
                                        <i className="ri-admin-line"></i>
                                        {validation.touched.adminId &&
                                        validation.errors.adminId ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.adminId}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <h5 className="fs-15 fw-medium text-muted">
                                        Techniciens
                                    </h5>
                                    {/*<Label htmlFor="iconInput" className="form-label text-muted">Techniciens</Label>*/}
                                    <div className="input-step">
                                        <button
                                            type="button"
                                            className="minus"
                                            onClick={() => {
                                                techCountDown(setTechCounter, techCounter);
                                            }}
                                        >
                                            –
                                        </button>
                                        <Input
                                            name="techNumber"
                                            type="number"
                                            className="product-quantity"
                                            min="0"
                                            max="100"
                                            readOnly
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={techCounter}
                                        />
                                        <button
                                            type="button"
                                            className="plus"
                                            onClick={() => {
                                                    techCountUP(setTechCounter, techCounter);
                                            }}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div>
                                    <h5 className="fs-15 fw-medium text-muted">
                                        Ingénieurs
                                    </h5>
                                    <div className="input-step">
                                        <button
                                            type="button"
                                            className="minus"
                                            onClick={() => {
                                                engCountDown(setEngCounter, engCounter);
                                            }}
                                        >
                                            –
                                        </button>
                                        <Input
                                            name="engineerNumber"
                                            type="number"
                                            className="product-quantity"
                                            min="0"
                                            max="100"
                                            readOnly
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={engCounter}
                                        />
                                        <button
                                            type="button"
                                            className="plus"
                                            onClick={() => {
                                                engCountUP(setEngCounter, engCounter);
                                            }}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="choices-text-unique-values" className="form-label text-muted">Emails de Notifications</Label>
                                    <input
                                        name="emails"
                                        className="form-control"
                                        id="choices-text-unique-values"
                                        data-choices
                                        data-choices-text-unique-true
                                        type="text"
                                        placeholder="exemple@mail.com"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="licence_key-input" className="form-label text-muted">
                                        Clé de licence
                                        <span className="text-danger mx-1">*</span>
                                    </Label>
                                    <Input
                                        name="companyLicense"
                                        className="form-control"
                                        id="licence_key-input"
                                        type="text"
                                        placeholder="Saisir une clé de licence valide"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.companyLicense || ""}
                                        invalid={
                                            !!(validation.touched.companyLicense &&
                                                validation.errors.companyLicense)
                                        }
                                    />
                                    {validation.touched.companyLicense &&
                                    validation.errors.companyLicense ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.companyLicense}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                        </Row>
                        <Row>
                        <div className="form-check form-switch form-switch-danger mt-3 ms-3">
                                <Input
                                    name="isEnable"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.isEnable || ""}
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                />
                                <Label className="form-check-label" for="flexSwitchCheckDefault">Activer
                                    espace</Label>
                            </div>

                        </Row>
                    </div>
                    <div className="d-flex align-items-start gap-3 mt-4">
                        <button
                            type="submit"
                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                            data-nexttab="steparrow-description-info-tab"
                        >
                            <i className="las la-rocket label-icon align-middle fs-16 ms-2"></i>
                            Créer Espace
                        </button>
                    </div>
                </Form>
            </Drawer>
        </>
    );
};
export default AddEditDrawerSpace;