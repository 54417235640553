import React, { useState } from "react";
import { Card, CardBody, CardTitle, CardText, Button, Input, Form } from "reactstrap";

const QuotaCard = ({ title, description, price, isActive, onClick, onSendEmail }) => {
  const [showTextarea, setShowTextarea] = useState(false);
  const [emailText, setEmailText] = useState("");

  const handleCommanderClick = () => {
    if (title === "Plus de forfait") {
      setShowTextarea(true);
    } else {
      onClick();
    }
  };

  const handleSendEmail = () => {
    onSendEmail(emailText);
    setEmailText("");
    setShowTextarea(false);
  };

  const handleCancel = () => {
    setShowTextarea(false);
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardBody className="d-flex flex-column">
        <CardTitle tag="h5">{title}</CardTitle><br></br>
        <CardText style={{ maxHeight: "100px", overflow: "hidden" }}>{description}</CardText>
        {!showTextarea && title !== "Plus de forfait" && (
          <div style={{ fontSize: "2rem", fontWeight: "bold", textAlign: "center", marginTop: "auto" }}>
            € {price}
          </div>
        )}
        <div className="text-center mt-3">
          {!showTextarea ? (
            <Button color={isActive ? "success" : "light"} onClick={handleCommanderClick}>
              Commander
            </Button>
          ) : (
            <Form>
              <Input
                type="textarea"
                value={emailText}
                onChange={(e) => setEmailText(e.target.value)}
                placeholder="Renseignez les détails de votre commande de forfait..."
              />
              <Button color="success" onClick={handleSendEmail} className="mt-2">
                Envoyer
              </Button>{" "}
              <Button color="danger" onClick={handleCancel} className="mt-2">
                Annuler
              </Button>
            </Form>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default QuotaCard;
