import React, {useCallback, useEffect, useState} from "react";
import { Link, NavLink } from "react-router-dom";
import {Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {EditOutlined} from "@ant-design/icons";
import {Badge, Button, Tag} from "antd";
import GoogleMapsBalises from "../../Dashboard/component/GoogleMapsBalises";
import EditBoxInfosModal from "./EditBoxInfosModal";

const TaskDescription = ({audit}) => {
    const [boxModal, setBoxModal] = useState(false);
    const toggleBoxMdl = useCallback(() => {
        if (boxModal) {
            setBoxModal(false);
        } else {
            setBoxModal(true);
        }
    }, [boxModal]);

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (audit?.place?.address) {
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
              audit.place.address
            )}`
          );
          const data = await response.json();
          if (data.length > 0) {
            const { lat, lon } = data[0];
            setLatitude(lat);
            setLongitude(lon);
          } else {
            console.error("Aucune coordonnée trouvée pour l'adresse.");
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des coordonnées :', error);
        }
      }
    };

    fetchCoordinates();
  }, [audit?.place?.address]);

  return (
    <React.Fragment>
      <Card style={{height: '410px'}}>
        <CardBody>
          <h5 className="mb-3">Description<span className="float-end"><Button onClick={toggleBoxMdl} type="text"><EditOutlined /></Button></span></h5>

          <p className="text-muted mb-4">
            {audit?.description || "Aucune description trouvée..."}
          </p>
          <div>
            <Row className="mb-4">
              <Col lg={6}>
                <h5 className="mb-3">Informations de la Boîte</h5>
                <ul className="text-muted vstack gap-2">
                  <li>Référence Boîte : <Tag color="#2db7f5">{audit?.box?.boxReference}</Tag></li>
                  <li>Nombre de Câble : <Badge count={`${audit?.box?.cablesNumber}`} showZero color="#faad14" /></li>
                  <li>Nombre de Cassette : <Badge count={`${audit?.box?.cassetteNumber}`} showZero color="#faad14" /></li>
                  <li>Type de Boîte : <Tag color="#2db7f5">{audit?.box?.boxType?.type?.toUpperCase()}</Tag></li>
                  <li>Environnement : {audit?.place?.type}</li>
                  <li>Adresse : {audit?.place?.address}</li>
                  {(latitude !== null && longitude !== null) ? (
                    <li>
                      Coordonnées :{" "}
                      <span className="text-body fw-medium">
                        <Link
                          target="_blank"
                          to={`https://maps.google.com/maps?q=${latitude},${longitude}&z=10`}
                        >
                          lat = {latitude} | lon = {longitude}
                        </Link>
                      </span>
                    </li>
                  ) : (
                    <li>Coordonnées non disponibles pour cette adresse</li>
                  )}
                </ul>
              </Col>
              <Col lg={6}>
                <h5 className="mb-3">Contact</h5>
                <ul className="text-muted vstack gap-2">
                  <li>Nom : {audit?.contact?.lastname || ""}</li>
                  <li>Prénom : {audit?.contact?.firstname || ""}</li>
                  <li>Email : {audit?.contact?.email || ""}</li>
                  <li>Téléphone : {audit?.contact?.phone || ""}</li>
                </ul>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
      <EditBoxInfosModal modal={boxModal} toggle={toggleBoxMdl} boxInfos={audit?.box} />
    </React.Fragment>
  );
};

export default TaskDescription;
