import React, {useCallback, useContext, useState} from "react";
import { Link, NavLink } from "react-router-dom";
import {Button, Card, CardBody, Col, Nav, NavItem, Row, TabContent, TabPane} from "reactstrap";
import JSZip from "jszip";
import classnames from "classnames";
import FileAudit from "../overview/files";
import AuditComment from "../overview/comments";
import AuditHistory from "../overview/history";
import TabMedia from "../overview/media";
import TypeBoite from "../overview/types-boite/TypeBoite";
import CableTable from "./tabs/cables/CableTable";
import CassetteList from "./tabs/cassettes/CassetteList";
import AuditBPETab from "../overview/auditBPETab";
import {Tabs} from "antd";
import Conformite from "./tabs/conformite/Conformite";
import HistoryAuditBox from "./tabs/history/HistoryAuditBox";
import Medias from "./tabs/medias/Medias";
import TaskJointsFiles from "./tabs/files/TaskJointsFiles";
import BoxTypes from "./tabs/box-types/BoxTypes";
import TaskContext from "../hook/TaskContext";
import Remarques from "./tabs/remarques/Remarques";

const TabHeaders = [
    {name: 'Câbles', key: 0, children: <CableTable/>},
    {name: 'Cassettes', key: 1, children: <CassetteList/>},
    {name: 'Conformité', key: 2, children: <Conformite/>},
    {name: 'Remarques', key: 3, children: <Remarques/>},
    {name: 'Audit(s) Précédent', key: 4, children: <AuditHistory/>},
    // {name: 'Historique', key: 4, children: <AuditHistory/>},
    {name: 'Médias', key: 5, children: <TabMedia/>},
    {name: 'Boîte Inconnue', key: 6, children: <BoxTypes/>},
    {name: 'Fichiers joints', key: 7, children: <TaskJointsFiles/>},
]

const TabSection = () => {
    const audit = useContext(TaskContext)
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const [selectedCheckBoxImage, setSelectedCheckBoxImage] = useState([]);
    const [isActionButton, setIsActionButton] = useState(false);

    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".imgCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => (ele.checked = true));
        } else {
            ele.forEach((ele) => (ele.checked = false));
        }

        selectCheckbox();
    }, []);

    const checkedAll2 = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll2");
        const ele = document.querySelectorAll(".imgCheckBox2");

        if (checkall.checked) {
            ele.forEach((ele) => (ele.checked = true));
        } else {
            ele.forEach((ele) => (ele.checked = false));
        }

        selectCheckbox();
    }, []);

    const selectCheckbox = () => {
        const ele = document.querySelectorAll(".imgCheckBox:checked");
        const ele2 = document.querySelectorAll(".imgCheckBox2:checked");
        ele.length > 0 || ele2.length > 0
            ? setIsActionButton(true)
            : setIsActionButton(false);
        ele.length > 0
            ? setSelectedCheckBoxImage(ele)
            : setSelectedCheckBoxImage(ele2)
    }

    const downloadMultiple = () => {
        const zip = new JSZip();
        const folder = zip.folder('collection');
        const checkall = document.querySelectorAll(".imgCheckBox");
        const checkall2 = document.querySelectorAll(".imgCheckBox2");
        const all = document.getElementById("checkBoxAll");
        const all2 = document.getElementById("checkBoxAll2");
        selectedCheckBoxImage.forEach((element) => {
            const blodPromise = fetch(`${process.env.REACT_APP_API_IMG_URL}/${element.value}`).then(r => {
                if (r.status === 200) return r.blob()
                return Promise.reject(new Error(r.statusText))
            })
            const name = element.value;
            folder.file(name, blodPromise)
        });
        zip.generateAsync({ type:'blob'}).then(content => saveAs(content, 'audit-images')).catch(e => console.log(e))
        setIsActionButton(false);
        all ? all.checked = false : null;
        all2 ? all2.checked = false : null;
        checkall.forEach((el) => {
            el.checked = false;
        })
        checkall2.forEach((el) => {
            el.checked = false
        })

    };

    const onChange = (key: string) => {
        //console.log(key);
    };


    return (
        <Card>
            <CardBody>
                <Tabs
                    // onTabClick={}
                    onChange={onChange}
                    type="card"
                    items={TabHeaders.map((item, key) => {
                        return {
                            label: item.name,
                            key: key,
                            children: item.children,
                        };
                    })}
                />
                {/*<AuditBPETab auditItem={audit}/>*/}
                {/*<Nav pills className="nav nav-pills arrow-navtabs nav-primary bg-light mb-3">*/}
                {/*    <NavItem>*/}
                {/*        <NavLink*/}
                {/*            to=""*/}
                {/*            style={{ cursor: "pointer" }}*/}
                {/*            className={classnames({*/}
                {/*                active: customActiveTab === "1",*/}
                {/*            })}*/}
                {/*            onClick={() => {*/}
                {/*                toggleCustom("1");*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Câble*/}
                {/*        </NavLink>*/}
                {/*    </NavItem>*/}
                {/*    <NavItem>*/}
                {/*        <NavLink*/}
                {/*            to=""*/}
                {/*            style={{ cursor: "pointer" }}*/}
                {/*            className={classnames({*/}
                {/*                active: customActiveTab === "2",*/}
                {/*            })}*/}
                {/*            onClick={() => {*/}
                {/*                toggleCustom("2");*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Cassette*/}
                {/*        </NavLink>*/}
                {/*    </NavItem>*/}
                {/*    <NavItem>*/}
                {/*        <NavLink*/}
                {/*            to=""*/}
                {/*            style={{ cursor: "pointer" }}*/}
                {/*            className={classnames({*/}
                {/*                active: customActiveTab === "3",*/}
                {/*            })}*/}
                {/*            onClick={() => {*/}
                {/*                toggleCustom("3");*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Conformité*/}
                {/*        </NavLink>*/}
                {/*    </NavItem>*/}
                {/*    <NavItem>*/}
                {/*        <NavLink*/}
                {/*            to=""*/}
                {/*            style={{ cursor: "pointer" }}*/}
                {/*            className={classnames({*/}
                {/*                active: customActiveTab === "4",*/}
                {/*            })}*/}
                {/*            onClick={() => {*/}
                {/*                toggleCustom("4");*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Remarques*/}
                {/*        </NavLink>*/}
                {/*    </NavItem>*/}
                {/*    <NavItem>*/}
                {/*        <NavLink*/}
                {/*            to=""*/}
                {/*            style={{ cursor: "pointer" }}*/}
                {/*            className={classnames({*/}
                {/*                active: customActiveTab === "5",*/}
                {/*            })}*/}
                {/*            onClick={() => {*/}
                {/*                toggleCustom("5");*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Historique*/}
                {/*        </NavLink>*/}
                {/*    </NavItem>*/}
                {/*    <NavItem>*/}
                {/*        <NavLink*/}
                {/*            to=""*/}
                {/*            style={{ cursor: "pointer" }}*/}
                {/*            className={classnames({*/}
                {/*                active: customActiveTab === "6",*/}
                {/*            })}*/}
                {/*            onClick={() => {*/}
                {/*                toggleCustom("6");*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Média*/}
                {/*            {isActionButton && (*/}
                {/*                <Button*/}
                {/*                    color="link"*/}
                {/*                    className="p-0 mx-2"*/}
                {/*                    onClick={() => downloadMultiple()}*/}
                {/*                >*/}
                {/*                    <i className="ri-download-2-line fs-18"></i>*/}
                {/*                </Button>*/}
                {/*            )}*/}
                {/*        </NavLink>*/}
                {/*    </NavItem>*/}
                {/*    <NavItem>*/}
                {/*        <NavLink*/}
                {/*            to=""*/}
                {/*            style={{ cursor: "pointer" }}*/}
                {/*            className={classnames({*/}
                {/*                active: customActiveTab === "7",*/}
                {/*            })}*/}
                {/*            onClick={() => {*/}
                {/*                toggleCustom("7");*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Image Type Boîte*/}
                {/*        </NavLink>*/}
                {/*    </NavItem>*/}
                {/*    <NavItem>*/}
                {/*        <NavLink*/}
                {/*            to=""*/}
                {/*            style={{ cursor: "pointer" }}*/}
                {/*            className={classnames({*/}
                {/*                active: customActiveTab === "8",*/}
                {/*            })}*/}
                {/*            onClick={() => {*/}
                {/*                toggleCustom("8");*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Fichiers joints*/}
                {/*        </NavLink>*/}
                {/*    </NavItem>*/}
                {/*</Nav>*/}
                {/*<TabContent*/}
                {/*    activeTab={customActiveTab}*/}
                {/*    id="nav-tabContent"*/}
                {/*>*/}
                {/*    <TabPane id="nav-speci" tabId="1">*/}
                {/*        <CableTable cables={auditItem?.cables} />*/}
                {/*    </TabPane>*/}
                {/*    <TabPane id="nav-detail" tabId="2">*/}
                {/*        <CassetteList cassettes={auditItem?.cassettes} />*/}
                {/*    </TabPane>*/}
                {/*    <TabPane id="nav-detail" tabId="3">*/}
                {/*        <Conformite qcmResponse={auditItem?.qcmResponses} />*/}
                {/*    </TabPane>*/}
                {/*    <TabPane id="nav-detail" tabId="4">*/}
                {/*        <AuditComment comments={auditItem?.remarque} />*/}
                {/*    </TabPane>*/}
                {/*    <TabPane id="nav-detail" tabId="5">*/}
                {/*        <AuditHistory boxRef={auditItem?.box?.boxReference} />*/}
                {/*    </TabPane>*/}
                {/*    <TabPane id="nav-detail" tabId="6">*/}
                {/*        <TabMedia*/}
                {/*            boxImages={auditItem?.imagesBeforeAudit}*/}
                {/*            imagesAfterAudit={auditItem?.imagesAfterAudit}*/}
                {/*            selectCheckbox={selectCheckbox}*/}
                {/*            checkedAll={checkedAll}*/}
                {/*            checkedAll2={checkedAll2}*/}
                {/*        />*/}
                {/*    </TabPane>*/}
                {/*    <TabPane id="nav-detail" tabId="7">*/}
                {/*        <TypeBoite*/}
                {/*            boxInfo={auditItem?.box}*/}
                {/*            boxImages={auditItem?.boxImages}*/}
                {/*        />*/}
                {/*    </TabPane>*/}
                {/*    <TabPane id="nav-detail" tabId="8">*/}
                {/*        <FileAudit />*/}
                {/*    </TabPane>*/}
                {/*</TabContent>*/}
            </CardBody>
        </Card>
    );
};

export default TabSection;
