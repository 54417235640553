import SimpleBar from "simplebar-react";
import {Button, Divider, Row} from "antd";
import React, {useContext, useState} from "react";
import TaskContext from "../../../hook/TaskContext";
import CassetteCard from "./cassetteCard/CassetteCard";
import {FullscreenOutlined} from "@ant-design/icons";
import ShowCassetteModal from "./showCassetteModal/ShowCassetteModal";
import ShowAllCassetteModal from "./showCassetteModal/ShowAllCassetteModal";

const CassetteList = () => {
    const {cassettesData, status} = useContext(TaskContext);
    const [showCassetteMdl, setShowCassetteMdl] = useState(false);
    const [showAllCassetteMdl, setShowAllCassetteMdl] = useState(false);
    const [selectedCassette, setSelectedCassette] = useState(null);

    function toggleModal() {
        setShowCassetteMdl(!showCassetteMdl);
    }

    function toggleAllModal() {
        setShowAllCassetteMdl(!showAllCassetteMdl);
    }

    return (
        <>
            <SimpleBar style={{ maxHeight: "440px" }} className="px-3">
                <Row>
                    {cassettesData.length>0 && cassettesData.map((cassette, index) => {
                        return <CassetteCard
                            toggleModal={toggleModal}
                            showCassetteMdl={showCassetteMdl}
                            cassette={cassette}
                            setSelectedCassette={setSelectedCassette}
                            index={index}
                        />
                    })}
                </Row>
            </SimpleBar>
            <Divider />
            <Row className="d-flex justify-content-end">
                <Button onClick={toggleAllModal} icon={<FullscreenOutlined />} iconPosition='end'>
                    Voir toutes les cassettes
                </Button>
            </Row>
            <ShowCassetteModal
                toggleModal={toggleModal}
                cassette={selectedCassette}
                showCassetteMdl={showCassetteMdl}
                status={status}
            />
            <ShowAllCassetteModal
                toggleAllModal={toggleAllModal}
                showAllCassetteMdl={showAllCassetteMdl}
                status={status}
            />
        </>
    )
}

export default CassetteList;