import {useMutation, useQueries, useQuery, useQueryClient} from "react-query";
import {addCassetteLine, deleteCassetteLine, getTubeById, updateCassetteLine} from "../../../../../../../api/audits/auditsApi";
import toast from "react-hot-toast";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, Card, CardBody, Col, Form, Input, Row} from "reactstrap";

const LineCassetteInput = ({
  lineData,
  cassetteId,
  isEdit,
  setIsEdit,
  cables,
  setLines
}) => {
    const colors = {
        1: "FF0000",
        2: "0000FF",
        3: "18F10E",
        4: "FFFF00",
        5: "81007F",
        6: "FFFFFF",
        7: "FF7F00",
        8: "AFAFAF",
        9: "B05121",
        10: "000000",
        11: "17EAE3",
        12: "FC8BFF",
    };
    function getValueByKey(object, row) {
        return object[row];
    }
    const queryClient = useQueryClient();
    const { data: tubeData } = useQuery(["tube", lineData?.idTubeIn], () => getTubeById(lineData?.idTubeIn));

    const addCassetteLineMutation = useMutation(addCassetteLine, {
        onSuccess: (data) => {
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("audit");
            toast.success("Ligne de cassette ajoutée avec succès!");
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const updateCassetteLineMutation = useMutation(updateCassetteLine, {
        onSuccess: () => {
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("audit");
            toast.success("Cassette modifiée avec succès!");
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const deleteCassetteLineMutation = useMutation(deleteCassetteLine, {
        onSuccess: () => {
            // Invalidates cache and refetch data
            queryClient.invalidateQueries("audit");
            toast.success("Ligne de cassette supprimée avec succès!");
        },
        onError: (error) => {
            toast.error(error);
        },
    });

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            idCableIn: (lineData && lineData.idCableIn) || "",
            idCableOut: (lineData && lineData.idCableOut) || "",
            fiberInEnd: (lineData && lineData.fiberInEnd) || "",
            fiberInStart: (lineData && lineData.fiberInStart) || "",
            fiberOutEnd: (lineData && lineData.fiberOutEnd) || "",
            fiberOutStart: (lineData && lineData.fiberOutStart) || "",
            ringIn: (lineData && lineData.ringIn) ,
            ringOut: (lineData && lineData.ringOut) ,
            tubeInNumber: (lineData && lineData.tubeInNumber) || "",
            tubeOutNumber: (lineData && lineData.tubeOutNumber) || "",
            type: (lineData && lineData.type) || "",
        },
        validationSchema: Yup.object({
            //ringIn: Yup.number().required().positive().integer(),
            //ringOut: Yup.number().required().positive().integer(),
        }),

        onSubmit: (values) => {
            const {
                idCableIn,
                idCableOut,
                tubeInNumber,
                ringIn,
                fiberInStart,
                fiberInEnd,
                tubeOutNumber,
                ringOut,
                fiberOutStart,
                fiberOutEnd,
                type,
            } = validation.values;
            const data = {
                formData: {
                    idLine: lineData?.lineId,
                    idCableIn: idCableIn,
                    tubeIn: {
                        tubeNumber: tubeInNumber,
                        tubeColor: getValueByKey(colors, tubeInNumber),
                        ring: ringIn,
                    },
                    fiberInStart: fiberInStart,
                    fiberInEnd: fiberInEnd,
                    idCableOut: idCableOut,
                    tubeOut: {
                        tubeNumber: tubeOutNumber,
                        tubeColor: getValueByKey(colors, tubeOutNumber),
                        ring: ringOut,
                    },
                    fiberOutStart: fiberOutStart,
                    fiberOutEnd: fiberOutEnd,
                    type: type,
                },
                idCassette: cassetteId,
                idLine: lineData?.lineId,
            };
            if (!isEdit) {
                addCassetteLineMutation.mutate(data);
                setIsEdit(true);
            } else {
                updateCassetteLineMutation.mutate(data);
                setIsEdit(true);
            }
        },
    });

    const TypesSoudures = [
        { label: "STOCKEE", value: "STOCKEE" },
        { label: "EPISSUREE", value: "EPISSUREE" },
        { label: "PASSAGE", value: "PASSAGE" },
    ];

    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody className="bg-light-subtle">
                        <Form
                            id={lineData.lineId}
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Row className="g-3 d-flex justify-content-around">
                                <Col xxl={1} sm={1}>
                                    <select
                                        name="idCableIn"
                                        id="cableIn_id"
                                        className="form-select border-dashed"
                                        aria-label="Default select example"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.idCableIn || ""}
                                    >
                                        <option>Cables IN</option>
                                        {cables.map(({ cableName, cableId }, index) => (
                                            <option key={index} value={cableId}>
                                                {cableName}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col xxl={1} sm={1}>
                                    <Input
                                        name="tubeInNumber"
                                        type="number"
                                        min = "0"
                                        className="form-control border-dashed"
                                        id="tubeIn_id"
                                        placeholder="Tube Entrant"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.tubeInNumber}
                                    />
                                </Col>
                                <Col xxl={1} sm={1}>
                                    <Input
                                        name="ringIn"
                                        type="number"
                                        min = "0"
                                        className="form-control border-dashed"
                                        id="ringIn_id"
                                        placeholder="Bague"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.ringIn}
                                    />
                                </Col>
                                <Col xxl={1} sm={1}>
                                    {/* <Input
                    name="fiberInStart"
                    type="number"
                    className="form-control border-dashed"
                    id="fiberInStart_id"
                    placeholder="Fibre Ent. Debut"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.fiberInStart || ""}
                  /> */}
                                    <select
                                        name="fiberInStart"
                                        id="fiberInStart_id"
                                        className="form-select border-dashed"
                                        aria-label="Default select example"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.fiberInStart || ""}
                                    >
                                        <option>Fibre In Start </option>
                                        {[1,2,3,4,5,6,7,8,9,10,11,12].map((item, index) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col xxl={1} sm={1}>
                                    {/* <Input
                    name="fiberInEnd"
                    type="number"
                    className="form-control border-dashed"
                    id="fiberInEnd_id"
                    placeholder="Fibre Ent. Fin"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.fiberInEnd || ""}
                  /> */}
                                    <select
                                        name="fiberInEnd"
                                        id="fiberInEnd_id"
                                        className="form-select border-dashed"
                                        aria-label="Default select example"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.fiberInEnd || ""}
                                    >
                                        <option>Fibre In End </option>
                                        {[1,2,3,4,5,6,7,8,9,10,11,12].map((item, index) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col xxl={1} sm={1}>
                                    <select
                                        name="type"
                                        id="type_id"
                                        className="form-select border-dashed"
                                        aria-label="Default select example"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.type || ""}
                                    >
                                        <option>Statut </option>
                                        {TypesSoudures.map(({ value, label }, index) => (
                                            <option key={index} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col xxl={1} sm={1}>
                                    {/* <Input
                    name="fiberOutStart"
                    type="number"
                    className="form-control border-dashed"
                    id="fiberOutStart_id"
                    placeholder="Fibre Sort. Debut"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.fiberOutStart || ""}
                  /> */}
                                    <select
                                        name="fiberOutStart"
                                        id="fiberOutStart_id"
                                        className="form-select border-dashed"
                                        aria-label="Default select example"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.fiberOutStart || ""}
                                    >
                                        <option>Fibre Out Start </option>
                                        {[1,2,3,4,5,6,7,8,9,10,11,12].map((item, index) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>

                                </Col>
                                <Col xxl={1} sm={1}>
                                    {/* <Input
                    name="fiberOutEnd"
                    type="number"
                    className="form-control border-dashed"
                    id="fiberOutEnd_id"
                    placeholder="Fibre Sort. Fin"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.fiberOutEnd || ""}
                  /> */}
                                    <select
                                        name="fiberOutEnd"
                                        id="fiberOutEnd_id"
                                        className="form-select border-dashed"
                                        aria-label="Default select example"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.fiberOutEnd || ""}
                                    >
                                        <option>Fibre Out End </option>
                                        {[1,2,3,4,5,6,7,8,9,10,11,12].map((item, index) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col xxl={1} sm={1}>
                                    <Input
                                        name="ringOut"
                                        type="number"
                                        min = "0"
                                        className="form-control border-dashed"
                                        id="ringOut_id"
                                        placeholder="Bague"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.ringOut}
                                    />
                                </Col>
                                <Col xxl={1} sm={1}>
                                    <Input
                                        name="tubeOutNumber"
                                        type="number"
                                        min = "0"
                                        className="form-control border-dashed"
                                        id="tubeOut_id"
                                        placeholder="Tube Sortant"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.tubeOutNumber}
                                    />
                                </Col>
                                <Col xxl={1} sm={1}>
                                    <select
                                        name="idCableOut"
                                        id="cableOut_id"
                                        className="form-select border-dashed"
                                        aria-label="Default select example"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.idCableOut || ""}
                                    >
                                        <option>Cable OUT </option>
                                        {cables.map(({ cableName, cableId }, index) => (
                                            <option key={index} value={cableId}>
                                                {cableName}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col xxl={1} sm={4}>
                                    <Button
                                        onClick={() => {
                                            deleteCassetteLineMutation.mutate(lineData.lineId);
                                        }}
                                        color="danger"
                                        className="btn-icon mx-2"
                                        outline
                                    >
                                        {" "}
                                        <i className="ri-close-fill" />{" "}
                                    </Button>
                                    {/* {!isEdit && (
                                <Button
                                    id={`${lineData.lineId}`}
                                    onClick={handleEditLine}
                                    color="primary"
                                    className="btn-icon"
                                    outline
                                >
                                    {" "}
                                    <i className="ri-edit-fill" />{" "}
                                </Button>
                            )}  */}
                                    {/* {isEdit && ( */}
                                    <Button
                                        type="submit"
                                        color="success"
                                        className="btn-icon"
                                        outline
                                    >
                                        {" "}
                                        <i className="ri-check-fill" />{" "}
                                    </Button>
                                    {/* )} */}
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default LineCassetteInput;