import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import toast from "react-hot-toast";
import multiDownload from "multi-download";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { useMutation, useQueryClient } from "react-query";
import { deleteProjectFiles } from "../../../../api/projects/projectsApi";
import {Button, Empty} from "antd";
import {DeleteOutlined, DownloadOutlined} from "@ant-design/icons";

const DocumentTab = ({ documents }) => {
    const queryClient = useQueryClient();
    const deleteProjectFileMutation = useMutation(deleteProjectFiles, {
        onSuccess: () => {
        // Invalidates cache and refetch data
        queryClient.invalidateQueries("projects");
        toast.success("Le fichier a été supprimé avec succès");
        },
        onError: (error) => {
        // An error happened!
        toast.error(error);
        },
    });

    const [item, setItem] = useState();
    const onClickDelete = (file) => {
        setItem(file)
        setDeleteModal(true);
      };

    // Delete Multiple
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    // Checked All
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".contactCheckBox");

        if (checkall.checked) {
        ele.forEach((ele) => {
            ele.checked = true;
        });
        } else {
        ele.forEach((ele) => {
            ele.checked = false;
        });
        }
        deleteCheckbox();
    }, []);

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".contactCheckBox:checked");
        ele.length > 0
          ? setIsMultiDeleteButton(true)
          : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    //Delete single file
    const handleDeleteFile = () => {
        if (item) {
            deleteProjectFileMutation.mutate(item)
            setDeleteModal(false);
        }
    }

    //Delete multiples files
    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            deleteProjectFileMutation.mutate(element.value)
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
    };

    //Download multiples files
    const downloadMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".contactCheckBox");
        multiDownload(
          Object.keys(selectedCheckBoxDelete).map(
            (item) =>
              `${process.env.REACT_APP_API_URL}/data-file/download-file/${selectedCheckBoxDelete[item].value}`
          )
        );
        setIsMultiDeleteButton(false);
        checkall.checked = false;
        ele.forEach((ele) => {
            ele.checked = false;
        });
    };

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteFile()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                deleteMultiple();
                setDeleteModalMulti(false);
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
            <Row>
                <Col lg={12}>
                    <Card>
                    <CardBody>
                        {isMultiDeleteButton && (
                            <div className="flex-shrink-0 mb-2">
                                <div className="d-flex justify-content-end hstack text-nowrap gap-2">
                                    <Button onClick={() => setDeleteModalMulti(true)} type="primary" danger shape="circle" icon={<DeleteOutlined />} size="large" />
                                    <Button onClick={() => downloadMultiple()} shape="circle" icon={<DownloadOutlined />} size="large" />
                                </div>
                            </div>
                        )}
                        <div className="todo-content position-relative px-4 mx-n4" id="todo-content">
                            <div className="table-responsive">
                                {documents.length > 0 ? (
                                    <table className="table align-middle table-nowrap mb-0">
                                        <thead className="table-active">
                                        <tr>
                                            <th scope="col">
                                            <span className="mx-1">
                                                <input
                                                    type="checkbox"
                                                    id="checkBoxAll"
                                                    className="form-check-input"
                                                    onClick={() => checkedAll()}
                                                />
                                            </span>
                                                Nom Fichier
                                            </th>
                                            <th scope="col">Type Fichier</th>
                                            <th scope="col">Taille Fichier</th>
                                            <th scope="col">Date Récente</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody id="file-list">
                                        {(documents || []).map((item, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <input
                                                        className="form-control filelist-id"
                                                        type="hidden"
                                                        value="1"
                                                        id="filelist-1"
                                                    />
                                                    <div className="d-flex align-items-center">
                                                        <div className="mx-1">
                                                            <input
                                                                type="checkbox"
                                                                className="contactCheckBox form-check-input"
                                                                value={item}
                                                                onChange={() => deleteCheckbox()}
                                                            />
                                                        </div>
                                                        <div className="flex-shrink-0 fs-17 me-2 filelist-icon">
                                                            <i
                                                                className={
                                                                    item.split(".").at(-1) === "pdf"
                                                                        ? "ri-file-pdf-fill text-danger"
                                                                        : item.split(".").at(-1) === "docx"
                                                                            ? "ri-file-text-fill text-secondary"
                                                                            : "ri-gallery-fill text-success"
                                                                }
                                                            />
                                                        </div>
                                                        <div className="flex-grow-1 filelist-name">
                                                            {item?.substr(20).split(".").at(0)}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{item.split(".").at(-1)}</td>
                                                <td className="filelist-size">.ko</td>
                                                <td className="filelist-create">{item?.substr(0, 10)}</td>
                                                <td>
                                                    <Link
                                                        to={`${process.env.REACT_APP_API_URL}/data-file/download-file/${item}`}
                                                    >
                                                        <i className="ri-download-2-fill me-2 align-middle text-muted"></i>
                                                    </Link>
                                                    <Link onClick={() => onClickDelete(item)}>
                                                        <i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                ) : <Empty imageStyle={{
                                    height: 200,
                                }} />}

                            </div>
                        </div>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DocumentTab;
