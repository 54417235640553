import ExcelJS from 'exceljs';

function Nouvelle_worksheet(workbook,datas){

  const worksheet = workbook.addWorksheet('Nouvelles_Lignes')
  make_header(worksheet)
  var row = null;

  for(const lines of datas.nouvelles){
    let tab = serializeLine(lines);
  row = worksheet.addRow(tab.slice(0,-2))
  row.eachCell((cell) => {
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
  });

  var fiber = serializeLine(lines);
  if(fiber[4]){
    //coloration des tubes
    const statusCellE = row.getCell('E')
    statusCellE.fill={
      type:'pattern',
      pattern:"solid",
      fgColor:{argb:fiber[14]}
  } 
  setFontColor(statusCellE,fiber[14])
 
   //ici des bagues sachant que les bagues ont la meme couleur que les tubes
    const statusCellF = row.getCell('F'); 
    statusCellF.fill={
      type:'pattern',
      pattern:"solid",
      fgColor:{argb:fiber[14]}
  } 
  setFontColor(statusCellF,fiber[14])
  //les fibres maintenant
    const statusCellG = row.getCell('G'); 
    fiberColor(statusCellG,fiber[6].toString())
   
  }
  if(fiber[10]){
    //coloration des tubes
    const statusCellK = row.getCell('K')
    statusCellK.fill={
      type:'pattern',
      pattern:"solid",
      fgColor:{argb:fiber[15]}
  } 
  setFontColor(statusCellK,fiber[15])
 
    //ici des bagues sachant que les bagues ont la meme couleur que les tubes
    const statusCellJ = row.getCell('J'); 
    statusCellJ.fill={
      type:'pattern',
      pattern:"solid",
      fgColor:{argb:fiber[15]}
  } 
  setFontColor(statusCellJ,fiber[15])
    //les fibres maintenant
    const statusCellI = row.getCell('I'); 
    fiberColor(statusCellI,fiber[8].toString())
  }
  }
}
function Ancienne_worksheet(workbook,datas){

  const worksheet = workbook.addWorksheet('Anciennes_Lignes')
  make_header(worksheet)
  var row = null;

  for(const lines of datas.ancienne){

    let tab = serializeLine(lines);
  row = worksheet.addRow(tab.slice(0,-2))
 row.eachCell((cell) => {
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
  });

  var fiber = serializeLine(lines);
  if(fiber[4]){
    //coloration des tubes
    const statusCellE = row.getCell('E')
    statusCellE.fill={
      type:'pattern',
      pattern:"solid",
      fgColor:{argb:fiber[14]}
  } 
  setFontColor(statusCellE,fiber[14])
 
   //ici des bagues sachant que les bagues ont la meme couleur que les tubes
    const statusCellF = row.getCell('F'); 
    statusCellF.fill={
      type:'pattern',
      pattern:"solid",
      fgColor:{argb:fiber[14]}
  } 
  setFontColor(statusCellF,fiber[14])
  //les fibres maintenant
    const statusCellG = row.getCell('G'); 
    fiberColor(statusCellG,fiber[6].toString())
   
  }
  if(fiber[10]){
    //coloration des tubes
    const statusCellK = row.getCell('K')
    statusCellK.fill={
      type:'pattern',
      pattern:"solid",
      fgColor:{argb:fiber[15]}
  } 
  setFontColor(statusCellK,fiber[15])
 
    //ici des bagues sachant que les bagues ont la meme couleur que les tubes
    const statusCellJ = row.getCell('J'); 
    statusCellJ.fill={
      type:'pattern',
      pattern:"solid",
      fgColor:{argb:fiber[15]}
  } 
  setFontColor(statusCellJ,fiber[15])
    //les fibres maintenant
    const statusCellI = row.getCell('I'); 
    fiberColor(statusCellI,fiber[8].toString())
  }
  }
}


function make_header(worksheet){
  const columnWidths = {
    A: 13,
    B: 13,
    C:13,
    D:13,
    E:13,
    F:12,
    H:12,
    G: 13,
    I: 13,
    L: 13,
    M: 13,
    N: 13,
  };

  for (const col in columnWidths) {
    worksheet.getColumn(col).width = columnWidths[col];
  }
const headers = [
    "Cassette","Equipement Amont", "Câble", "Capacité",
    "Tube", "Bague", "Fibre", "Status",
    "Fibre", "Bague", "Tube", "Capacité",
    "Câble", "Equipement aval"
  ];

 
let row = worksheet.addRow(headers);
row.eachCell((cell) => {
  cell.fill={
    type:'pattern',
    pattern:"solid",
    fgColor:{argb:"999999"}
}
  cell.font={
    bold:true
  }
  cell.alignment = { horizontal: 'center', vertical: 'middle' };
});
}

export default function exportRapportToCompare(datas){
  const workbook = new ExcelJS.Workbook();
   const worksheet = workbook.addWorksheet('Différences')

   Nouvelle_worksheet(workbook,datas)
   Ancienne_worksheet(workbook,datas)
   const columnWidths = {
       A: 13,
       B: 13,
       C:13,
       D:13,
       E:13,
       F:12,
       H:12,
       G: 13,
       I: 13,
       L: 13,
       M: 13,
       N: 13,
     };
   
     for (const col in columnWidths) {
       worksheet.getColumn(col).width = columnWidths[col];
     }
   const headers = [
       "Cassette","Equipement Amont", "Câble", "Capacité",
       "Tube", "Bague", "Fibre", "Status",
       "Fibre", "Bague", "Tube", "Capacité",
       "Câble", "Equipement aval"
     ];

    
  let row = worksheet.addRow(headers);
   row.eachCell((cell) => {
     cell.fill={
       type:'pattern',
       pattern:"solid",
       fgColor:{argb:"999999"}
   }
     cell.font={
       bold:true
     }
     cell.alignment = { horizontal: 'center', vertical: 'middle' };
   });
   let row1=null;
   let row2=null;

   for(const lines of datas.differents){
     //ici la methode slice pour retirer les 2 derniers éléments du tableau etant les couleur du tube et de la bague pour ne pas les afficher
     let tab1 = serializeLine(lines[0]).slice(0,-2)
     let tab2 = serializeLine(lines[1]).slice(0,-2)

     row1= worksheet.addRow(tab1)

     row2= worksheet.addRow(tab2)

     worksheet.addRow()

     row1.eachCell((cell) => {
       cell.alignment = { horizontal: 'center', vertical: 'middle' };
     });

     row2.eachCell((cell) => {
       cell.alignment = { horizontal: 'center', vertical: 'middle' };
     });

     const fibre1numbervalue = serializeLine(lines[0]);
     const fibre2numbervalue = serializeLine(lines[1]);

     //verification si le tube existe avant de faire les colorations
     if(fibre1numbervalue[4]){
       //coloration des tubes
       const statusCellE1 = row1.getCell('E')
       statusCellE1.fill={
         type:'pattern',
         pattern:"solid",
         fgColor:{argb:fibre1numbervalue[14]}
     }
     setFontColor(statusCellE1,fibre1numbervalue[14])
    
      //ici des bagues sachant que les bagues ont la meme couleur que les tubes
       const statusCellF1 = row1.getCell('F'); 
       statusCellF1.fill={
         type:'pattern',
         pattern:"solid",
         fgColor:{argb:fibre1numbervalue[14]}
     } 
     setFontColor(statusCellF1,fibre1numbervalue[14])
    
     //les fibres maintenant
       const statusCellG1 = row1.getCell('G'); 
       fiberColor(statusCellG1,fibre1numbervalue[6].toString())
      
     }
     if(fibre2numbervalue[4]){
       const statusCellE2 = row2.getCell('E')
       statusCellE2.fill={
         type:'pattern',
         pattern:"solid",
         fgColor:{argb:fibre2numbervalue[14]}
     }
     setFontColor(statusCellE2,fibre2numbervalue[14])

       const statusCellF2 = row2.getCell('F'); 
       statusCellF2.fill={
         type:'pattern',
         pattern:"solid",
         fgColor:{argb:fibre2numbervalue[14]}
     }
     setFontColor(statusCellF2,fibre2numbervalue[14])
     

       const statusCellG2 = row2.getCell('G'); 
       fiberColor(statusCellG2,fibre2numbervalue[6].toString())
      
     }
     if(fibre1numbervalue[10]){
       
       //coloration des tubes
       const statusCellK = row1.getCell('K')
       statusCellK.fill={
         type:'pattern',
         pattern:"solid",
         fgColor:{argb:fibre1numbervalue[15]}
     } 
     setFontColor(statusCellK,fibre1numbervalue[15])
     
     //ici des bagues sachant que les bagues ont la meme couleur que les tubes
       const statusCellJ = row1.getCell('J'); 
       statusCellJ.fill={
         type:'pattern',
         pattern:"solid",
         fgColor:{argb:fibre1numbervalue[15]}
     } 
     setFontColor(statusCellJ,fibre1numbervalue[15])
     
     //les fibres maintenant
       const statusCellI = row1.getCell('I'); 
       fiberColor(statusCellI,fibre1numbervalue[8].toString())
     }
     
     if(fibre2numbervalue[10]){
       const statusCellK = row2.getCell('K')
       statusCellK.fill={
         type:'pattern',
         pattern:"solid",
         fgColor:{argb:fibre2numbervalue[15]}
     } 
     setFontColor(statusCellK,fibre2numbervalue[15])
    
       const statusCellJ = row2.getCell('J'); 
       statusCellJ.fill={
         type:'pattern',
         pattern:"solid",
         fgColor:{argb:fibre2numbervalue[15]}
     } 
     setFontColor(statusCellJ,fibre2numbervalue[15])
    
       const statusCellI = row2.getCell('I'); 
       fiberColor(statusCellI,fibre2numbervalue[8].toString())
     }

     //recuperation des cellule ... et comparaison entre elle si diff coloration de la ligne en bas en rouge sinon on laisse
     celldiffcolor(row2.getCell("C"),fibre1numbervalue[2],fibre2numbervalue[2])
     celldiffcolor(row2.getCell("D"),fibre1numbervalue[3],fibre2numbervalue[3])
     celldiffcolor(row2.getCell("H"),fibre1numbervalue[7],fibre2numbervalue[7])
     celldiffcolor(row2.getCell("L"),fibre1numbervalue[11],fibre2numbervalue[11])
     celldiffcolor(row2.getCell("M"),fibre1numbervalue[12],fibre2numbervalue[12])

     
    

   }

   

  
// Générer le fichier Excel
workbook.xlsx.writeBuffer().then((buffer) => {
 var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
 var url = window.URL.createObjectURL(blob);
 var a = document.createElement('a');
 a.href = url;
 a.download = 'Rapport_de_Comparaison.xlsx'; // Nom du fichier Excel
 a.click();
 window.URL.revokeObjectURL(url);
 });

}
function serializeLine(data) {
        
  var line =[];

  if(data?.line.fiberDataReportIn==null){
      line = [
          data?.cassettetype,
          data?.line.refBoxUpStream,
          "","","","","",
          data?.line.status,
          data?.line.fiberDataReportOut?.fiberNumber,
          data?.line.fiberDataReportOut?.ringNumber,
          data?.line.fiberDataReportOut?.tubeNumber,
          data?.line.fiberDataReportOut?.cableCapacity,
          data?.line.fiberDataReportOut?.cableName,
          data?.line.refBoxDownStream,
          "",
          data?.line.fiberDataReportOut?.tubeColor,
          
          
          ];
  }
 else if(data?.line.fiberDataReportOut==null){
      line = [
          data?.cassettetype,
          data?.line.refBoxUpStream,
          data?.line.fiberDataReportIn?.cableName,
          data?.line.fiberDataReportIn?.cableCapacity,
          data?.line.fiberDataReportIn?.tubeNumber,
          data?.line.fiberDataReportIn?.ringNumber,
          data?.line.fiberDataReportIn?.fiberNumber,
          data?.line.status,
          "","","","","",
          data?.line.refBoxDownStream,
          data?.line.fiberDataReportIn?.tubeColor,
          ""
          ];
  }
  else{
      line =[
          data?.cassettetype,
          data?.line.refBoxUpStream,
          data?.line.fiberDataReportIn?.cableName,
          data?.line.fiberDataReportIn?.cableCapacity,
          data?.line.fiberDataReportIn?.tubeNumber,
          data?.line.fiberDataReportIn?.ringNumber,
          data?.line.fiberDataReportIn?.fiberNumber,
          data?.line.status,
          data?.line.fiberDataReportOut?.fiberNumber,
          data?.line.fiberDataReportIn?.ringNumber,
          data?.line.fiberDataReportIn?.tubeNumber,
          data?.line.fiberDataReportIn?.cableCapacity,
          data?.line.fiberDataReportIn?.cableName,
          data?.line.refBoxDownStream,
          data?.line.fiberDataReportIn?.tubeColor,
          data?.line.fiberDataReportOut?.tubeColor,

          ]
  }

  return line;
}

function setFontColor(cell,value){
  const couleurs = ["FF0000", "0000FF", "81007F", "FF7F00", "AFAFAF", "B05121", "000000"];
  
  if (couleurs.includes(value)) {
    cell.font = {
      color: { argb: 'FFFFFF' }, // Blanc
    };
  }
  
  }
function fiberColor(cell,val) {
  switch (val) {
    case "1":
        cell.fill={
            type:"pattern",
            pattern:"solid",
            fgColor:{argb:"FF0000"},   
        }

        cell.font = {
            color: { argb: 'FFFFFF' }, // Blanc
          };

        break;
        case "2":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"0000FF"}
        }
        cell.font = {
            color: { argb: 'FFFFFF' }, // Blanc
          };
        
        break;
        case "3":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"18F10E"}
        }
        
        
        break;
        case "4":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"FFFF00"}
        }
        
        
        break;
        case "5":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"81007F"}
        }
        cell.font = {
            color: { argb: 'FFFFFF' }, // Blanc
          };
          
        break;
        case "6":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"FFFFFF"}
        }
        
        break;
        case "7":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"FF7F00"}
        }
        cell.font = {
            color: { argb: 'FFFFFF' }, // Blanc
          };
          
        break;
        case "8":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"AFAFAF"}
        }
        cell.font = {
            color: { argb: 'FFFFFF' }, // Blanc
          };
          
        break;
        case "9":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"B05121"}
        }
        cell.font = {
            color: { argb: 'FFFFFF' }, // Blanc
          };
          
        break;
        case "10":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"000000"},
       }
        cell.font = {
            color: { argb: 'FFFFFF' },
             // Blanc
          };
          
        break;
        case "11":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"17EAE3"}
        }
          
        break;
        case "12":
        cell.fill={
            type:'pattern',
            pattern:"solid",
            fgColor:{argb:"FC8BFF"}
        }
          
        break;

    default:
        break;
}
  
}

function celldiffcolor(celltocolor,value1, value2){
  if(value1!=value2){
    celltocolor.font = {
      color: { argb: 'FF0000' }, // Rouge
    };
  }
}