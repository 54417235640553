import {useState} from "react";
import * as XLSX from "xlsx";
import toast from "react-hot-toast";
import {useMutation} from "react-query";
import {createMultiMembers} from "../../../api/users/usersApi";
import {FileUploader} from "../../../Components/Common/FileUpload/FileUploader";

const formatRole = (role) => {
    switch (role) {
        case 'Technicien':
            return 'ROLE_MOBILE_USER';
        case 'Ingenieur':
            return 'ROLE_BACK_OFFICE_MANAGER';
        case 'Admin':
            return 'ROLE_ADMIN';
    }
}
const AddMultiUsers = ({queryClient}) => {
    const [event, setEvent] = useState(null)
    const addMultiUsersMutation = useMutation(createMultiMembers, {
        onSuccess: (data) => {
            // Invalidates cache and refetch data
            //queryClient.invalidateQueries("audits");
            if (data.registeredUsersCount === data.totalUsersCount) {
                toast.success("Utilisateurs ajoutés avec succès!");
            } else {
                toast.error(Object.keys(data.unregisteredUsers).join('; \n** '));
            }

            // if (data.unregisteredUsersCount>0) {
            //     toast.error(Object.keys(data.unregisteredUsers).join('; \n** '));
            // }
        },
        onSettled: () => {
            queryClient.invalidateQueries("users");
            event.target.value = null;
        },
        onError: (error) => {
            toast.error(error)
        }
    });

    const fileType=['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const handleFileUpload = (e) => {
        if (e.target.files[0]) {
            setEvent(e)
            if (e.target.files[0] && fileType.includes(e.target.files[0].type)) {
                const reader = new FileReader();
                reader.readAsBinaryString(e.target.files[0]);
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "binary" });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const parsedData = XLSX.utils.sheet_to_json(sheet);

                    const superAdminId = JSON.parse(sessionStorage.getItem("authUser")).superAdminId
                    const company = JSON.parse(sessionStorage.getItem("authUser")).company

                    addMultiUsersMutation.mutate(parsedData.map((item) => ({
                        firstname: item.Nom,
                        lastname: item.Prénom,
                        email: item.Email,
                        mobileNumber: item.Téléphone,
                        username: item.Nom_Utilisateur,
                        userRole: formatRole(item.Rôle),
                        userImageUrl: "",
                        superAdminId: superAdminId && superAdminId,
                        company: company && company
                    })));
                }
            } else {
                toast.error("Format de fichier non valide !")
            }
        }
    };

    return <FileUploader handleFileUpload={handleFileUpload} text={"Ajout multiple"}/>
}

export default AddMultiUsers;