import React, {useContext, useEffect, useState} from "react";
import TaskContext from "../../../../../hook/TaskContext";
import LineCassetteInput from "./LineCassetteInput";
import BoxBottomLineForm from "../../../../../overview/cassettes/components/BoxBottomLineForm";
import {Button} from "reactstrap";

const EditLinesCassetteDrawer = ({cassette}) => {
    const {cassettesLines, cables} = useContext(TaskContext);
    const [lines, setLines] = useState([]);
    const [isEdit, setIsEdit] = useState(true);
    const [isBoxBottom, setIsBoxBottom] = useState(false);
    useEffect(() => {
        if (cassette?.cassetteId) {
            const foundItem = cassettesLines.find((item) => (item.cassetteId || item.box_bottom_id) === cassette.cassetteId);
           if(foundItem?.box_bottom_id) {
               setIsBoxBottom(true)
               setLines(foundItem?.boxBottomLines)
           } else {
               setIsBoxBottom(false)
               
               setLines(foundItem?.lineEntities)
           }
        }
    }, [cassette]);

    const handleClick = () => {
        setIsEdit(false);
        setLines([
            ...lines,
            {
                capacityCableIn: 0,
                capacityCableOut: 0,
                fiberInEnd: 0,
                fiberInStart: 0,
                fiberOutEnd: 0,
                fiberOutStart: 0,
                idCableIn: 0,
                idCableOut: 0,
                idTubeIn: 0,
                idTubeOut: 0,
                lineId: 724,
                nameCableIn: "",
                nameCableOut: "",
                ringIn: 0,
                ringOut: 0,
                tubeInColor: "",
                tubeInNumber: 0,
                tubeOutColor: "",
                tubeOutNumber: 0,
                type: "",
            },
        ]);
    };

    return (
        <>
            {!isBoxBottom && lines.map((line, i) => (
                <LineCassetteInput
                    cables={cables}
                    cassetteId={cassette.cassetteId}
                    lineData={line}
                    // handleDelete={() => handleDelete(i)}
                    key={i}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    lines={lines}
                    setLines={setLines}
                />
            ))}
            {isBoxBottom && lines.map((line, i) => (
                <BoxBottomLineForm
                    lineData={line}
                    // handleDelete={() => handleDelete(i)}
                    cassetteId={cassette.cassetteId}
                    isEdit={isEdit}
                    setLines={setLines}
                    cables={cables}
                />
            ))}
            <Button
                onClick={handleClick}
                color="primary"
                className="btn-label float-end mx-2"
            >
                {" "}
                <i className="ri-add-line label-icon align-middle fs-16 me-2"></i>{" "}
                Ligne{" "}
            </Button>
        </>
    )
}

export default EditLinesCassetteDrawer;