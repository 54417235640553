import React, {useEffect, useState} from 'react';
//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';
import { Toaster } from 'react-hot-toast';
import {jwtDecode} from "jwt-decode";
import {useLocation, useNavigate} from "react-router-dom";
import HistoryContext from "./Components/Hooks/HistoryContext";

function App(props) {
    const location = useLocation();
    const [path, setPath] = useState([]);

    useEffect(() => {
        // execute on location change
        setPath((prev) => [...prev, location.pathname]);
    }, [location.pathname]);

    // Check valid token if current tab is visible
    document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === 'visible') {
            const token = JSON.parse(sessionStorage.getItem("authUser"))?.token;
            if (token && jwtDecode(token).exp < Date.now() / 1000){
                window.location.reload();
            }
        }
    });

    return (
        <HistoryContext.Provider  value={path}>
            <React.Fragment>
                <Route />
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                    toastOptions={{
                        duration: 5000,
                    }}
                />
            </React.Fragment>
        </HistoryContext.Provider>
      );
}

export default App;
